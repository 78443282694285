<template>
  <div class="nav" :class="{ old: isElder }">
    <van-tabbar route>
      <van-tabbar-item replace to="/home"
        >首页
        <template #icon="props">
          <img :src="props.active ? home : homenoclick" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/patrol"
        >巡查
        <template #icon="props">
          <img
            style="padding-left: 3px"
            :src="props.active ? patrol : patrolnoclick"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/task"
        >任务
        <template #icon="props">
          <img :src="props.active ? task : tasknoclick" />
        </template>
      </van-tabbar-item>

      <!-- <van-tabbar-item replace to="/my"
        >我的
        <template #icon="props">
          <img :src="props.active ? my : mynoclick" />
        </template>
      </van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      home: require("../../assets/img/home/home.png"),
      homenoclick: require("../../assets/img/home/homenoclick.png"),
      patrolnoclick: require("../../assets/img/home/patrolnoclick.png"),
      patrol: require("../../assets/img/home/patrol.png"),
      task: require("../../assets/img/home/task.png"),
      tasknoclick: require("../../assets/img/home/tasknoclick.png"),
      mynoclick: require("../../assets/img/home/mynoclick.png"),
      my: require("../../assets/img/home/my.png"),
    };
  },
  created() {},
  methods: {},
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.old {
  ::v-deep .van-tabbar-item__text {
    font-size: 18px !important;
  }
}
</style>
