import shouyejingqulist from '@/api/home/api_list'
import xuncha from '@/api/Patrol/api_list';
import my from '@/api/my/api_list'
import task from '@/api/task/api_list'
import waring from '@/api/waring/api_list'
import ext from '@/api/ext/api_list'
import announcement from '@/api/announcement/api_list'
import onlineMonitor from '@/api/onlineMonitor/api_list'
import securityMap from '@/api/securityMap/api_list'
import danger from '@/api/danger/api_list'


export default {
  login: '/api/login/up',//登录
  zlbLogin: '/api/app/person/getSsoToken',//钉钉登录code码
  ...shouyejingqulist,
  ...xuncha,
  ...my,
  ...task,
  ...waring,
  ...ext,
  ...announcement,
  ...onlineMonitor,
  ...securityMap,
  ...danger,
}