<template>
  <div class="xiangqingBox" :class="{ old: isElder }">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
      </div>
      <div class="list" v-for="(item,index) in list['基本信息']" :key="index">
        <div class="con">
          <van-field
            type="textarea"
            rows="1"
            autosize
            readonly
            :label="item.label"
            :value="Data[item.value]?Data[item.value]:wu"
          />
        </div>
        <div class="xian"></div>
      </div>
      <!-- <div class="list">
        <div class="con">
          <van-field
            type="textarea"
            rows="1"
            autosize
            readonly
            label="任务标题"
            v-model="Data.title"
          ></van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field
            type="textarea"
            rows="1"
            autosize
            readonly
            label="接收单位"
            v-model="Data.companyName"
          ></van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field
            type="textarea"
            v-model="Data.auditCompanyName"
            label="查阅单位"
            rows="1"
            autosize
            readonly
          ></van-field>
        </div>
      </div> -->
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>任务内容</span>
      </div>
      <div class="list">
        <div class="con">
          <van-field
            type="textarea"
            :value="Data.comment?Data.comment:wu"
            label="内容详情"
            rows="1"
            readonly
            autosize
          ></van-field>
        </div>
        <div class="xian"></div>
      </div>

      <div class="list">
        <div class="con">
          <van-field
            label="发布时间"
            readonly
            :value="Data.createTime?Data.createTime:wu"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span style="">任务截止时间</span>
      </div>
      <div class="list">
        <div class="con" style="">
          <van-field
            label="截至时间"
            readonly
            :value="Data.taskTerm?Data.taskTerm:wu"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>处理人员</span>
      </div>
      <div class="list">
        <div class="con">
          <van-field
            label="人员姓名"
            :value="personPerson?personPerson:wu"
            readonly
          ></van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field
            readonly
            :value="mobile?mobile:wu"
            label="联系方式"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="jiben" v-show="state == 5">
      <div class="infomation">
        <div class="kuai"></div>
        <span>查阅结果</span>
      </div>
      <div
        v-for="(item, index) in Data.retrunCompany"
        :key="index"
        class="result"
      >
        <!-- <div class="b" v-show="index > 0"></div> -->
        <div class="list">
          <div class="con">
            <van-field
              label="退回单位"
              :value="item.returnCompanyName?item.returnCompanyName:wu"
              readonly
            ></van-field>
          </div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="con">
            <van-field
              label="退回意见"
              :value="item.returnReason?item.returnReason:wu"
              type="textarea"
              rows="1"
              readonly
              autosize
            ></van-field>
          </div>
          <div
            class="xian"
            style="background: #b3b5b9"
            v-show="index !== Data.retrunCompany.length - 1"
          ></div>
        </div>
      </div>
    </div>
    <div class="bottomBox">
      <van-button
        round
        size="normal"
        style="width: 90%"
        @click="dealWithNow"
        type="info"
        >开始处理</van-button
      >
    </div>
  </div>
</template>
<script>
import api from "@/api/task/index";
export default {
  name: "taskxiangqing",
  data() {
    return {
      form: { taskId: 0 },
      Data: {},
      list: {
        '基本信息':[
          {label:'任务编号',value:'taskCode'},
          {label:'任务标题',value:'title'},
          {label:'接收单位',value:'companyName'},
          {label:'查阅单位',value:'auditCompanyName'},
        ],
      },
      wu: '无'
    };
  },
  created() {
    this.form.taskId = this.$route.query.id;
  },
  mounted() {
    this.getList();
  },
  computed: {
    personPerson() {
      return this.$store.state.userName;
    },
    mobile() {
      return this.$store.state.mobile;
    },
    state() {
      return this.$route.query.state;
    },
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  methods: {
    getList() {
      api.taskDetail(this.form).then((res) => {
        if (res.code == "200") {
          this.Data = res.data;
        }
      });
    },
    //开始处理
    dealWithNow() {
      if (this.state === 1) {
        api.taskimmediateProcessing(this.form).then((res) => {
          if (res.code == "200") {
            this.$router.push({
              name: "dealWithNow",
              query: {
                id: this.Data.id,
              },
            });
          } else {
            this.$toast(res.message);
          }
        });
      } else {
        this.$router.push({
          name: "dealWithNow",
          query: {
            id: this.Data.id,
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
@import url("../../assets/less/vantChange.less");
.xiangqingBox {
  .jiben {
    margin-top: 16px;
    .infomation {
      padding-left: 16px;
      margin-bottom: 11px;
      span {
        font-size: 15px;
      }
      .kuai {
        height: 16px;
      }
    }
    // .result:nth-child(){
    .result {
      // display: flex;
      // align-items: center;
      // flex-direction: column;
      .van-cell {
        height: 42px;
      }
      // .b_top{
      //   border-top:1px solid #babbbd;
      //   //  margin-left: 20px;
      // }
      // .b{
      //   height: 1px;
      //   width: 90%;
      //   background-color: #b3b5b9;
      //   margin-left: 20px;
      //   // background-color: none;
      //   // display: inline-block;
      //   // text-align: center;
      //   // background-color: #aeafaf;
      // }
    }
  }
  // }
  .list {
    min-height: 50px;
    .van-cell {
      font-size: 18px;
      color: #686b73;
      background: transparent;
    }

    .con {
      width: 100%;
      padding: 0 20px;
      // box-sizing: content-box;
      .van-cell {
        color: #363a44 !important;
        font-size: 15px;
        padding: 10px 0px;
      }
      .van-cell__value {
        color: #b3b5b9;
      }
      .popupBox {
        padding: 48px 34px 38px 34px;
        .title {
          align-self: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #363a44;
        }
        .shangxia();
        .xuanze {
          .van-checkbox {
            justify-content: space-between;
            &:first-child {
              margin: 0 0 0 0;
            }
            margin-top: 27px;
          }
        }
        .buttomBox {
          width: 100%;
          height: 54px;
          .liangbian();
          align-items: center;
          margin-top: 103px;
          .close {
            height: 54px;
            width: 39%;
            border-radius: 27px;
            border: 1px solid #0085ff;
            color: #0085ff;
            font-size: 20px;
            text-align: center;
            line-height: 54px;
          }
          .sure {
            width: 39%;
            height: 54px;
            border-radius: 27px;
            text-align: center;
            line-height: 54px;
            background: #1c91ff;
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }
  }
}

/deep/ .van-field__label {
  color: #b3b5b9;
  font-size: 15px;
  margin-right: 17px;
}
.bottomBox {
  margin-top: 49px;
  width: 100%;
  height: 85px;
  position: relative;
  bottom: 0px;
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
}
/deep/.van-button {
  left: 0px;
  margin: 10px 17px;
  font-size: 20px;
  justify-content: center;
}
.old {
  .biaoti {
    font-size: 18px !important;
  }
  ::v-deep .van-cell__title {
    font-size: 18px !important;
  }
  ::v-deep.van-field__control {
    font-size: 18px !important;
  }
  .jiben {
    .infomation span{
      font-size: 22px !important;
    }
  }
}
</style>
