<template>
  <div
    videoURL="videoURL"
    style="
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
    "
  >
    <video
      ref="video"
      preload="auto"
      muted
      autoplay
      type="rtmp/flv"
      style="width: 100%; height: 100%; object-fit: fill"
    ></video>
  </div>
</template>

<script>
import flvjs from "flv.js";
export default {
  props: {
    videoURL: {
      type: String,
      default: "",
    },
    // szWidth: {
    //   type: String,
    //   default: "500",
    // },
    // szHeight: {
    //   type: String,
    //   default: "400",
    // }
  },
  data() {
    return {};
  },
  created() {
    this.$nextTick(() => {
      if (this.videoURL) {
        this.initFlvPlay();
      }
    });
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    initFlvPlay() {
      let that = this;
      if (flvjs.isSupported()) {
        const flvPlayer = flvjs.createPlayer(
          {
            type: "flv",
            isLive: true,
            url: this.videoURL,
            //enableStashBuffer: false
          },
          {
            // enableStashBuffer: true
            //表示实时性优先，视频有可能不流畅
            autoCleanupSourceBuffer: true, //对SourceBuffer进行自动清理缓存
            enableStashBuffer: false, //关闭IO隐藏缓冲区
            stashInitialSize: 32,
          }
        );
        flvPlayer.attachMediaElement(this.$refs.video);
        flvPlayer.load();
        flvPlayer.play();
        this.flvPlayer = flvPlayer;

        this.flvPlayer.on(
          flvjs.Events.ERROR,
          (errorType, errorDetail, errorInfo) => {
            //播放器异常时，重新初始化
            console.log("flvPlayer_" + that.uid + " ERROR");
            that.resetFlvPlay();
          }
        );

        // this.$refs.video.addEventListener('pause', function () {
        //   //网络抖动导致暂停，重新初始化
        //   console.log("flvPlayer_" + that.uid + "pause");
        //   that.resetFlvPlay();
        // });
      }
    },
    resetFlvPlay() {
      this.detachMediaElement();
      this.initFlvPlay();
    },
    // 销毁
    detachMediaElement() {
      if (this.flvPlayer) {
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.detachMediaElement();
        this.flvPlayer.destroy();
        this.flvPlayer = null;
      }
    },
  },
  watch: {
    videoURL() {
      // 切换流之前，判断之前的流是否销毁
      this.flvPlayer == "" ? "" : this.detachMediaElement();
      // 初始化
      if (this.videoURL) this.initFlvPlay();
    },
  },
  beforeDestroy() {
    this.detachMediaElement();
  },
};
</script>

<style lang="less" scoped>
.jiankong {
  .jiankong-nav {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    border-radius: 4px;
    z-index: 10;
    cursor: pointer;
    .imgtubiao {
      width: 15px;
      height: 15px;
    }
    .imgwen {
      font-size: 14px;
      color: #00f6ff;

      margin: 0 0 0 5px;
    }
    .imgwen2 {
      font-size: 14px;
      color: #ffffff;
      margin: 0 0 0 5px;
    }
    .imgflex {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      position: absolute;
      margin: 0 0 0 10px;
      top: 10px;
      cursor: pointer;
      z-index: 100;
    }
    .title {
      //   width: 20%;
      margin: 10px 0 0 0;
      p {
        text-indent: 10px;
        color: #ffffff;
        font-size: 18px;
      }
    }
    .conten {
      //   width: 80%;
      margin: 5px 0 0 0;

      display: flex;
      span {
        margin: 0 10px;
        color: #ffffff;
      }
    }
  }
  .plugin {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    z-index: 0;
  }
}
</style>
