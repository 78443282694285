/*
 * @Description: 新埋点配置

 */

// import { APPID } from '@/config'

import store from "@/store/index.js";
class ZLOG {
  constructor(_user_id, _user_nick) {
    this.APPID = '2002319035'
    this.AppName = '文旅新业态'
    this._user_id = _user_id;
    this._user_nick = _user_nick;
    this.zwlog = new ZwLog({ _user_id: _user_id, _user_nick: _user_nick })
    this.performance = window.performance.getEntriesByType('navigation')[0]
    // this.sendPV()
  }
  sendPV(to) {
    this.zwlog.onReady(() => {
      this.zwlog.sendPV({
        // _user_id: this._user_id,
        // _user_nick: this._user_nick,
        pageid: to.name,
        pagename: to.name,
        miniAppId: this.APPID,
        miniAppName: this.AppName,
        t2: this.getT2(),
        t0: this.getT0(),
        log_status: this._user_id ? '02' : '01'
      })
    })
  }
  getT2() {
    return (this.performance.responseStart - this.performance.startTime) / 1000
  }
  getT0() {
    return (this.performance.responseEnd - this.performance.startTime) / 1000
  }
}


let zwlog;

function setZwLog(userId, username) {
  zwlog = new ZLOG(userId, username)
}

export { zwlog, setZwLog }
