<template>
  <div class="img-upload-box" :class="{ old: isElder }">
    <div class="img-item" v-for="(imgSrc, index) in imgList" :key="index">
      <div class="close" @click.stop="closeImg(index)">x</div>
      <van-image width="100%" height="100%" @click.stop="imgShow(index)" :src="imgSrc" />
    </div>
    <van-uploader class="upload-box" accept="image/*,video/*" :capture="capture" :after-read="afterRead" :multiple="choice">
      <div class="Box">
        <div class="round">
          <img :src="jia" alt="" />
        </div>
      </div>
    </van-uploader>
    <div class="titleSpecial">* 支持jpg,png的图片格式,图片无大小限制</div>
    <van-image-preview v-model="show" :images="imgList" @change="onChange"></van-image-preview>
  </div>
</template>
<script>
import Client from "@/utils/ossClient";

export default {

  data () {
    return {
      imgList: [],
      show: false,
      index: 0,
      jia: require("@/assets/img/waring/jia.png"),
      choice: true, //开始多选
    };
  },
  mounted () {

  },
  props: {
    capture: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: []
    }
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    //上传图片到服务器
    afterRead (file) {
      // 如果是数组
      if (Array.isArray(file)) {
        file.map((f) => this.fileUpload(f));
      } else {
        this.fileUpload(file);
      }
    },
    fileUpload (file) {
      const fileExtension = file.file.name.substring(
        file.file.name.lastIndexOf(".")
      );
      const objectKey = "gt/" + this.uuid() + fileExtension;
      let client = Client();
      client
        .put(objectKey, file.file)
        .then((result) => {
          this.imgList.push(result.url);
          this.$emit('change', this.imgList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChange (index) {
      this.index = index;
    },
    uuid () {
      //生成随机数
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },
    closeImg (index) {
      this.imgList.splice(index, 1);
      this.$emit('change', this.imgList);
    },
    imgShow (index) {
      this.index = index;
      this.show = true;
    },
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  watch: {
    value: {
      handler (newVal) {
        // console.log('newVal: ', newVal);
        this.imgList = newVal;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="less" scoped>
.van-uploader__preview-image {
  width: 104px;
  height: 104px;
}
.van-uploader__preview {
  padding: 0 0px 0 0;
  margin: 0 15px 0px 0px;
}
.van-field__word-limit {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0c90ff;
}
.van-cell {
  margin-top: 15px;
  background: #fff;
  border-radius: 9px;
  padding: 16px 16px;
  font-size: 17px;
}
.van-cell::after {
  border-bottom: 0px;
}

.img-item {
  display: inline-block;
  width: 105px;
  height: 105px;
  padding: 5px;
  margin-right: 9px;
  box-sizing: border-box;
  position: relative;
  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px;
    background: #000;
    border-radius: 15px;
    color: #fff;
    line-height: 1;
    font-size: 14px;
    text-align: center;
    z-index: 2000;
  }
}
.upload-box {
  // display: inline-block;
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 !important;
}

.img-upload-box {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 10px;
  padding: 15px 0;
  img {
    width: 87px;
    height: 75px;
    border-radius: 5px;
    border: none;
  }
  .Box {
    width: 104px;
    height: 104px;
    background: #e9f3fe;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .round {
      width: 38px;
      height: 38px;
      background: #0c90ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 19px;
        height: 19px;
      }
    }
  }
}
.titleSpecial{
  color:red;
  font-size:14px;
  // position: absolute;
  left: 25px;
  margin-top: 10px;
 }
 .old{
  padding-bottom: 10px;
  .titleSpecial{
    font-size:18px;
  }
 }
</style>