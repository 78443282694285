<template>
  <div class="FormBox" :class="{ old: isElder }">
    <div class="yuanBox">
      <div>
        <div class="yinying">
          <div class="xvxian">
            <div class="shixin">
              <div class="word">
                <span class="fenshu"
                  >{{ total }}<span class="fen">分</span></span
                >
                <span class="defen">景区得分</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 选择景区 -->
      <van-button
        type="default"
        round
        :icon="jiantou"
        icon-position="right"
        style="
          width: 50%;
          margin-top: 30px;
          background: #f6f7f8;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #363a44;
        "
        @click="showPicker = true"
        >{{ buttomName }}</van-button
      >
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
          toolbar-position="bottom"
        />
      </van-popup>
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>得分明细</span>
      </div>
    </div>
    <div class="list" v-if="list.length">
      <div class="jinduBox" v-for="(item, index) in list" :key="index">
        <div class="word">
          <span>{{ item.statItem }}</span>
          <span>{{ item.score }}/10</span>
        </div>
        <van-progress
          :percentage="item.score"
          :stroke-width="10"
          color="#1C91FF"
          track-color="#EAF5FF"
          :show-pivot="jindu"
        />
      </div>
    </div>
    <div v-else class="noData">
      <no-data></no-data>
    </div>
  </div>
</template>
<script>
import "vant/lib/picker/style/less";
import api2 from "@/api/home/index";
import noData from "@/components/no-data";
import request from "@/api/index";
export default {
  components: { noData },
  data() {
    return {
      form: {
        value: "",
      },
      total: 0,
      buttomName: "垂云通天河",
      jiantou: require("@/assets/img/my/jiantou.png"),
      jindu: false,
      list: [],
      showPicker: false,
      toolbaShow: false,
      columns: [],
      values: [],
      scenicSpotId: this.$store.state.scenicSpotId,
    };
  },
  methods: {
    getDatas() {
      let now = new Date();
      request.request
        .post("/api/app/home/assess/statList", {
          scenicSpotId: this.scenicSpotId,
          statType: 2, // 2月统计，3季统计，4年统计
          statYear:
            now.getMonth() == 0 ? now.getFullYear() - 1 : now.getFullYear(),
          statMonth: now.getMonth() == 0 ? 12 : now.getMonth(),
        })
        .then((res) => {
          let data = res.data;
          let buildData = data.filter((c) => c.title == "建设资料");
          let ziduData = data.filter((c) => c.title == "管理制度");
          let logData = data.filter((c) => c.title == "管理记录");
          let buildInfoData = [];
          buildInfoData[0] = buildData;
          buildInfoData[1] = ziduData;
          buildInfoData[2] = logData;
          let datas = [];
          this.list = [];
          this.total = 0;
          buildInfoData.forEach((c, i) => {
            if (c && c[0]) {
              let total = c.reduce((total, item) => total + item.total, 0);
              let finish = c.reduce((total, item) => total + item.finish, 0);
              let num = Math.floor((finish / total) * 10);
              c.finish = finish;
              this.list.push({
                statItem: c[0].title,
                score: num <= 9 ? "0" + num : num,
              });
            }
          });
          console.log("datas", datas);
          this.total = this.list.reduce(
            (total, item) => total + Number(item.score),
            0
          );
        });
    },

    //获取景区列表
    getScenicAreaList() {
      api2.scenicSpotList().then((res) => {
        if (res.code == "200") {
          this.columns = [];
          this.values = [];
          res.data.forEach((item) => {
            if (item.id == this.scenicSpotId) {
              this.buttomName = item.name;
            }
            this.columns.push(item.name);
            this.values.push(item.id);
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
    onConfirm(value, index) {
      this.buttomName = value;
      this.scenicSpotId = this.values[index];
      console.log();
      this.getDatas();
      // this.form.value = value;
      this.showPicker = false;
      // this.buttomName = value;
    },
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  created() {
    this.getScenicAreaList();
    this.getDatas();
  },
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
@import url("~@/assets/less/vantChange.less");

.FormBox {
  margin: 10px 10px;

  .yuanBox {
    height: 308px;
    background: #fff;
    justify-content: center;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .yinying {
        margin-top: 20.5px;
        width: 185.5px;
        height: 185.5px;
        border-radius: 50%;
        background: #daedfd;
        .zhongjian();

        .xvxian {
          width: 162px;
          height: 162px;
          border-radius: 50%;
          border: 1px dashed #1c91ff;
          .zhongjian();

          .shixin {
            width: 140.5px;
            height: 140.5px;
            background: linear-gradient(175deg, #8ac2f9 0%, #1c91ff 100%);
            border-radius: 50%;
            .zhongjian();

            .word {
              display: flex;
              flex-direction: column;

              .fenshu {
                font-size: 44px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #ffffff;

                .fen {
                  color: #fff;
                  font-size: 24px;
                }
              }

              .defen {
                align-self: center;
                color: #0063c0;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .van-button__content {
      justify-content: space-between;

      .van-icon__image {
        width: 10px;
        height: 17px;
      }
    }
  }

  .jiben {
    margin-top: 18px;
    background: transparent;

    .infomation {
      padding: 0px 0px;
    }
  }

  .list {
    margin-top: 10px;
    // height: 338.5px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0px 23px 23px 23px;

    .jinduBox {
      padding-top: 24px;
      height: 36px;
      .shangxia();

      .word {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        .liangbian();
      }
    }
  }
}

/deep/.van-picker__confirm {
  margin-left: 30px !important;
}

/deep/.van-picker__cancel {
  font-size: 20px;
}
.old {
  .defen {
    font-size: 18px !important;
  }
  .van-button__content {
    .van-button__text {
      font-size: 20px !important;
    }
  }
  .jiben span{
    font-size: 20px !important;
  }
  .list span {
    font-size: 20px !important;
  }
}
</style>
