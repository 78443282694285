import request from '@/api/index';

export default {
  xunchaList(data) {
    return request.get('xunchaList', data)
  },
  xunchaxiangqing(data) {
    return request.get('xunchaxiangqing', data)
  },
  patrolHandleSave(data) {
    return request.get('patrolHandleSave', data)
  },
  examineCompanyFlowSave(data) {
    return request.post2('examineCompanyFlowSave', data)
  },
  statrPatrol(data) {
    return request.post2('statrPatrol', data)
  },
  auditPatrolDetail(data) {
    return request.get('auditPatrolDetail', data)
  },
  patrolListNum(data) {
    return request.get('patrolListNum', data)
  },
  patrolImmediateProcessing(data) {
    return request.post2('patrolImmediateProcessing', data)
  },
  preservationPatrol(data) {
    return request.post2('preservationPatrol', data)
  },
  startDeal(data) {
    return request.post2('startDeal', data)
  }
}