<template>
  <div class="xiangqingBox" :class="{ old: isElder }">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
      </div>
      <div class="xiangxiList">
        <!-- <div class="con"> -->
          <van-field label-class="title" label="预警设施" :value="Data.facilityName || '无'" readonly />
        <!-- </div> -->
        <div class="xian"></div>
      </div>
      <div class="xiangxiList">
        <!-- <div class="con"> -->
          <van-field label-class="title" label="预警项" :value="Data.monitorTerm || '无'" readonly />
        <!-- </div> -->
        <div class="xian"></div>
      </div>
      <div class="xiangxiList">
        <!-- <div class="title">任务标题</div> -->
        <!-- <div class="con"> -->
          <van-field
            label-class="title"
            label="预警设备"
            :value="Data.equipmentCode || '无'"
            readonly
          ></van-field>
        <!-- </div> -->
        <!-- <div class="xian"></div> -->
      </div>
      <!-- <div class="list">
        <div class="con">
          <van-field v-model="Data.auditCompanyName" readonly label="查阅单位"></van-field>
        </div>
      </div> -->
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>预警内容</span>
      </div>
      <div class="xiangxiList">
        <van-field
          label-class="title"
          label="内容详情"
          rows="1"
          type="textarea"
          readonly
          autosize
          :value="Data.warningContent || '无'"
        ></van-field>
        <div class="xian"></div>
      </div>
      <div class="xiangxiList">
        <van-field
          label-class="title"
          :value="Data.warningTime || '无'"
          readonly
          label="预警时间"
        ></van-field>
      </div>
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>处理人员</span>
      </div>
      <div class="xiangxiList">
        <!-- <div class="con"> -->
          <van-field
            label-class="title"
            :value="personPerson"
            readonly
            label="人员姓名"
          ></van-field>
        <!-- </div> -->
        <div class="xian"></div>
      </div>
      <div class="xiangxiList">
        <van-field
          label-class="title"
          :value="mobile"
          readonly
          label="联系方式"
        ></van-field>
      </div>
    </div>
    <div>
      <van-button round size="large" @click="dealWith" type="info"
        >立即处理</van-button
      >
    </div>
  </div>
</template>
<script>
import api from "@/api/waring/index";
export default {
  data() {
    return {
      form: { warningHandleId: null },
      Data: {},
      stateShow: true,
    };
  },

  created() {
    this.form.warningHandleId = this.$route.query.id;
  },
  mounted() {
    this.getList();
    if (this.state == 1 || this.state == 4) {
      this.stateShow = true;
    } else {
      this.stateShow = false;
    }
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
    state() {
      const state = this.$route.query.state || 0;
      return state;
    },
    personPerson() {
      return this.$store.state.userName;
    },
    mobile() {
      return this.$store.state.mobile;
    },
    
  },
  methods: {
    getList() {
      api.warningHandleDetail(this.form).then((res) => {
        if (res.code == "200") {
          this.Data = res.data;
          console.log(this.Data);
        } else {
          this.$toast(res.message);
        }
      });
    },
    //立即处理
    dealWith() {
      if (this.state == 2) {
        this.$router.push({
          name: "dealWith",
          query: {
            warningHandleId: this.form.warningHandleId,
          },
        });
      } else {
        api.immediateProcessing(this.form).then((res) => {
          if (res.code == "200") {
            this.$router.push({
              name: "dealWith",
              query: {
                warningHandleId: this.form.warningHandleId,
              },
            });
          } else {
            this.$toast(res.message);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
// @import url("../../assets/less/vantChange.less");
.xiangqingBox {
  margin: 10px 0px 10px 0px;
  height: 100%;
}
.jiben {
  &:nth-child(2) {
    .xiangxiList {
      // margin-top: 10px;

      .van-field {
        height: 54.5px;
        display: flex;
        align-items: center;
      }

      ::v-deep.title {
        font-family: PingFang SC;
        font-weight: 400;
        color: #b3b5b9;
        font-size: 14px;
      }
    }
  }
  .infomation {
    margin-bottom: 10px;
    span {
      font-size: 15px !important;
    }
    .kuai {
      width: 4px;
      height: 16px;
    }
  }
  .jiben();
  .list {
    align-items: center;
    .van-cell {
      font-size: 18px;
      color: #686b73;
      background: transparent;
    }
    .con {
      width: 100%;
      font-size: 18px;
      .van-cell {
        color: #363a44 !important;
        font-size: 18px;
        padding: 10px 0px;
      }
      .van-cell__value {
        color: #b3b5b9;
      }
      .popupBox {
        padding: 48px 34px 38px 34px;
        .title {
          align-self: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #363a44;
        }
        .shangxia();
        .xuanze {
          .van-checkbox {
            justify-content: space-between;
            &:first-child {
              margin: 0 0 0 0;
            }
            margin-top: 27px;
          }
        }
        .buttomBox {
          width: 100%;
          height: 54px;
          .liangbian();
          align-items: center;
          margin-top: 103px;
          .close {
            height: 54px;
            width: 39%;
            border-radius: 27px;
            border: 1px solid #0085ff;
            color: #0085ff;
            font-size: 20px;
            text-align: center;
            line-height: 54px;
          }
          .sure {
            width: 39%;
            height: 54px;
            border-radius: 27px;
            text-align: center;
            line-height: 54px;
            background: #1c91ff;
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }
  }
}
/deep/.van-button {
  position: absolute;
  bottom: 27px;
  left: 0px;
  font-size: 20px;
  width: 90%;
  justify-content: center;
  margin-left: 17px;
  margin-right: 17px;
  box-sizing: border-box;
}
/deep/.van-field__label {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #b3b5b9;
  margin-right: 14px;
  // width: 61px;
}
/deep/.van-field__control {
  // font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #363a44;
}

.old {
  .jiben span {
    font-size: 22px !important;
  }
  ::v-deep.van-field__label {
    font-size: 20px !important;
    // width: 81px;
  }
  ::v-deep .van-cell__title {
    font-size: 20px !important;
    // font-weight: bold;
  }
  ::v-deep.van-field__control {
    font-size: 20px !important;
  }
}
</style>
