<template>
  <div class="patrolBox" :class="{ old:  isElder}">
    <div class="option">
      <div
        @click="yishenClick('已审核')"
        :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'"
      >
        <div class="word">
          <!-- <div class="dian" v-show="yishenNumber!=0">{{ yishenNumber }}</div> -->
          已处理
        </div>
      </div>
      <div
        @click="xunClick('')"
        class="yishenheNoClick"
        :class="xunShow ? 'xunClick' : ''"
      >
        <div class="word">
          <div class="dian" v-show="xunNumber != 0">{{ xunNumber }}</div>
          待处理
        </div>
      </div>
    </div>
    <Search
      v-model="form"
      @changeclickSearch="clickSearch"
      @changeonConfirm="onConfirm"
    ></Search>
    <div class="content">
      <div class="list" v-if="Data1.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="0"
          :immediate-check="false"
        >
          <div
            class="box"
            @click="xiangqing(item)"
            v-for="(item, index) in Data1"
            :key="index"
          >
            <div class="contentBox">
              <div  v-show="!xunShow">
                <van-image :src="item.imgUrl">
                  <template v-slot:error>加载失败</template>
                </van-image>
                <!-- <img
                  
                  :src="item.imgUrl"
                  alt=""
                /> -->
                <!-- <img  v-show="!xunShow" :src="item.imgUrl" alt="" /> -->
              </div>
              <div class="word">
                <div class="title">{{ item.facilityName || "无" }}</div>
                <div
                  class="biaoti van-ellipsis"
                  style="width: 90%; margin-top: 4px"
                >
                  预警设备:{{ item.equipmentCode || "无" }}
                </div>
                <div class="biaoti van-ellipsis" style="width: 90%">
                  预警项：{{ item.monitorTerm || "无" }}
                </div>

                <div class="time">
                  {{
                    form.examineState == 3
                      ? "解除时间："
                      : form.examineState == 2
                      ? "上传时间："
                      : "预警时间："
                  }}{{
                    form.examineState == 3
                      ? item.removeTime || "无"
                      : form.examineState == 2
                      ? item.createTime || "无"
                      : item.warningTime || "无"
                  }}
                </div>
              </div>
              <div v-if="xunShow" class="chakan">
                <van-button type="info" @click.stop="chakan(item)"
                  >处理</van-button
                >
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <no-data v-else></no-data>
    </div>
  </div>
</template>
<script>
import api from "@/api/waring/index";
import noData from "@/components/no-data";
import Search from "@/components/Search";
import dayjs from "dayjs";
export default {
  components: {
    noData,
    Search,
  },
  data() {
    return {
      form: {
        keyWord: "",
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        scenicSpotId: ""
      },
      rili: false,
      riqi: require("../../assets/img/my/xiajiantou.png"),
      Data1: [],
      searchIcon: require("../../assets/img/patrol/search.png"),
      yishenShow: true,

      yishendian: true,
      daiShow: false,
      daishenNumber: null,
      daishendian: true,
      xunShow: false,
      yishenNumber: 0,
      xunNumber: 0,
      xundian: true,
      user: require("../../assets/img/task/user.png"),
      gaojin: require("../../assets/img/task/gaojin.png"),
      zhiding: require("../../assets/img/task/zhiding.png"),
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      loading: false,
      finished: false,
    };
  },
  created() {
    this.form.scenicSpotId = this.$store.state.scenicSpotId
    this.yiShow = true;
    if (this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.xunShow == true) {
      this.xundian = false;
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.finished = false;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    }
    this.getList();
    this.getWarningHandleListNum();
  },
  methods: {
    onLoad() {
      this.form.pageNum++;
      this.loading = true;
      api.warnList(this.form).then((res) => {
        this.loading = false;
        if (res.data.rows.length !== 0) {
          for (var i in res.data.rows) {
            this.Data1.push(res.data.rows[i]);
          }
        } else if (res.data.rows.length == 0) {
          this.finished = true;
          return;
        }
      });
    },
    //获取未读数量
    getWarningHandleListNum() {
      const scenicSpotId = this.$store.state.scenicSpotId;
      api
        .warningHandleListNum({
          scenicSpotId: scenicSpotId,
        })
        .then((res) => {
          if (res.code == "200") {
            console.log(res.data);
            this.yishenNumber = res.data.implementNum;
            this.xunNumber = res.data.unImplementNum;
          }
        });
    },
    //获取列表数据
    getList() {
      this.Data1 = [];
      this.form.pageNum = 0;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.onLoad();
      // api.warnList(this.form).then((res) => {

      //   if (res.code == "200") {
      //     this.Data1 = res.data.rows;
      //   }
      // });
    },

    chakan(item) {
      this.$router.push({
        name: "waringForm",
        query: {
          name: "预警审核",
          id: item.id,
          state: item.examineState,
        },
      });
    },
    xiangqing(item) {
      //   已审核跳转页面
      if (this.form.examineState == 3) {
        this.$router.push({
          name: "waringchakan",
          query: {
            name: "预警已审核的页面",
            id: item.id,
            state: item.examineState,
          },
        });
      } else {
        //未审核跳转页面
        this.$router.push({
          name: "waringForm",
          query: {
            name: "预警审核页面",
            id: item.id,
            state: item.examineState,
          },
        });
      }
    },

    clickSearch() {
      this.form.pageNum = 1;
      this.getList();
    },
    onConfirm() {
      this.form.pageNum = 1;
      this.getList();
    },
    yishenClick() {
      this.form.keyWord = "";
      this.form.startTime = null;
      this.form.endTime = null;
      this.form.examineState = 3;
      this.form.pageNum = 1;
      this.finished = false;
      this.getList();
      this.$router.replace({
        path: "/waring/list",
      });
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    daishenClick() {
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
      this.form.examineState = 2;
      this.getList();
    },
    xunClick() {
      (this.form.keyWord = ""),
        (this.form.startTime = null),
        (this.form.endTime = null),
        (this.finished = false);
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
      this.getList();
      this.$router.replace({
        path: "/waring/list",
        query: {
          type: 1,
        },
      });
    },
    jialing(s) {
      return s < 10 ? "0" + s : ss;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${this.jialing(
        date.getMonth() + 1
      )}-${this.jialing(date.getDate())}`;
    },
  },

  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");

.patrolBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .option {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e8e9ec;

    .box {
      width: 73px;
      height: 100%;
      color: #0c90ff;
      font-size: 16px;
      border-bottom: 3px solid #0c90ff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .iconstyle {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-top: 3px;
    }

    .dianstyle {
      width: auto;
      height: 14px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 30%;
      text-align: center;
      position: absolute;
      right: -10px;
      top: 0px;
      line-height: 14px;
      font-size: 16px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("../../assets/img/patrol/yishenhe.png");
      }
    }

    .yishenheNoClick {
      width: 73px;
      height: 100%;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFang SC;
      line-height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("../../assets/img/patrol/daishenhe.png");
      }
    }

    .xunClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("../../assets/img/patrol/daixuncha.png");
      }
    }
  }
  .content {
    flex: 1;
    .list {
      margin-top: 10px;
      height: 100%;
      overflow-y: auto;
      padding: 0 10px 10px 10px;

      .box {
        &:first-child {
          margin-top: 0px;
        }

        padding: 8px 0;
        margin-top: 10px;
        position: relative;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .biaoshiStyle {
          position: absolute;
          left: 0;
          top: 0;
          width: 50px;
          height: 20px;
          color: #fff;
          border-radius: 10px 0px 10px 0px;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
        }

        .lvbiaoshi {
          .biaoshiStyle();
          background: #50cb79;
          box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
        }

        .huangbiaoshi {
          background: #ffac20;
          .biaoshiStyle();
          box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
        }

        .hongbiaoshi {
          background: #ff6661;
          .biaoshiStyle();
          box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
        }

        .contentBox {
          margin: 5px 16px 0px 16px;
          display: flex;
          align-items: center;
          .imgBox {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 1px 0px rgb(108, 107, 107);
            align-self: center;

            .gaojin {
              width: 100%;
              height: 100%;
            }

            .user {
              width: 20px;
              height: 18px;
            }
          }

          .word {
            font-family: PingFang SC;
            color: #686b73;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;
            width: 79%;

            .title {
              font-size: 16px;
              font-weight: 600;
              color: #363a44;
            }

            .van-ellipsis {
              font-size: 16px;
            }
            .time {
              font-size: 16px;
            }
          }

          img {
            height: 85px;
            width: 96px;
            border-radius: 8px;
            align-self: end;
          }
          ::v-deep img{
            border-radius: 8px;
          }
          .van-image{
            height: 85px;
            width: 96px;
            border-radius: 8px;
            align-self: end;
          }

          .chakan {
            display: flex;
            flex-direction: column;
            justify-content: center;

            > .time {
              color: #686b73;
              font-size: 16px;
              align-self: flex-end;
            }

            .van-button {
              width: 80px;
              height: 25px;
              font-size: 16px;
              background: #1c91ff;
              box-shadow: 0px 3px 5px 0px rgba(28, 128, 222, 0.2);
              border-radius: 21px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.old {
  .title {
    font-size: 24px !important;
  }
  .van-ellipsis {
    font-size: 18px !important;
  }
  .time {
    font-size: 18px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 78%;
  }

  .van-button {
    font-size: 18px !important;
  }
  .list {
    .contentBox img{
      height: 110px !important;
      width: 105px !important;
    }
    .contentBox .van-image{
      height: 110px !important;
      width: 105px !important;
    }
    .contentBox {
      .word {
        margin-left: 15px !important;
        width: 70% !important;
      }
      // align-items: flex-start !important;
    }
  }
}
</style>
