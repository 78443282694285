<template>
  <div class="FormBox" :class="{ old: isElder }">
    <!-- <div v-if="xiangqingShow" class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
        <div class="xian"></div>
      </div>
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="con">{{ item.con }}</div>
        <div class="xian"></div>
      </div>
    </div>
    <div v-if="xiangqingShow" class="project">
      <div class="infomation">
        <div class="kuai"></div>
        <span>巡查项目</span>
        <div class="xian"></div>
      </div>
      <div class="xiangqing">
        <div class="name">项目详情</div>
        <div class="con">
          <div class="shixiang" v-for="(item, index) in shixiang" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="xian"></div>
      </div>
      <div class="time">
        <div class="title">发布时间</div>
        <div class="timer">{{ cretime }}</div>
        <div class="xian"></div>
      </div>
    </div> -->
    <van-form v-if="!xiangqingShow" @submit="onSubmit">
      <div class="jiben">
        <div class="infomation">
          <div class="kuai"></div>
          <span>基本信息</span>
        </div>
        <div class="list" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
        <!-- <div class="list">
          <div class="title">任务内容</div>
          <div class="con" style="width: 67%">
            {{ Data.comment }}
          </div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="title">任务截止时间</div>
          <div class="con">{{ Data.taskTerm }}</div>
          <div class="xian"></div>
        </div> -->
      </div>
      <!-- <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>执行人员</span>
        </div>
        <div class="list" v-for="(item, index) in zhixing" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
      </div> -->
      <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>任务反馈</span>
        </div>
        <div class="list" style="">
          <div class="title">反馈内容</div>
          <div class="con" style="width: 67%">
            {{ fistData.feedbackComment }}
          </div>
          <div class="xian"></div>
        </div>
        <div class="tu">
          <span>反馈图片</span>
          <div class="imgBox">
            <img
              v-for="(item, index) in fistData.imageUrl"
              :key="index"
              :src="item"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核进度</span>
        </div>
        <van-steps
          direction="vertical"
          active="0"
          active-color="#1C91FF"
          :active-icon="stepIcon"
        >
          <van-step v-for="(item, index) in reviewProgress" :key="index">
            <span>{{ item.operateTime }}{{ item.title }}</span>
            <div class="shenheqingkuang">
              <div>
                {{ item.jsonObject.companyName }}：<span>{{
                  item.jsonObject.examineState == 3 ? "已通过" : "未通过"
                }}</span>
              </div>
              <img
                :src="item.jsonObject.examineState == 3 ? pass : nopass"
                alt=""
              />
            </div>
          </van-step>
        </van-steps>
      </div>
    </van-form>
  </div>
</template>
<script>
import api from "@/api/task/index";
export default {
  data() {
    return {
      list: [
        {
          title: "任务编号",
          con: "",
        },
        {
          title: "任务标题",
          con: "",
        },
        {
          title: "接收单位",
          con: "",
        },
        {
          title: "查阅单位",
          con: "",
        },
      ],
      shixiang: [],
      zhixing: [
        {
          title: "人员姓名",
          con: "xxx",
        },
        {
          title: "联系电话",
          con: "123-1234-5678",
        },
      ],
      fankui: [
        {
          text: "1.滑道表面是否干燥、平整、坚实、光滑，有无霉变、杂物，滑道末端阻尼垫铺设是否正常。",
          state: 0,
        },
        {
          text: "2.乘客滑行装备（含防护裤、手套、头盔等）有无破损、是否可正常使用。",
          state: 0,
        },
      ],
      shenhejindu: [
        {
          step: "县文广旅体局: ",
          pass: 0,
          time: "10:10",
        },
        {
          step: "县城管局: ",
          pass: 1,
          time: "10:10",
        },
      ],
      xiangqingShow: false,
      activeIcon: require("../../assets/img/patrol/zhengchang.png"),
      inactiveIcon: require("../../assets/img/patrol/yichang.png"),
      xuanzhongIcon: require("../../assets/img/patrol/xuanzhong.png"),
      pass: require("../../assets/img/patrol/pass.png"),
      nopass: require("../../assets/img/patrol/nopass.png"),
      stepIcon: require("../../assets/img/patrol/stepIcon.png"),
      cretime: "",
      id: {
        taskId: 0,
      },
      allData: {},
      fistData: {},
      reviewProgress: [],
    };
  },
  created() {
    this.id.taskId = this.$route.query.id;
    this.form.id = this.id.taskId;
    let state = this.$route.query.state;
    if (state == "已审核") {
      this.xiangqingShow = true;
      this.getlist();
    } else if (state == "待审核") {
      this.xiangqingShow = false;
    } else {
      this.xiangqingShow = false;
    }
  },
  mounted() {
    this.getlist();
  },
  methods: {
    //获取审核详情
    getlist() {
      api.auditTaskDetail(this.id).then((res) => {
        console.log(res.data);
        this.Data = res.data;
        this.list[0].con = res.data.operateLogList[0].jsonObject.taskCode;
        this.list[1].con = res.data.operateLogList[0].jsonObject.title;
        this.list[2].con = res.data.operateLogList[0].jsonObject.companyName;
        this.list[3].con =
          res.data.operateLogList[0].jsonObject.examineCompanyNames;
        this.fistData = res.data.operateLogList[2].jsonObject;
        this.allData = res.data;
        let a = JSON.parse(JSON.stringify(this.allData.operateLogList));
        let a1 = a;
        let arr = a1.splice(3);
        let Arr = [];
        Arr.push(arr);
        Arr.forEach((i) => {
          i.forEach((j) => {
            this.reviewProgress.push(j);
          });
        });
        console.log(this.reviewProgress);
      });
    },
    onSubmit() {},
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
.FormBox {
  .jiben {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 0px;
    .infomation {
      padding: 15px 17px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      .kuai {
        width: 4px;
        height: 16px;
        background: #1c91ff;
      }
      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }
    .list {
      padding: 20px 17px;
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: flex-start;
      // border-bottom: 1px solid #000;
      .title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
        width: 62px;
      }
      .con {
        color: #363a44;
        font-size: 16px;
      }
      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .fankui {
      height: 254px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      .fankuixiangqing {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        line-height: 23px;
      }
      .van-radio-group--horizontal {
        justify-content: center;
      }
      .img-icon {
        height: 16px;
      }
      .fankuiImg {
        display: flex;
        flex-direction: column;
        height: 110px;
        justify-content: space-between;
        .word {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }
        img {
          width: 87px;
          height: 75px;
          border-radius: 5px;
          border: none;
        }
      }
      .xian {
        .hengxian();
      }
    }
    .result {
      height: 30px;
      display: flex;
      justify-content: space-between;
      .hezibj10px();
      position: relative;
      div {
        &:first-child {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }
        &:nth-child(2) {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
        }
      }
      .xian {
        .hengxian();
      }
    }
    .con {
      word-break: break-all;
      .liangbian();
      padding-left: 15px;
      position: relative;
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      div {
        width: 237px;
        height: 73px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
      }
      .xian {
        .hengxian();
      }
    }
    .tu {
      background: #fff;
      padding: 18px 0px;
      span {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .hezibj15px();
      .shangxia();
      height: 121px;
      img {
        .imgBox {
          .liangbian();
        }
        width: 95px;
        height: 82px;
        border-radius: 5px;
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    .van-radio-group--horizontal {
      justify-content: center;
    }
    .img-icon {
      height: 18px;
    }
    .van-steps {
      .shenheqingkuang {
        padding: 18px 27px 18px 16px;
        margin-top: 21px;
        background: #f6f7f8;
        border-radius: 8px;
        color: #363a44;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        span {
          color: #686b73;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }
      .van-step--vertical:not(:last-child)::after {
        border-bottom-width: 0px;
      }
    }
  }
  .project {
    margin-top: 10px;
    height: 319px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > .infomation {
      padding-left: 10px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 32px;
      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }
      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }
    .xiangqing {
      height: 165px;
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      margin-right: 15px;
      position: relative;

      .name {
        font-size: 16px;
        color: #686b73;
      }
      .con {
        width: 234px;
        font-size: 16px;
        color: #363a44;
        line-height: 23px;
        overflow: auto;
        margin-bottom: 5px;
        .shixiang {
          margin-top: 19px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .time {
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      height: 36px;
      display: flex;
      justify-content: space-between;
      color: #686b73;
      > .timer {
        align-self: flex-start;
        color: #363a44;
      }
      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
/deep/.van-radio {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #686b73;
}
/deep/.van-step__circle {
  width: 12px;
  height: 12px;
}
/deep/.van-step--finish {
  color: #1c91ff;
  font-size: 15px;
  font-weight: 500;
}

/deep/ .van-step--vertical .van-step__line {
  width: 0 !important;
  border: 1px solid #1c91ff !important;
}
</style>
