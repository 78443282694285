<template>
  <div class="patrolBox" :class="{ old: isElder }">
    <div class="option">
      <div
        @click="yishenClick('已审核')"
        :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'"
      >
        <div class="word">
          <!-- <div class="dian" v-show="yishendian!=0">{{ yishenNumber }}</div> -->
          已处理
        </div>
      </div>
      <div
        @click="xunClick('')"
        class="yishenheNoClick"
        :class="xunShow ? 'xunClick' : ''"
      >
        <div class="word">
          <div class="dian" v-show="xunNumber != 0">{{ xunNumber }}</div>
          待处理
        </div>
      </div>
    </div>
    <Search
      v-model="form"
      @changeclickSearch="clickSearch"
      @changeonConfirm="onConfirm"
    ></Search>
    <div v-if="isElder" class="list"> <!--适老化样式-->
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="0"
        :immediate-check="false"
      >
        <div
          class="item"
          @click="xiangqing(item.id, item.examineState)"
          v-for="(item, index) in Data"
          :key="index"
        >
        <div class="top">
          <div class="topLeft">
            <div
              v-show="xunShow && item.examineState == 5"
              :class="
                item.examineState == 4
                  ? 'lvbiaoshi'
                  : item.examineState
                  ? 'huangbiaoshi'
                  : ''
              "
            >
              {{
                item.examineState == 4
                  ? "已阅"
                  : item.examineState == 5
                  ? "已退回"
                  : ""
              }}
            </div>
            <div
              v-show="!xunShow"
              :class="
                item.examineState == 4
                  ? 'lvbiaoshi'
                  : item.examineState == 3
                  ? 'huangbiaoshi'
                  : ''
              "
            >
              {{
                item.examineState == 4
                  ? "已阅"
                  : item.examineState == 3
                  ? "待阅"
                  : ""
              }}
            </div>
          </div>
          <div class="time">{{ dealTime(item.modifiedTime) }}</div>
        </div>
        <div class="bottom">
          <div class="left">
            <template v-if="xunShow">
              <img :src="user" alt="" class="user" />
            </template>
            <template v-else>
              <!-- <img :src="item.imgUrl ? item.imgUrl : zhanwei" alt="" /> -->
              <van-image :src="item.imgUrl ? item.imgUrl : zhanwei">
                <template v-slot:error>加载失败</template>
              </van-image>
            </template>
          </div>
          <div class="right">
            <div class="word">
              <div class="title" v-if="xunShow">
                {{ item.companyName }}
              </div>
              <div class="title" v-if="!xunShow">
                {{ item.scenicSpotName }}
              </div>
              <div class="biaoti" v-if="xunShow">
                {{ item.type == "gaojin" ? "预警设备：" : "任务标题："
                }}{{ item.title || '无' }}
              </div>
              <div class="biaoti" v-if="!xunShow">
                {{ item.type == "gaojin" ? "预警项：" : "任务标题："
                }}{{ item.title || '无' }}
              </div>

              <!-- 这个待处理时出现 -->
              <div class="biaoti" v-if="xunShow">
                任务内容：{{ item.comment || '无' }}
              </div>
              <div class="biaoti van-ellipsis" v-if="!xunShow">
                {{ item.type == "gaojin" ? "预警项：" : "任务反馈："
                }}{{ item.feedbackComment || '无' }}
              </div>
              <div class="biaoti" v-if="!xunShow">
                {{ item.type == "gaojin" ? "解除时间：" : "上传时间："
                }}{{ item.executeTime || '无' }}
              </div>
            </div>
            <div v-if="xunShow" class="chakan">
            <van-button
              class="button"
              size="small"
              round
              type="info"
              @click.stop="chakan(item.id, item.examineState)"
              >处理</van-button
            >
          </div>
          </div>
        </div>
        </div>
      </van-list>
      <no-data v-if="Data.length == 0"></no-data>
    </div>
    <div v-else class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="0"
        :immediate-check="false"
      >
        <div
          class="item"
          @click="xiangqing(item.id, item.examineState)"
          v-for="(item, index) in Data"
          :key="index"
        >
        
          <div
            v-show="xunShow && item.examineState == 5"
            :class="
              item.examineState == 4
                ? 'lvbiaoshi'
                : item.examineState
                ? 'huangbiaoshi'
                : ''
            "
          >
            {{
              item.examineState == 4
                ? "已阅"
                : item.examineState == 5
                ? "已退回"
                : ""
            }}
          </div>
          <div
            v-show="!xunShow"
            :class="
              item.examineState == 4
                ? 'lvbiaoshi'
                : item.examineState == 3
                ? 'huangbiaoshi'
                : ''
            "
          >
            {{
              item.examineState == 4
                ? "已阅"
                : item.examineState == 3
                ? "待阅"
                : ""
            }}
          </div>
          <div class="left">
            <template v-if="xunShow">
              <img :src="user" alt="" class="user" />
            </template>
            <template v-else>
              <img :src="item.imgUrl ? item.imgUrl : zhanwei" alt="" />
            </template>
          </div>
          <div class="right">
            <div class="word">
              <div class="title" v-if="xunShow">
                {{ item.companyName }}
              </div>
              <div class="title" v-if="!xunShow">
                {{ item.scenicSpotName }}
              </div>
              <div class="biaoti" v-if="xunShow">
                {{ item.type == "gaojin" ? "预警设备：" : "任务标题："
                }}{{ item.title || '无'}}
              </div>
              <div class="biaoti" v-if="!xunShow">
                {{ item.type == "gaojin" ? "预警项：" : "任务标题："
                }}{{ item.title || '无'}}
              </div>

              <!-- 这个待处理时出现 -->
              <div class="biaoti" v-if="xunShow">
                任务内容：{{ item.comment }}
              </div>
              <div class="biaoti van-ellipsis" v-if="!xunShow">
                {{ item.type == "gaojin" ? "预警项：" : "任务反馈："
                }}{{ item.feedbackComment || '无'}}
              </div>
              <div class="biaoti" v-if="!xunShow">
                {{ item.type == "gaojin" ? "解除时间：" : "上传时间："
                }}{{ item.executeTime || '无'}}
              </div>
            </div>
          </div>
          <div v-if="xunShow" class="chakan">
            <div class="time">{{ dealTime(item.modifiedTime) }}</div>
            <van-button
              class="button"
              size="small"
              round
              type="info"
              @click.stop="chakan(item.id, item.examineState)"
              >处理</van-button
            >
          </div>
        </div>
      </van-list>
      <no-data v-if="Data.length == 0"></no-data>
    </div>
    <div style="height: 50px">
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import Nav from "../../src/components/nav/nav.vue";
import moment from "moment";
import api from "@/api/task/index";
import noData from "@/components/no-data";
import Search from "@/components/Search";
import dayjs from "dayjs";
export default {
  beforeRouteEnter(to, from, next) {
    if (from.name === "dealWithNow") {
      next((vm) => {
        // vm._data.xunShow = true;
        // vm._data.yishenShow = false;
        vm._data.form.examineState = 1;
        vm._data.form.pageNum = 1;
        vm._data.finished = false;
        vm._data.yishenShow = false;
        vm._data.daiShow = false;
        vm._data.xunShow = true;
        vm._data.xundian = false;
        console.log("vm", vm._data);
      });
    } else {
      next();
    }
  },
  components: {
    Nav,
    noData,
    Search,
  },
  data() {
    return {
      form: {
        keyWord: "",
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
      },
      Data: [],
      Data1: [],
      zhanwei: require("../assets/img/login/zhanwei.png"),
      searchIcon: require("../assets/img/patrol/search.png"),
      yishenShow: true,
      yishenNumber: 0,
      xunNumber: 0,
      daishenNumber: 0,
      yishendian: true,
      daiShow: false,

      daishendian: true,
      xunShow: false,

      xundian: true,
      user: require("../assets/img/task/user.png"),
      gaojin: require("../assets/img/task/gaojin.png"),
      zhiding: require("../assets/img/task/zhiding.png"),
      loading: false,
      finished: false,
    };
  },
  created() {
    this.yiShow = true;
    if (this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.daiShow == true) {
      this.daishendian = false;
    }
  },
  mounted() {
    if (this.$route.query.type || this.$route.query.examineState === 1) {
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.finished = false;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    }
    this.getList();
    this.gettaskListNum();
  },
  methods: {
    onLoad() {
      this.form.pageNum++;
      api.taskList(this.form).then((res) => {
        console.log(res);
        if (res.code == "200") {
          if (res.data.rows.length !== 0) {
            for (var i in res.data.rows) {
              this.Data.push(res.data.rows[i]);
            }
          } else {
            this.finished = true;
            return;
          }
        }
        this.loading = false;
      });
    },
    //获取未读数量
    gettaskListNum() {
      const scenicSpotId = this.$store.state.scenicSpotId;
      api
        .taskListNum({
          scenicSpotId: scenicSpotId,
        })
        .then((res) => {
          if (res.code == "200") {
            this.yishenNumber = res.data.passNum; //已审核未读
            this.daishenNumber = res.data.unAuditNum; //待审核未读
            this.xunNumber = res.data.unImplementNum; //待处理未读
          } else {
            this.$toast(res.message);
          }
        });
    },
    //获取列表数据
    getList() {
      this.Data = [];
      //   this.$toast.loading({
      //     message: '加载中...',
      //     forbidClick: true
      //   });
      this.form.scenicSpotId = this.$store.state.scenicSpotId;
      api.taskList(this.form).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.Data = res.data.rows;
        }
      });
    },
    chakan(id, state) {
      this.$router.push({
        name: "taskxiangqing",
        query: {
          id: id,
          state: state,
        },
      });
      //   if (state == 2 || state == 4) {
      //     this.$router.push({
      //       name: 'dealWithNow',
      //       query: {
      //         id: id,
      //         state: state
      //       }
      //     });
      //   } else {
      //     this.$router.push({
      //       name: 'taskxiangqing',
      //       query: {
      //         id: id,
      //         state: state
      //       }
      //     });
      //   }
    },
    xiangqing(id, state) {
      if (this.form.examineState == 3 || this.form.examineState == 4) {
        //已处理跳转
        this.$router.push({
          name: "renwuyishenhe",
          query: {
            id: id,
            state: state,
          },
        });
      } else {
        this.$router.push({
          name: "taskxiangqing",
          query: {
            id: id,
            state: state,
          },
        });
      }
    },

    clickSearch() {
      this.form.pageNum = 1;
      this.getList();
    },
    onConfirm() {
      this.getList();
    },
    yishenClick() {
      this.form.examineState = 3;
      this.form.pageNum = 1;
      this.finished = false;
      this.form.keyWord = "";
      this.form.startTime = "";
      this.form.endTime = "";
      this.getList();
      this.$router.replace({
        path: "/task",
      });
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    daishenClick() {
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
      this.form.examineState = 2;
      this.getList();
    },
    xunClick() {
      this.finished = false;
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
      this.form.keyWord = "";
      this.form.startTime = "";
      this.form.endTime, this.getList();
      this.$router.replace({
        path: "/task",
        query: {
          type: 1,
        },
      });
    },
    // 获取当前时间，如果是当天时间就取时间，不是当前日期取完整日期
    dealTime(time) {
      let now = moment().format("YYYY-MM-DD");
      let currentTime = moment(time).format("YYYY-MM-DD");
      if (now == currentTime) {
        return moment(time).format("HH:mm:ss");
      } else {
        return moment(time).format("YYYY-MM-DD");
      }
    },
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/less/conment.less");

.patrolBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    height: 40px;
    width: 98%;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }
  }
  .biaoshiStyle {
    position: absolute;
    left: 0;
    top: 0;
    width: 53px;
    height: 23px;
    color: #fff;
    border-radius: 10px 0px 10px 0px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lvbiaoshi {
    .biaoshiStyle();
    background: #50cb79;
    box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
  }

  .huangbiaoshi {
    background: #ffac20;
    .biaoshiStyle();
    box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
  }

  .hongbiaoshi {
    background: #ff6661;
    .biaoshiStyle();
    box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
  }
  .option {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e8e9ec;

    .box {
      width: 73px;
      height: 100%;
      color: #0c90ff;
      font-size: 16px;
      border-bottom: 3px solid #0c90ff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dianstyle {
      width: 16px;
      height: 16px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: -20px;
      top: -3px;
      line-height: 14px;
      font-size: 12px;
      color: #fff;
    }

    .yishenheClick {
      .box();
    }

    .yishenheNoClick {
      width: 73px;
      height: 100%;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFang SC;
      line-height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();
    }

    .xunClick {
      .box();
    }
  }

  .list {
    flex: 1;
    // margin-top: 10px;
    // min-height: 620px;
    overflow-y: auto;
    padding: 0 10px 10px 10px;

    .item {
      background: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 10px 8px;

      .biaoshi {
        position: absolute;
        top: 0;
        left: 0;
      }
      .left {
        margin-top: 26px;
        .user {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          width: 20px;
          height: 18px;
          margin-right: 10px;
        }
        img {
          height: 80px;
          width: 80px;
          border-radius: 10px;
        }
        .van-image{
          height: 80px;
          width: 80px;
          border-radius: 10px;
        }
      }

      .right {
        display: flex;
        flex: 1;
        overflow: hidden;
        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 12px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-left: 12px;
          overflow: hidden;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 4px;
            font-size: 16px;
            // width: 195px;
            // white-space: nowrap; // 不换行
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }
      }

      .chakan {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 4px;
            // width: 195px;
            white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }
        > .time {
          color: #686b73;
          font-size: 16px;
          align-self: center;
        }

        .button {
          width: 80px;
          justify-items: end;
        }
      }
    }
  }
}
.old {
  .title,
  .chakan {
    font-size: 22px !important;
    span {
      font-size: 20px !important;
    }
  }
  .hongbiaoshi,
  .lvbiaoshi,
  .huangbiaoshi,
  .biaoti {
    font-size: 18px !important;
  }
  .yishenheNoClick .word .dian {
    min-width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
  }
  .item {
    display: block !important;
    .top {
      display: flex;
      justify-content: space-between;
      .hongbiaoshi,
      .lvbiaoshi,
      .huangbiaoshi {
        position: relative;
        top: -1.3vh;
        left: -1.9vw;
        width: 70px;
        height: 30px;
      }
      .time {
        font-size: 18px;
      }
    }
    .bottom {
      margin: 10px 0 0 0 ;

      .left {
        display: inline-block;
        width: 29vw;
        margin-top: 0px !important;
        .user {
          min-width: 80px;
          min-height: 75px;
          margin-left: 10px;
        }
        img {
          min-width: 105px;
          min-height: 110px;
          
        }
        .van-image{
          min-width: 105px;
          min-height: 110px;
        }
      }
      .right {
        display: inline-block;
        vertical-align: top;
        width: 56vw;
        .word {
          .title, .biaoti {
            width: 55vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

          }
          .biaoti {
            padding-top: 3px;
          }
        }
        
        .chakan {
          padding-top: 5px;
          display: block;
          float: right;
        }
      }
    }
  }
}
</style>
