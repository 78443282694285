import request from '@/api/index';

export default {
  warnList(data) {
    return request.get('warnList', data)
  },
  warningHandleDetail(data) {
    return request.get('warningHandleDetail', data)
  },
  //已审核详情
  auditWarningHandleDetail(data) {
    return request.get('auditWarningHandleDetail', data)
  },
  //审核提交
  auditWarningHandle(data) {
    return request.post2('auditWarningHandle', data)
  },
  //预警立即处理
  immediateProcessing(data) {
    return request.post('immediateProcessing', data)
  },
  //预警提交
  releaseWarningHandle(data) {
    return request.post2('releaseWarningHandle', data)
  },
  warningHandleListNum(data) {
    return request.get('warningHandleListNum', data)
  }
}