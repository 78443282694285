<template>
  <div class="messageBox">
    <div class="list" @click="renwutongzhi">
      <div class="leftBox">
        <img :src="shixiang" alt="" />
        <div class="word">
          <div class="title">任务通知</div>
          <div class="cont">您收到了任务，请及时处理</div>
        </div>
      </div>
      <div class="righticon">
        <div class="time">{{tasktime}}</div>
        <!-- <div v-if="tasknum" class="hongdian">{{tasknum}}</div> -->
      </div>
    </div>
    <van-divider />
    <div class="list" @click="gonggaoupdate">
      <div class="leftBox">
        <img :src="gonggao" alt="" />
        <!-- <van-icon :name="gonggao" :badge="noticenum" /> -->
        <div class="word">
          <div class="title">公告更新</div>
          <div class="cont">公告刚刚进行了更新，请及时查看</div>
        </div>
      </div>
      <div class="righticon">
        <div class="time">{{noticetime}}</div>
        <div v-if="noticenum" class="hongdian">{{noticenum}}</div>
      </div>

    </div>
  </div>
</template>
<script>
import api from '@/api/my/index';
import request from '@/api/index'
import moment from 'moment';
export default {
  data () {
    return {
      tasktime: moment().format('MM-DD'),
      noticetime: moment().format('MM-DD'),
      queryParams: {
        //1 全部 已读2,未读3
        type: 1,
        page: 1,
        pageSize: 100
      },
      shixiang: require('../../assets/img/my/shixiang.png'),
      gonggao: require('../../assets/img/my/gonggao.png'),
      youshixiang: require('../../assets/img/my/youshixiang.png'),
      yougonggao: require('../../assets/img/my/yougonggao.png'),
      tasknum: 0,
      noticenum: 0,
    };
  },
  mounted () {
    this.getGonggao()
  },
  methods: {
    //获取消息列表
    getGonggao () {
      request
        .post('announcementList', this.queryParams)
        .then((res) => {
          if (res.code == '200' && res.success) {
            this.noticenum = res.data.total
          }
        })
    },
    gonggaoupdate () {
      this.$router.push({
        name: 'messagexiangxi',
      });
    },
    renwutongzhi () {
      this.$router.push({
        name: 'myTaskxiangxi'
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
.messageBox {
  background: #ffffff;
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    &:first-child {
      margin-top: 15px;
    }
    // height: 94px;
    // background: #ffffff;
    // border-radius: 11px;
    // padding: 0px 10px;
    // margin-top: 10px;
    .liangbian();
    .leftBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 44px;
        width: 44px;
        border-radius: 50%;
      }
      .word {
        margin-left: 20px;
        .title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #363a44;
        }
        .cont {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #9da9b8;
        }
      }
    }
    .righticon {
      margin: 0 10px 0 0;
    }
    .hongdian {
      padding: 0 5px;
      border-radius: 30px;
      background: #ff4240;
      text-align: center;
      line-height: 17px;
      font-size: 12px;
      color: #fff;
      position: relative;
      top: 8px;
      left: 2px;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      color: #b3b5b9;
    }
  }
  /deep/ .van-divider {
    margin: 0;
  }
}
</style>
