<template>
  <div class="patrolBox" :class="{ old: isElder }">
    <div class="option">
      <div
        @click="yishenClick()"
        :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'"
      >
        <div class="word">
          <!-- <div class="dian" v-show="yishendian!=0">{{ yishenNumber }}</div> -->
          已巡查
        </div>
      </div>
      <div
        @click="xunClick()"
        class="yishenheNoClick"
        :class="xunShow ? 'xunClick' : ''"
      >
        <div class="word">
          <div class="dian" v-show="xunNumber != 0">{{ xunNumber }}</div>
          待巡查
        </div>
      </div>
    </div>
    <Search
      v-model="form"
      @changeclickSearch="clickSearch"
      @changeonConfirm="onConfirm"
    ></Search>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
        :offset="0"
        :immediate-check="false"
      >
        <div
          class="item"
          :class="{xuncha :item.patrolResult == 2}"
          v-for="(item, index) in Data"
          :key="index"
          @click="xiangqing(item)"
        >
          <div class="left">
            <van-image v-if="!xunShow" :src="item.imageUrl ? item.imageUrl : zhanweiicon">
              <template v-slot:error>加载失败</template>
            </van-image>
            <!-- <img
              v-if="!xunShow"
              :src="item.imageUrl ? item.imageUrl : zhanweiicon"
              alt=""
            /> -->
          </div>

          <div class="right">
            <div class="word">
              <div class="title">
                <span>{{ item.facilityName || '无'}}</span>
                
              </div>
              <div class="biaoti">巡查标题：{{ item.title || '无'}}</div>
              <div class="biaoti">
                巡查反馈：{{
                  form.examineState == 1
                    ? item.feedbackComment || "无"
                    : item.patrolResult == 1
                    ? "正常"
                    : item.patrolResult == 2
                    ? "异常"
                    : "无"
                }}
              </div>
              <div class="biaoti">
                {{ form.examineState == 1 ? "发布时间：" : "上传时间："
                }}{{
                  form.examineState == 1 ? item.createTime : item.patrolTime
                }}
              </div>
              <div class="chuli" v-if="item.patrolResult == 2">
                  <van-button
                    class="button"
                    size="small"
                    round
                    @click.stop="dealWith(item)"
                    type="info"
                    >处理</van-button
                  >
                </div>
            </div>
          </div>
          <div v-if="xunShow" @click.stop="chakan(item)" class="chakan">
            <van-button class="button" size="small" round type="info"
              >巡查</van-button
            >
          </div>
          
        </div>
      </van-list>
      <no-data v-if="Data.length == 0"></no-data>
    </div>
    <div style="height: 50px">
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import api from "@/api/Patrol/index";
import Nav from "../../src/components/nav/nav.vue";
import noData from "@/components/no-data";
import Search from "@/components/Search";
import moment from "moment";
import dayjs from "dayjs";
export default {
  name: "patrol",
  components: {
    Nav,
    noData,
    Search,
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "startInspection") {
      next((vm) => {
        if(vm.$route.query.type == 1){
          vm._data.xunShow = true;
          vm._data.yishenShow = false;
          vm._data.state = "待巡查";
          vm._data.form.examineState = 1;
          vm._data.finished = false;
          vm._data.form.pageNum = 1;
          // this.yishenShow = false;
          vm._data.daiShow = false;
          // this.xunShow = true;
          vm._data.xundian = false;
          // console.log('vm',vm._data)
        }else{
          vm._data.xunShow = false;
          vm._data.yishenShow = true;
        }
      });
    } else {
      next();
    }
  },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        keyWord: "",
        examineState: 3,
        startTime: "",
        endTime: "",
        scenicSpotId: "",
      },
      Data: [],
      searchIcon: require("../assets/img/patrol/search.png"),
      zhanweiicon: require("../assets/img/login/zhanwei.png"),
      yishenShow: true,
      yishendian: true,
      daiShow: false,
      daishenNumber: 2,
      daishendian: true,
      xunShow: false,
      yishenNumber: 0,
      xunNumber: 0,
      xundian: true,
      state: "已巡查",
      loading: false,
      finished: false,
    };
  },
  created() {
    this.form.scenicSpotId = this.$store.state.scenicSpotId
    this.yiShow = true;
    if (this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.daiShow == true) {
      this.daishendian = false;
    }
    this.daiShow = this.$route.query.show;
    this.yiShow = false;
    if (this.$route.query.type) {
      this.state = "待巡查";
      this.form.examineState = 1;
      this.form.startTime = "";
      this.form.endTime = "";
      this.finished = false;
      this.form.pageNum = 1;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    } else {
      console.log('4444444444444444')
      this.form.startTime = "";
      this.form.endTime = "";
      this.xunShow = false;
    }
  },
  mounted() {
    this.getUnread();
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    goBack() {
      console.log("监听浏览器回退事件");
    },
    onLoad() {
      console.log("onLoadonLoad");
      this.form.pageNum++;
      api.xunchaList(this.form).then((res) => {
        if (res.data.rows.length !== 0) {
          for (var i in res.data.rows) {
            this.Data.push(res.data.rows[i]);
          }
        } else {
          this.finished = true;
          return;
        }
        this.loading = false;
      });
    },
    xiangxi(item) {
      this.$router.push({
        name: "patrolForm",
        query: {
          id: item,
        },
      });
    },

    clickSearch() {
      this.form.pageNum = 1;
      this.getList();
      this.getUnread();
    },
    onConfirm() {
      this.form.pageNum = 0;
      this.Data = [];
      this.onLoad();
      this.getUnread();
    },
    yishenClick() {
      this.finished = false;
      this.state = "已巡查";
      this.form = {
        examineState: 3,
        pageNum: 1,
        keyWord: "",
        startTime: "",
        endTime: "",
        scenicSpotId: "",
      };
      // this.form.examineState = 3;
      // this.form.pageNum = 1;
      // this.form.keyWord = "";
      // this.form.startTime = dayjs().format('YYYY-MM-DD');
      // this.form.endTime = dayjs().format('YYYY-MM-DD');
      this.getList();
      this.getUnread();
      this.$router.replace({
        path: "/patrol",
      });
      //history.pushState('',null,'patrol')
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    //查看跳转详情
    chakan(item) {
      this.$router.push({
        name: "patrolForm",
        query: {
          id: item.id,
          examineState: item.examineState,
        },
      });
      //   if (item.examineState == 2 || item.examineState == 4) {
      //     this.$router.push({
      //       name: 'startInspection',
      //       query: {
      //         id: item.id,
      //         examineState: item.examineState,
      //       },
      //     })
      //   } else {
      //     this.$router.push({
      //       name: 'patrolForm',
      //       query: {
      //         id: item.id,
      //         examineState: item.examineState,
      //       },
      //     })
      //   }
    },
    xiangqing(item) {
      if (item.patrolResult == 2) {
        //已审核跳转页面
        this.$router.push({
          name: "xunchashenhe",
          query: {
            id: item.id,
            examineState: item.examineState,
            processing: 1,
            patrolResult: 2
          },
        });
        return;
      }
      if (this.form.examineState == 3) {
        this.$router.push({
          name: "xunchashenhe",
          query: {
            id: item.id,
            examineState: item.examineState,
          },
        });
      } else {
        this.$router.push({
          name: "patrolForm",
          query: {
            id: item.id,
            examineState: item.examineState,
          },
        });
      }
    },
    //异常项处理
    dealWith(i) {
      console.log(i);
      if (i.examineState == 4) {
        this.$router.push({
          name: "startInspection",
          query: {
            id: i.id,
            patrolResult: i.patrolResult,
          },
        });
      } else if (i.examineState == 3 || i.patrolResult == 2) {
        this.$router.push({
          name: "patrolForm",
          query: {
            id: i.id,
            patrolResult: i.patrolResult,
          },
        });
      }

      // api.statrPatrol({ id: i.id }).then((res) => {
      //   if (res.code == '200') {
      //     this.$router.push({
      //       name: 'startInspection',
      //       params: {
      //         id: i.id
      //       }
      //     });
      //   } else {
      //     this.$toast(res.message);
      //   }
      // });
    },
    daishenClick() {
      this.state = "已巡查";
      this.form.examineState = 2;
      this.getList();
      this.getUnread();
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
    },
    xunClick() {
      //history.pushState('',null,'patrol?type=1')
      this.finished = false;
      this.state = "待巡查";
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
      this.form.keyWord = "";
      this.form.startTime = "";
      this.form.endTime = "";
      this.getList();
      this.getUnread();
      this.$router.replace({
        path: "/patrol",
        query: {
          type: 1,
        },
      });
    },

    //请求列表数据
    getList() {
      // this.form.pageNum = 1;
      this.form.pageNum = 0;
      this.Data = [];
      this.onLoad();
    },
    //未读数量
    getUnread() {
      const scenicSpotId = this.$store.state.scenicSpotId;
      api
        .patrolListNum({
          scenicSpotId: scenicSpotId,
        })
        .then((res) => {
          if (res.code == "200") {
            this.yishenNumber = res.data.implementNum;
            this.xunNumber = res.data.unImplementNum;
          } else {
            this.$toast(res.message);
          }
        });
    },
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/less/conment.less");
::v-deep .custom-image .van-empty__image {
  width: 110px;
  height: 82px;
}
.patrolBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    height: 40px;
    width: 98%;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: #fff;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-icon__image {
          width: 12px;
          height: 12px;
        }
      }

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e8e9ec;

    .box {
      width: 73px;
      height: 100%;
      color: #0c90ff;
      font-size: 16px;
      border-bottom: 3px solid #0c90ff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dianstyle {
      min-width: 16px;
      height: 16px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 30%;
      text-align: center;
      position: absolute;
      right: -20px;
      top: -3px;
      line-height: 16px;
      font-size: 16px;
      color: #fff;
    }

    .yishenheClick {
      .box();
    }

    .yishenheNoClick {
      width: 73px;
      height: 100%;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFang SC;
      line-height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();
    }

    .xunClick {
      .box();
    }
  }

  .list {
    // margin-top: 15px;
    // margin-top: 10px;
    // height: 620px;
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 10px 10px;

    .item {
      background: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 5px 8px;

      .biaoshi {
        position: absolute;
        top: 0;
        left: 0;
      }
      .left {
        .user {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          width: 20px;
          height: 18px;
          margin-right: 10px;
        }
        ::v-deep img {
          height: 80px;
          width: 80px;
          border-radius: 10px;
        }
        ::v-deep .van-image__error{
          border-radius: 10px;
        }
        // .van-image{
        //   height: 80px;
        //   width: 80px;
        //   border-radius: 10px;
        // }
      }

      .right {
        display: flex;
        flex: 1;
        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 12px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-left: 12px;
          overflow: hidden;
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #363a44;
            display: flex;
            justify-content: space-between;
          }

          .biaoti {
            margin-top: 4px;
            font-size: 16px;
            // width: 195px;
            // white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }
      }
      .chuli {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 3px 0;
        .button {
          width: 80px;
          font-size: 16px;
        }
      }
      .chakan {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 4px;
            // width: 195px;
            white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }
        > .time {
          color: #686b73;
          font-size: 16px;
          align-self: center;
        }

        .button {
          width: 80px;
          justify-items: end;
          font-size: 16px;
        }
      }
    }
    .xuncha {
      align-items: center;
    }
  }
}
.old {
  .title,
  .chakan {
    span {
      font-size: 22px !important;
    }
    .button {
      font-size: 18px;
    }
    // ::v-deep .van-button__content {
    //   width: 40px;
    // }
  }
  .xuncha {
    align-items: flex-start !important;
  }
  .chuli {
    margin: 10px 0;
  }

  .biaoti {
    font-size: 18px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 91%;
    white-space: nowrap;
  }
  .option .yishenheNoClick .word .dian {
    min-width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
  }
  .list {
      .item {
        ::v-deep .left  img {
          height: 110px !important;
          width: 105px !important;
        }
        .left  .van-image {
          height: 110px !important;
          width: 105px !important;
        }
        .right {
          width: 70%;
        }
      }
  }
}
</style>
