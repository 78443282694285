<template>
  <div class="record" :class="{ old: isElder }">
    <!--swipeable开启tabs滑动 -->
    <van-tabs
      v-model="activerecord"
      animated
      sticky
      title-active-color="#0C90FF"
      color="#0C90FF"
      @change="changevantab"
    >
      <van-tab title="我的反馈" name="我的反馈">
        <div class="bigBox" @touchmove="touchmove" @touchstart="touchstart">
          <div
            class="listBox"
            v-for="(item, index) in suggestionlist"
            :key="index"
          >
            <div class="title">
              <span class="address"
                >{{ item.scenicName }}-{{ item.facilityName }}</span
              >
              <span class="time">{{ item.createTime | datetimejs }}</span>
            </div>
            <div class="describe">
              {{ item.context }}
            </div>
            <div class="imgBox" v-if="item.imageUrls && item.imageUrls.length">
              <div v-for="(items, index) in item.imageUrls" :key="index">
                <img :src="items" />
              </div>
            </div>
            <!-- <div class="whether">
              <van-cell class="dizhi" :title="item.location" :icon="weizhi">
                <template #right-icon>
                  <span class="kejian">{{item.isOpen?'公众可见':'暂不公开'}}</span>
                  <van-switch style="margin:2px 0" disabled v-model="item.isOpen" size="20px" />
                </template>
              </van-cell>
            </div> -->
            <div class="nameless" v-if="item.auditStatus == 2">
              <span class="liang">{{ item.scenicName }}</span
              >&nbsp;&nbsp;<span>{{ item.auditContent }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="评分记录" name="评分记录">
        <div class="rarerecord">
          <div
            class="cardbox"
            v-for="(item, index) in scorelist"
            :key="index"
            @click="routerlinkinfor(item)"
          >
            <div class="cardbox-item">
              <div>
                <img class="leftimg" :src="item.scenicImageUrl" alt="" />
              </div>
              <div class="rightcon">
                <div class="title">{{ item.scenicSpotName }}</div>
                <div class="branchtitle">
                  <span class="branch">{{ item.score }} 分</span>
                  <span class="pingfen">综合评分</span>
                </div>
                <div class="pingfentime">
                  <span> 评分日期 </span>
                  <span style="margin: 0 0 0 5px; flex: 1">
                    {{ item.scoreTime | dayjs }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div style="height: 50px">
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import Nav from "../../components/nav/zlbNav.vue";
import request from "@/api/index";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      activerecord: "我的反馈",
      weizhi: require("../../assets/img/zlb/dingwei.png"),
      shifou: require("../../assets/img/zlb/shifou.png"),
      checked: false,
      suggestionlist: [],
      scorelist: [],
    };
  },
  computed: {
    userId() {
      const userId = this.$store.state.id;
      // console.log('userId', userId);
      return userId;
    },

    // activerecord () {
    //   const name = this.$route.query.name || '评分记录'
    //   return name
    // }
  },
  methods: {
    getsuggestionlist() {
      const userId = this.userId;
      request
        .post2("suggestionList", {
          userId: userId,
        })
        .then((res) => {
          // this.$toast(res.message);
          this.suggestionlist = res.data.reverse().map((item) => {
            if (!item.imageUrls) {
              item.imageUrls = [];
            } else {
              item.imageUrls = item.imageUrls.split(",");
            }
            return item;
          });
        });
    },
    getscoreviewList() {
      const userId = this.userId || this.$store.getters.getUserId;
      // console.log('getscoreviewList', userId)
      request
        .post2("suggestionScoreViewList", {
          userId: userId,
        })
        .then((res) => {
          // this.$toast(res.message);
          this.scorelist = res.data;
        });
    },

    routerlinkinfor(item) {
      this.$router.push({
        path: "/zhelibanPage/record/recordinfor",
        query: {
          // id: item.id,
          ...this.$route.query,
          scoreinfor: item,
        },
      });
    },
    changevantab(name) {
      if (name == "我的反馈") {
        console.log(name);
        this.getsuggestionlist();
        this.$router.replace({
          path: "/zhelibanPage/record",
          query: this.$route.query,
        });
      } else if (name == "评分记录") {
        console.log(name);
        this.getscoreviewList();
        this.$router.replace({
          path: "/zhelibanPage/record",
          query: {
            type: 1,
            ...this.$route.query,
          },
        });
      }
    },
    touchstart(e){
      this.startX = e.touches[0].pageX;
      this.startY = e.touches[0].pageY;
      // console.log('touchstart', e, this.startX);

    },
    touchmove(e){
      // console.log('touchmove', e);
      // let moveX = e.touches[0].pageX;
      // let moveY = e.touches[0].pageY;
      // console.log('touchmove', Math.abs(moveX-this.startX));
      // if(Math.abs(moveX-this.startX)>Math.abs(moveY-this.startY)){
      //   console.log('seuucesss');
      //   let stopScroll =  (e) => {
      //     e.preventDefault();
      //   }
      //   window.addEventListener("touchmove", stopScroll,{ passive: false });//禁止页面滑动
      // }
    },
    // touchend(event){
    //   console.log('touchend', event);
    // },
  },

  mounted() {
    if (this.$route.query.type) {
      this.activerecord = "评分记录";
      console.log("评分记录get");
      this.getscoreviewList();
    } else {
      console.log("评分记录11get");
      this.getsuggestionlist();
    }
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
.rarerecord {
  width: 100%;
  .cardbox {
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    padding: 20px 20px;
    margin: 20px 10px;
  }
  .cardbox-item {
    display: flex;
  }
  .leftimg {
    width: 105px;
    height: 85px;
    border-radius: 10px;
  }
  .rightcon {
    font-size: 16px;
    margin: 0 0 0 20px;
    .title {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }
    .branchtitle {
      margin: 10px 0 0 0;
      .branch {
        font-size: 16px;
        font-weight: bold;
        color: #363a44;
        border-bottom: 5px solid #ffb500;
      }
      .pingfen {
        font-size: 12px;
        font-weight: 400;
        color: #b3b5b9;
        margin: 0 0 0 10px;
      }
    }

    .pingfentime {
      width: 200px;
      font-size: 12px;
      font-weight: 400;
      color: #b3b5b9;
      margin: 10px 0 0 0;
      display: flex;
      align-items: center;
      span {
        white-space: nowrap;
        text-overflow: ellipsis !important;
        overflow: hidden;
      }
      // flex-wrap: wrap;
    }
  }
}
.bigBox {
  padding: 12px 12px;
  .listBox {
    &:first-child {
      margin-top: 0px;
    }
    margin-top: 12px;
    // height: 345px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      // height: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items:  flex-start;
      .address {
        flex: 1;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .time {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #868686;
      }
    }
    .describe {
      margin: 10px 0;
      max-height: 70px;
      overflow: auto;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #686b73;
    }
    .imgBox {
      width: 100%;
      margin: 10px 0;
      display: flex;
      overflow: auto;
      flex-wrap: wrap;
      img {
        width: 70px;
        height: 70px;
        margin: 0 2px;
        background: #ffffff;
        border: 1px dashed #bfbfbf;
      }
    }
    .whether {
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        line-height: 27px;
        margin: 0 5px;
      }
      .van-cell {
        padding: 0px 0px;
        .van-cell__left-icon {
          margin-top: 6px;
        }
      }
      .dizhi {
        font-size: 16px;
        color: #363a44;
      }
      .kejian {
        font-size: 16px;
        color: #b3b5b9;
        margin-right: 5px;
      }
    }
    .nameless {
      // height: 60px;
      margin: 10px 0;
      background: #f6f6f6;
      border-radius: 6px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #686b73;
      padding: 20px 10px 20px 10px;
      .liang {
        color: #1c91ff;
      }
    }
  }
}
.old {
  .title,
  .address {
    font-size: 24px !important;
    font-weight: bold !important;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .bigBox {
    .listBox {
      // .title {
      //   align-items: start !important;
      // }
      .imgBox {
      width: 100%;
      margin: 10px 0;
      display: flex;
      overflow: auto;
      flex-wrap: wrap;
      img {
        width: 140px;
        height: 140px;
        margin: 0 3px;
      }
    }
    }
  }


  .time,
  .nameless,
  .pingfentime,
  .branchtitle {
    font-size: 18px !important;
    white-space: normal;
    text-overflow: ellipsis !important;
    overflow: hidden;
    line-height: 25px;
    .pingfen {
      font-size: 18px !important;
    }
    .branch {
      font-size: 20px !important;
    }
  }

  .describe {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  /deep/ .van-tab {
    font-size: 20px;
  }
  /deep/ .bigBox .selectBox span {
    font-size: 20px;
  }
  /deep/ .van-cell__value {
    font-size: 18px;
  }
  /deep/ .bigBox .youkewen {
    font-size: 18px;
  }
  /deep/ .van-radio__label {
    font-size: 18px;
  }
  /deep/ .van-tabbar-item__text {
    font-size: 18px;
  }

  .rarerecord {
    .cardbox {
      .cardbox-item {
        .leftimg {
          width: 115px;
          height: 105px;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
