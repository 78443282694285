const OSS = require('ali-oss')

export default function Client() {
  return new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAI5tNnSKQc9pb1dgcGBx8B',
    accessKeySecret: '7lhMacrNSHb2YZjho1F3PWZGorfzoD',
    bucket: 'tongluwenlv',
  })
}
