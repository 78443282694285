var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myTask"},[_c('Search',{on:{"changeclickSearch":_vm.clickSearch,"changeonConfirm":_vm.onConfirm},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('div',{staticClass:"list"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了","offset":0,"immediate-check":false},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.Data),function(item,index){return _c('div',{key:index,staticClass:"box",on:{"click":function($event){return _vm.xiangqing(item.id, item.examineState)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.xunShow && item.examineState == 5),expression:"xunShow && item.examineState == 5"}],class:item.examineState == 4
            ? 'lvbiaoshi'
            : item.examineState
              ? 'huangbiaoshi'
              : ''},[_vm._v(" "+_vm._s(item.examineState == 4 ? '已阅' : item.examineState == 5 ? '已退回' : '')+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.xunShow),expression:"!xunShow"}],class:item.examineState == 4
            ? 'lvbiaoshi'
            : item.examineState == 3
              ? 'huangbiaoshi'
              : ''},[_vm._v(" "+_vm._s(item.examineState == 4 ? '已阅' : item.examineState == 3 ? '待阅' : '')+" ")]),_c('div',{staticClass:"contentBox"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.xunShow),expression:"!xunShow"}],attrs:{"src":item.imgUrl == '' ? '' : item.imgUrl,"alt":""}}),_c('div',{staticStyle:{"display":"flex","width":"80%"}},[(_vm.xunShow)?_c('div',{staticClass:"imgBox"},[_c('img',{staticClass:"user",attrs:{"src":_vm.user,"alt":""}})]):_vm._e(),_c('div',{staticClass:"word",staticStyle:{"margin-left":"12px"}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.companyName)+" ")]),(_vm.xunShow)?_c('div',{staticClass:"biaoti"},[_vm._v(" "+_vm._s(item.type == 'gaojin' ? '预警设备：' : '任务标题：')+_vm._s(item.title)+" ")]):_vm._e(),(_vm.xunShow == true ? false : true)?_c('div',{staticClass:"biaoti"},[_vm._v(" "+_vm._s(item.type == 'gaojin' ? '预警项：' : '任务标题：')+_vm._s(item.title)+" ")]):_vm._e(),(_vm.xunShow)?_c('div',{staticClass:"biaoti"},[_vm._v(" 任务内容："+_vm._s(item.comment)+" ")]):_vm._e(),(_vm.xunShow == true ? false : true)?_c('div',{staticClass:"biaoti van-ellipsis"},[_vm._v(" "+_vm._s(item.type == 'gaojin' ? '预警项：' : '任务反馈：')+_vm._s(item.feedbackComment)+" ")]):_vm._e(),(_vm.xunShow == true ? false : true)?_c('div',{staticClass:"biaoti"},[_vm._v(" "+_vm._s(item.type == 'gaojin' ? '解除时间：' : '上传时间：')+_vm._s(item.executeTime)+" ")]):_vm._e()])]),(_vm.xunShow)?_c('div',{staticClass:"chakan"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.modifiedTime.substr(11, 16)))]),_c('van-button',{attrs:{"type":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.chakan(item.id, item.examineState)}}},[_vm._v("处理")])],1):_vm._e()])])}),0)],1),(_vm.Data.length == 0)?_c('no-data'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }