<template>
  <div class="myBox">
    <div class="userInformation">
      <div class="xiangqing">
        <div class="head">
          <img :src="head" alt="" />
        </div>
        <div class="xinxi">
          <div class="name">{{ name }}</div>
          <div class="jobTitle">{{ companyName }}</div>
        </div>
      </div>
    </div>
    <div class="list">
      <div
        class="box"
        @click="go(item.name)"
        v-for="(item, index) in list"
        :key="index"
      >
        <img class="move_img" :src="item.icon" alt="" />
        <div class="name">{{ item.name }}</div>
        <div class="hongdian" v-if="item.show && noReade">{{ noReade }}</div>
        <img class="move_jiantou" :src="jiantou" alt="" />
      </div>
    </div>
    <Nav></Nav>
  </div>
</template>
<script>
import Nav from "../../src/components/nav/nav.vue";
import request from "@/api/index";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      head: require("../assets/img/my/head.png"),
      list: [
        {
          name: "我的消息",
          icon: require("../assets/img/my/information.png"),
          show: true,
        },
        {
          name: "巡查记录",
          icon: require("../assets/img/my/partol.png"),
          show: false,
        },
        {
          name: "任务记录",
          icon: require("../assets/img/my/task.png"),
          show: false,
        },
        {
          name: "预警记录",
          icon: require("../assets/img/my/waring.png"),
          show: false,
        },
        {
          name: "景区信息",
          icon: require("../assets/img/my/jingqu.png"),
          show: false,
        },
      ],
      jiantou: require("../assets/img/my/jiantou.png"),
      set: require("../assets/img/my/set.png"),
      queryParams: {
        //1 全部 已读2,未读3
        type: 1,
        page: 1,
        pageSize: 100,
      },
      position: "",
      tasknum: 0,
      noticenum: 0,
    };
  },
  computed: {
    noReade() {
      return this.noticenum;
    },
  },
  mounted() {
    this.getGonggao();
  },
  methods: {
    getGonggao() {
      request.post2("announcementList", this.queryParams).then((res) => {
        if (res.code == "200" && res.success) {
          this.noticenum = res.data.total;
        }
      });
    },
    go(item) {
      if (item == "巡查记录") {
        this.$router.push({
          name: "myPatol",
          query: {
            name: "巡查记录",
          },
        });
      } else if (item == "任务记录") {
        this.$router.push({
          name: "myTask",
          query: {
            name: "任务记录",
          },
        });
      } else if (item == "我的消息") {
        this.$router.push({
          name: "message",
          query: {
            name: "我的消息",
          },
        });
      } else if (item == "预警记录") {
        this.$router.push({
          name: "myWaring",
          query: {
            name: "预警记录",
          },
        });
      } else if (item == "景区信息") {
        this.$router.push({
          name: "jingqu",
          query: {
            name: "浙里办首页",
          },
        });
      } else if (item == "查看浙里办首页") {
        this.$router.push({
          name: "zlbHome",
          query: {
            name: "浙里办游客",
          },
        });
      } else if (item == "查看浙里办游客参与") {
        this.$router.push({
          name: "zlbYou",
          query: {
            name: "景区信息",
          },
        });
      } else if (item == "查看浙里办上传记录") {
        this.$router.push({
          name: "zlbUploade",
          query: {
            name: "浙里办上传",
          },
        });
      }
    },
  },
  computed:{
    name(){
      return this.$store.state.userName;
    },
    companyName(){
      return this.$store.state.companyName
    }
  }
};
</script>
<style lang="less" scoped>
.myBox {
  .userInformation {
    height: 125px;
    background: linear-gradient(0deg, #f6f7f8 -20%, #2093ff 100%);
    display: flex;
    align-items: center;

    .xiangqing {
      height: 64px;
      display: flex;
      margin-left: 21px;

      .head {
        width: 64px;
        height: 64px;
        background: #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 34.5px;
          height: 31px;
        }
      }

      .xinxi {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 11px;
        font-family: PingFang SC;
        font-weight: 500;

        .name {
          font-size: 20px;

          color: #ffffff;
        }

        .jobTitle {
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }

  .list {
    // height: 360px;

    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .box {
      font-size: 18px;
      height: 64px;
      border-bottom: 1px solid #e8e9ec;
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 68.5px;

      > .name {
        // width: 240px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
      }

      .hongdian {
        padding: 0px 5px;
        border-radius: 30px;
        background: #ff4240;
        text-align: center;
        line-height: 17px;
        font-size: 12px;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 37px;
      }
      .move_jiantou {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
      }
      .move_img {
        position: absolute;
        top: 35%;
        left: 34px;
      }

      img {
        &:nth-child(1) {
          width: 27.5px;
          height: 26.5px;
          vertical-align: middle;
        }

        &:last-child {
          height: 14px;
          width: 8px;
        }
      }

      .xian {
        height: 1px;
        width: 304px;
      }
    }
  }

  .set {
    width: 355px;
    height: 67px;
    background: #fff;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;

    > .box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      div {
        width: 240px;
        justify-self: flex-start;
      }

      img {
        &:nth-child(1) {
          width: 26px;
          height: 26px;
          margin-top: 5px;
        }

        &:last-child {
          height: 14px;
          width: 8px;
        }
      }
    }
  }
}

.box:last-child {
  border: none !important;
}
</style>
