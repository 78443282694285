import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import felxtable from 'amfe-flexible'
import '@/utils/rem.js'

import {
  Button,
  Field,
  Icon,
  Checkbox,
  CheckboxGroup,
  Grid,
  GridItem,
  NoticeBar,
  Badge,
  Tabbar,
  TabbarItem,
  Form,
  RadioGroup,
  Radio,
  Step,
  Steps,
  Popup,
  Cell,
  CellGroup,
  Calendar,
  Search,
  Toast,
  Progress,
  Picker,
  Uploader,
  Switch,
  PullRefresh,
  Collapse,
  CollapseItem,
  Loading,
  Tab,
  Tabs,
  List,
  Empty,
  Divider,
  ImagePreview,
  Swipe,
  SwipeItem,
  Popover,
  Overlay,
} from 'vant'
import { Image as VanImage } from 'vant'

import imgUpload from '@/components/ossUpload/imgUpload'

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(NoticeBar)
Vue.use(VanImage)
Vue.use(Badge)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Form)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(CheckboxGroup)
Vue.use(Calendar)
Vue.use(Search)
Vue.use(Progress)
Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Switch)
Vue.use(PullRefresh)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Loading)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Empty)
Vue.use(Divider)
Vue.use(Divider)
Vue.use(ImagePreview)
Vue.use(Popover);
Vue.use(felxtable)
Vue.use(Overlay)
// filter过滤器
import './utils/filter'
// import VConsole from 'vconsole';
// let vconsole = new VConsole();
Vue.component('imgUpload', imgUpload)
Toast.setDefaultOptions({ duration: 1000 })

Vue.prototype.$ajax = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // Vant,
  render: (h) => h(App),
}).$mount('#app')
