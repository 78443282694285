<template>
  <div class="search">
    <van-field
      v-model="form.keyWord"
      placeholder="输入搜索内容"
      type="text"
      input-align="left"
      :left-icon="searchIcon"
    >
      <!-- <template slot="left-icon">
        <img style="width: 15px; height: 15px" :src="searchIcon" alt="" class="searchInput" />
      </template> -->
      <template slot="right-icon">
        <div @click="clickSearch">搜索</div>
      </template>
    </van-field>
    <van-button
      style="margin: 0 0 0 10px"
      size="normal"
      icon-position="right"
      :icon="riqi"
      type="default"
      @click="rili = true"
    >
      日期
    </van-button>
    <van-calendar
      v-model="rili"
      type="range"
      :allow-same-day="true"
      color="#1C8FFB"
      @confirm="onConfirm"
      @close="onclose"
      :min-date="minDate"
      :max-date="maxDate"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  props: {
    form: {
      type: Object,
      default: [],
    },
  },
  model: {
    prop: "form",
    event: "change",
  },
  components: {},
  data() {
    return {
      rili: false,
      searchIcon: require("@/assets/img/patrol/search.png"),
      riqi: require("../../assets/img/my/xiajiantou.png"),
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      Data1: [],
    };
  },
  computed: {},
  watch: {
    // form: {
    //   handler (newVal) {
    //     console.log('newVal: ', newVal);
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {},
  mounted() {},
  methods: {
    removeTime() {
      this.form.startTime = "";
      this.form.endTime = "";
    },
    onclose() {},
    clickSearch() {
      this.removeTime();
      this.$emit("changeclickSearch");
    },

    onConfirm(date) {
      this.rili = false;
      if (date.length == 2) {
        this.form.startTime = moment(date[0]).format("YYYY-MM-DD");
        this.form.endTime = moment(date[1]).format("YYYY-MM-DD");
      }
      this.$emit("changeonConfirm");
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../assets/less/conment.less");
.search {
  margin-top: 10px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 14px 0 0;

  .liangbian();

  .van-field {
    border-radius: 18px;
    align-items: center;
  }

  ::v-deep.van-icon__image {
    width: 15px;
    height: 15px;
  }

  .van-button {
    background: #fff;
    border: none;
    color: #1c8ffb;
    min-width: 67px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    height: 40px;
    border-radius: 18px;
    padding: 0px 10px;

    .van-button__content {
      .van-icon__image {
        width: 12px;
        height: 12px;
      }
    }

    .van-button__content {
      .van-button--default {
        color: #1c8ffb !important;
      }
    }
  }
}
</style>
