import request from '@/api/index';

export default {
  //首页任务列表
  taskList(data) {
    return request.get('taskList', data)
  },
  //制定任务
  addTask(data) {
    return request.post2('addTask', data)
  },
  //单位列表
  companyList(data) {
    return request.get('companyList', data)
  },
  //任务详情
  taskDetail(data) {
    return request.get('taskDetail', data)
  },
  //审核过的详情
  auditTaskDetail(data) {
    return request.get('auditTaskDetail', data)
  },
  //提交审核结果
  auditTask(data) {
    return request.post2('auditTask', data)
  },
  //未读
  taskListNum(data) {
    return request.get('taskListNum', data)
  },
  //提交任务
  releaseTask(data) {
    return request.post2('releaseTask', data)
  },
  //立即处理改变状态
  taskimmediateProcessing(data) {
    return request.post('taskimmediateProcessing', data)
  }
}