<template>
  <div class="xiangqingBox" :class="{ old: isElder }">
    <div>
      <div class="jiben">
        <div class="infomation">
          <div class="kuai"></div>
          <span>基本信息</span>
        </div>
        <div class="xiangxiList">
          <van-field
            label-class="title"
            v-for="(item, index) in list"
            :key="index"
            readonly
            :label="item.title"
            input-align="left"
          >
            <template slot="input">
              {{ item.con || '无'}}
            </template>
          </van-field>
        </div>
      </div>
      <div class="jiben">
        <div class="infomation">
          <div class="kuai"></div>
          <span>巡查人员</span>
        </div>
        <div class="xiangxiList">
          <van-field
            label-class="title"
            readonly
            label="人员姓名"
            :value="patrolPerson || '无'"
          ></van-field>
          <van-field
            label-class="title"
            readonly
            label="联系方式"
            :value="mobile || '无'"
          ></van-field>
        </div>
      </div>
      <div class="buttonBox" v-if="examineState == 2 || examineState == 1">
        <van-button round size="large" @click="startInspection" type="info"
          >开始巡查</van-button
        >
      </div>
      <div class="buttonBox" v-if="(allData.patrolResult == 2) && (examineState == 3)">
        <van-button round size="large" @click="startInspection" type="info"
          >开始处理</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/Patrol/index";
export default {
  data() {
    return {
      form: {
        reason: "", //留言
        examineType: 2, //审核类型：1预警、2日常巡查、3任务定制
        patrolId: "", //巡查id
      },
      examineState: this.$route.query.examineState,
      form1: {
        patrolId: 0,
      },
      list: [
        {
          title: "巡查编号",
          con: "",
        },
        {
          title: "巡查标题",
          con: "",
        },
        {
          title: "巡查设施",
          con: "",
        },
        {
          title: "巡查单位",
          con: "",
        },
      ],
      shixiang: [],
      zhixing: [],
      fankui: [],
      shenhejindu: [],
      activeIcon: require("../../assets/img/patrol/zhengchang.png"),
      inactiveIcon: require("../../assets/img/patrol/yichang.png"),
      xuanzhongIcon: require("../../assets/img/patrol/xuanzhong.png"),
      pass: require("../../assets/img/patrol/pass.png"),
      nopass: require("../../assets/img/patrol/nopass.png"),
      stepIcon: require("../../assets/img/patrol/stepIcon.png"),
      liuyanShow: true,
      cretime: "",
      allData: "",
      shenhe: "",
      formId: {
        id: null,
      },
    };
  },
  created() {
    this.form1.patrolId = this.$route.query.id;
    this.form.patrolId = this.$route.query.id;
  },
  mounted() {
    this.getlist();
  },
  computed: {
    patrolPerson() {
      return this.$store.state.userName;
    },
    mobile() {
      return this.$store.state.mobile;
    },
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  methods: {
    //开始巡查
    startInspection() {
      if (this.examineState === 1) {
        api.statrPatrol({ id: this.form.patrolId }).then((res) => {
          if (res.code == "200") {
            this.$router.push({
              name: "startInspection",
              query: {
                id: this.allData.id,
              },
            });
          } else {
            this.$toast(res.message);
          }
        });
      } else if(this.$route.query.patrolResult == 2 && this.examineState === 3) {
        api.startDeal({ id: this.form.patrolId }).then((res) => {
          if (res.code == "200") {
            this.$router.push({
              name: "startInspection",
              query: {
                id: this.allData.id,
                patrolResult: 2
              },
            });
          } else {
            this.$toast(res.message);
          }
        });
      } else {
        this.$router.push({
          name: "startInspection",
          query: {
            id: this.allData.id,
          },
        });
      }
    },

    //获取巡查详情
    getlist() {
      api.xunchaxiangqing(this.form1).then((res) => {
        console.log(res);
        this.allData = res.data;
        this.list[0].con = res.data.patrolCode;
        this.list[1].con = res.data.title;
        this.list[2].con = res.data.facilityName;
        this.list[3].con = res.data.companyName;
        // this.list[4].con = res.data.depts;
        this.cretime = res.data.createTime;
        this.fankui = res.data.patrolTermVOList;
        this.examineState = res.data.examineState;
        this.shenhe = res.data.examineCompanyFlowEntityList;
      });
    },
    // liuyan () {
    //   this.liuyanShow = true;
    // },
    // noliuyan () {
    //   this.liuyanShow = false;
    // },
    // onSubmit (values) {
    //   console.log('1111', values);
    // },
    // tijiao () {
    //   api.examineCompanyFlowSave(this.form).then((res) => {
    //     if (res.code == '-1') {
    //       this.$toast(res.message);
    //     } else {
    //       this.$toast('提交成功');
    //       this.$router.push({
    //         name: 'Patrol',
    //         query: {
    //           show: false
    //         }
    //       });
    //     }
    //   });
    // }
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");

.xiangqingBox {
  .jiben {
    .xiangxiList {
      margin-top: 10px;

      .van-field {
        height: 54.5px;
        display: flex;
        align-items: center;
      }

      ::v-deep.title {
        font-family: PingFang SC;
        font-weight: 400;
        color: #b3b5b9;
        font-size: 14px;
      }
    }
  }

  .buttonBox {
    background: #fff;
    height: 86px;
    position: fixed;
    bottom: 0;
    border: 1px solid #fff;
    width: 100%;
    box-sizing: border-box;
  }
}
.old {
  .biaoti {
    font-size: 18px !important;
  }
  .infomation {
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  ::v-deep.title {
    font-weight: bold !important;
    color: #b3b5b9;
  }
  ::v-deep .van-cell__title {
    font-size: 20px !important;
    font-weight: bold;
  }
  ::v-deep.van-field__control {
    font-size: 20px !important;
    font-weight: bold;
  }
  .jiben {
    .infomation span{
      font-size: 22px !important;
    }
  }
}
</style>
