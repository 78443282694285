<template>
  <div class="formBox" :class="{ old: isElder }">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>{{$route.query.patrolResult == 2 ? '异常项目':'巡查项目'}}</span>
      </div>
    </div>
    <div class="projectBox">
      <div class="smallBox">
        <div
          class="fankui"
          v-for="(item, index) in requestData.patrolHandleTermDTOS"
          :key="index"
        >
          <span class="fankuixiangqing"
            >{{ `${index + 1}.` }}&nbsp;{{ item.termContent }}
          </span>
          <van-radio-group
            v-model="item.termState"
            direction="horizontal"
            @change="radioChange(item.termState, index)"
          >
            <van-radio :name="1">
              正常
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-radio>
            <van-radio :name="2">
              异常
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-radio>
          </van-radio-group>
          <!-- 选异常显示 -->
          <div class="abnormal" v-show="item.termState == 2">
            <span>异常原因</span>
            <van-field
              v-model="item.feedbackContent"
              rows="5"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="请输入内容"
              show-word-limit
            />
          </div>
          <div class="fankuiImg">
            <span class="word">图片上传</span>
            <span class="illustrate"> 请上传至少2张图片 </span>
            <img-upload
              v-model="requestData.patrolHandleTermDTOS[index].picList"
              capture="camera"
              @change="changeimglist"
            >
            </img-upload>
            <!-- <van-uploader :after-read="(file) => afterRead1(file, index)" v-model="requestData.patrolHandleTermDTOS[index].picList2" :max-count="2">
              <div class="Box">
                <div class="round">
                  <img :src="jia" alt="" />
                </div>
              </div>
            </van-uploader> -->
          </div>
          <!-- 选异常显示 -->
          <div class="measure" v-show="item.termState == 2">
            <span>处理措施</span>
            <van-field
              v-model="item.handleIdea"
              rows="5"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="请输入内容"
              show-word-limit
            />
          </div>
        </div>
      </div>
    </div>
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>巡查反馈</span>
      </div>
      <div class="projectBox">
        <div class="result">
          <span>巡查结果</span>
          <van-radio-group
            v-model="requestData.patrolResult"
            disabled
            direction="horizontal"
          >
            <van-radio :name="1">
              正常
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? an : inactiveIcon"
                />
              </template>
            </van-radio>
            <van-radio :name="2">
              异常
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? an : inactiveIcon"
                />
              </template>
            </van-radio>
          </van-radio-group>
        </div>

        <div v-show="requestData.patrolResult == 1" class="Content">
          <span>反馈内容</span>
          <van-field
            v-model="requestData.feedbackComment"
            rows="5"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入内容"
            show-word-limit
          />
        </div>
        <div v-show="requestData.patrolResult == 2" class="Content">
          <span>处理措施</span>
          <van-field
            v-model="requestData.handleIdea"
            rows="5"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入内容"
            show-word-limit
          />
        </div>
        <van-cell
          v-show="requestData.patrolResult == 2"
          class="beTime"
          @click="rili = true"
          title="处理截至时间"
          :value="requestData.handleTime"
        >
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <img :src="riliIcon" alt="" />
          </template>
        </van-cell>
        <van-calendar
          v-model="rili"
          color="#1C8FFB"
          @confirm="onConfirm"
          :max-date="maxDate"
        />

        <!-- <div v-show="requestData.patrolResult == 1" class="fankuiImg">
          <span class="word">图片上传</span>
          <span class="illustrate"> 请上传至少2张图片或视频 </span>
          <van-uploader
            :after-read="afterRead"
            v-model="matter"
            capture="camera"
            :max-count="2"
            accept="image/*"
          >
            <div class="Box">
              <div class="round">
                <img :src="jia" alt="" />
              </div>
            </div>
          </van-uploader>
        </div> -->
      </div>
    </div>
    <div class="bottomkeep" v-if="isshowpreservation">
      <van-button round size="large" @click="preservation" type="info"
        >保存</van-button
      >
    </div>
    <div class="bottomBox" v-else>
      <van-button round size="large" @click="dealWithNow" type="info"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import api from "@/api/Patrol/index";
import Client from "@/utils/ossClient";
import OSS from "ali-oss";
import imgUpload from "../../components/ossUpload/imgUpload.vue";
export default {
  components: { imgUpload },
  data() {
    return {
      isshowpreservation: true,
      activeIcon: require("../../assets/img/patrol/xuanzhong.png"),
      inactiveIcon: require("../../assets/img/patrol/yichang.png"),
      jia: require("../../assets/img/waring/jia.png"),
      an: require("../../assets/img/patrol/zhengchang.png"),
      riliIcon: require("../../assets/img/task/time.png"),
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      form: {
        patrolId: 0,
      },
      form1: {
        warningHandleImageList: [],
      },
      reslut: [1, 2],
      matter: [],
      rili: false,
      requestData: {
        id: null,
        feedbackComment: "",
        handleIdea: "",
        imageList: [],
        patrolResult: null,
        handleTime: "",
        patrolHandleTermDTOS: [
          {
            feedbackContent: "",
            handleIdea: "",
            id: 0,
            picList: [],
            termState: 0,
          },
        ],
      },
    };
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    }
  },
  created() {
    this.form.patrolId = this.$route.query.id;
    // console.log(this.form.id);
  },
  mounted() {
    this.getList();
  },
  methods: {
    //上传图片到服务器
    afterRead1(file, index) {
      console.log(index);
      let client = new OSS({
        region: "oss-cn-hangzhou",
        accessKeyId: "LTAI5tNnSKQc9pb1dgcGBx8B",
        accessKeySecret: "7lhMacrNSHb2YZjho1F3PWZGorfzoD",
        bucket: "tongluwenlv",
      });
      const fileN = file.content;
      const fileU = file.file.name;
      const base64 = fileN.split(",").pop();
      const fileType = fileN.split(";").shift().split(":").pop();
      const blob = this.toBlob(base64, fileType);
      this.readFile(blob).then((res) => {
        const fileExtension = fileU.substring(fileU.lastIndexOf("."));
        const objectKey = "tl/" + this.uuid() + fileExtension;
        const buffer = new OSS.Buffer(res.target.result);
        client
          .put(objectKey, buffer)
          .then((result) => {
            this.requestData.patrolHandleTermDTOS[index].picList.push(
              result.url
            );
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    afterRead(file) {
      let client = new OSS({
        region: "oss-cn-hangzhou",
        accessKeyId: "LTAI5tNnSKQc9pb1dgcGBx8B",
        accessKeySecret: "7lhMacrNSHb2YZjho1F3PWZGorfzoD",
        bucket: "tongluwenlv",
      });

      const fileN = file.content;
      const fileU = file.file.name;
      const base64 = fileN.split(",").pop();
      const fileType = fileN.split(";").shift().split(":").pop();
      const blob = this.toBlob(base64, fileType);
      this.readFile(blob).then((res) => {
        const fileExtension = fileU.substring(fileU.lastIndexOf("."));
        const objectKey = "gt/" + this.uuid() + fileExtension;
        const buffer = new OSS.Buffer(res.target.result);
        client
          .put(objectKey, buffer)
          .then((result) => {
            this.requestData.imageList.push(result.url);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          resolve(event);
        };
      });
    },
    toBlob(urlData, fileType) {
      let bytes = window.atob(urlData);
      let n = bytes.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }
      return new Blob([u8arr], { type: fileType });
    },

    uuid() {
      //生成随机数
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },
    //获取巡查详情
    getList() {
      api.xunchaxiangqing(this.form).then((res) => {
        if (res.code == "200") {
          console.log(res.data);
          // if(this.$route.query.patrolResult != 2){
            this.requestData.id = res.data.id;
            this.requestData.patrolHandleTermDTOS = res.data.patrolTermVOList.map(
              (i) => {
                i.picList2 = [];
                return i;
              }
            );
            if(this.$route.query.patrolResult == 2){
              this.requestData.patrolHandleTermDTOS.forEach(item=>{
                item.termState = 1
                item.picList = []
                item.feedbackContent = ''
              })
            }
          // }
          //   this.requestData.patrolHandleTermDTOS;
          console.log(this.requestData);
        }
      });
    },
    changeimglist(data) {
      //   console.log(data, "111111");
      //   console.log(this.requestData.patrolHandleTermDTOS,"22222",);
      let hasImage = this.requestData.patrolHandleTermDTOS.every(
        (i) => i.picList.length >= 2
      );
      if (hasImage) {
        this.isshowpreservation = false;
      } else {
        this.isshowpreservation = true;
      }
      console.log(hasImage, "hasImage");
    },
    //保存巡查
    preservation() {
      api.preservationPatrol(this.requestData).then((res) => {
        if (res.code == "200" && res.success) {
          this.$toast("保存成功");
          this.getList();
        } else {
          this.$toast(res.message);
        }
      });
    },
    //提交巡查
    dealWithNow() {
      if (
        this.requestData.patrolResult == 2 &&
        this.requestData.handleTime == ""
      ) {
        this.$toast("请选择处理截至时间");
        return;
      }
      if (
        this.requestData.patrolResult == 2 &&
        this.requestData.handleIdea == ""
      ) {
        this.$toast("请输入处理措施");
        return;
      }
      // 是否都上传图片
      let hasImage = this.requestData.patrolHandleTermDTOS.every(
        (i) => i.picList.length >= 2
      );

      if (!hasImage) {
        this.$toast("每项最少上传两张图片");
        return;
      }

      if (
        this.requestData.patrolResult == 1 &&
        !this.requestData.feedbackComment
      ) {
        this.$toast("请输入反馈内容");
        return;
      }
      // if (requestData.patrolResult == 2&&!this.requestData.feedbackComment) {
      //   this.$toast("请输入反馈内容");
      //   return;
      // }
      api.patrolImmediateProcessing(this.requestData).then((res) => {
        if (res.code == "200") {
          this.$toast("提交成功");
          let type = 1
          if(this.$route.query.patrolResult == 2){
            type = 0
          }
          this.$router.push({
            name: "Patrol",
            query: {
              examineState: 1,
              type: type,
            },
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
    //巡查结果
    radioChange() {
      let arr = this.requestData.patrolHandleTermDTOS.map((i) => {
        return i.termState;
      });
      const shi = arr.includes(2);
      if (shi == true) {
        this.requestData.patrolResult = 2;
      } else {
        this.requestData.patrolResult = 1;
      }
    },
    jialing(s) {
      return s < 10 ? "0" + s : s;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${this.jialing(
        date.getMonth() + 1
      )}-${this.jialing(date.getDate())}`;
    },
    onConfirm(date) {
      this.rili = false;
      this.requestData.handleTime = this.formatDate(date);
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
.formBox {
  .jiben {
    // margin-top: 21px;
    .infomation {
      align-items: center;
      padding-left: 17px;
      span {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 5px;
      }
      .kuai {
        height: 16px;
      }
    }
  }
  .projectBox {
    padding: 20px 16px 80px 16px;
    margin-top: 15px;
    background: #fff;
    .smallBox {
      .fankui {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        border-bottom: 1px solid #e8e9ec;
        margin-top: 20px;
        &:first-child {
          margin-top: 0px;
        }
        &:last-child {
          border-bottom: 0px;
        }
        .fankuixiangqing {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
          line-height: 23px;
        }
        .van-radio-group--horizontal {
          justify-content: space-around;
          margin: 51px 0px;
        }

        .img-icon {
          height: 16px;
        }
        .fankuiImg {
          display: flex;
          flex-direction: column;
          .word {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #363a44;
          }
          .illustrate {
            margin-top: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #b3b5b9;
          }
          img {
            width: 87px;
            height: 75px;
            border-radius: 5px;
            border: none;
          }
          .Box {
            width: 104px;
            height: 104px;
            background: #e9f3fe;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .round {
              width: 38px;
              height: 38px;
              background: #0c90ff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 19px;
                height: 19px;
              }
            }
          }
        }
        .abnormal {
          margin-bottom: 21px;
          span {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #363a44;
          }
        }
        .measure {
          span {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #363a44;
          }
        }
      }
    }
    .result {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #363a44;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e8e9ec;
      padding-bottom: 21px;
      .van-radio-group--horizontal {
        justify-content: space-around;
        width: 176px;
      }
      .img-icon {
        height: 16px;
      }
    }
    .Content {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #363a44;
      margin: 21px 0;
      border-bottom: 0px;
    }
    .fankuiImg {
      display: flex;
      flex-direction: column;
      .word {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #363a44;
      }
      .illustrate {
        margin-top: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #b3b5b9;
      }
      img {
        width: 87px;
        height: 75px;
        border-radius: 5px;
        border: none;
      }
      .Box {
        width: 104px;
        height: 104px;
        background: #e9f3fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .round {
          width: 38px;
          height: 38px;
          background: #0c90ff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
    .beTime {
      background: transparent;
      padding: 0 0;
      img {
        width: 18px;
        height: 17px;
        margin-right: 2px;
      }
      span {
        margin-right: 10px;
      }
    }
  }
  .bottomkeep {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0px;
    background: #ffffff;
  }
  .bottomBox {
    width: 100%;
    height: 80px;
    position: relative;
    bottom: -53px;
    background: #ffffff;
  }
}
/deep/.van-button {
  left: 0px;
  margin: 10px 17px;
  font-size: 20px;
  width: 90%;
  justify-content: center;
}
/deep/ .van-radio__label {
  font-size: 17px;
  margin-left: 10px;
}
/deep/.van-radio__icon {
  width: 17px;
  height: 17px;
}
/deep/.van-uploader__preview-image {
  width: 104px;
  height: 104px;
}
/deep/.van-uploader__preview {
  padding: 0 0px 0 0;
  margin: 0 15px 0px 0px;
}
/deep/.van-uploader {
  margin-top: 10px;
  margin-bottom: 21px;
}
/deep/.van-field__word-limit {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0c90ff;
}
/deep/ .van-cell {
  margin-top: 15px;
  background: #f6f7f8;
  border-radius: 9px;
  padding: 16px 16px;
  font-size: 17px;
}
/deep/.van-cell::after {
  border-bottom: 0px;
}
/deep/.van-cell::after {
  border-bottom: 0px;
}

.old {
  .infomation {
    .kuai {
      height: 22px !important;
    }
    & span {
      font-size: 22px !important;
    }
  }
  .projectBox {
    .smallBox {
      .fankui {
        & span {
          font-size: 20px !important;
        }
      }
    }
    .result, .van-radio-group, .Content {
      font-size: 20px !important;
    }
    .van-radio-group{
      .van-radio {
        /deep/ .van-radio__label {
          font-size: 20px !important;
        }
      }
    }
  }
  .bottomkeep {
    .van-button {
      .van-button__content {
        .van-button__text {
          font-size: 20px !important;
        }
      }
    }
  }
}
</style>
