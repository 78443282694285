export default {
  xunchaList: '/api/app/patrolTask/patrolPageList', //巡查列表
  xunchaxiangqing: '/api/app/patrolTask/getPatrolDetail',//巡查详情
  patrolHandleSave: '/api/app/patrolTask/patrolHandleSave',//巡查审核详情
  examineCompanyFlowSave: '/api/app/patrolTask/examineCompanyFlowSave',//巡查审核
  auditPatrolDetail: '/api/app/patrolTask/auditPatrolDetail', //巡查审核详情
  statrPatrol: '/api/app/patrolTask/startPatrol',//提交巡查
  patrolListNum: '/api/app/patrolTask/patrolListNum',//巡查列表未读数量
  patrolImmediateProcessing: '/api/app/patrolTask/immediateProcessing',//提交处理
  preservationPatrol:'/api/app/patrolTask/savePatrol',//保存巡查
  startDeal:'/api/app/patrolTask/startDeal',//异常处理
}