<template>
  <div class="myPatol">
    <!-- <div class="option">
      <div @click="yishenClick()" :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'">
        <div class="word">
          <div class="dian" v-show="yishendian">{{ yishenNumber }}</div>
          已巡查
        </div>
      </div>
      <div @click="xunClick()" :class="xunShow ? 'xunClick' : 'yishenheNoClick'">
        <div class="word">
          <div class="dian" v-show="xunNumber !== 0">{{ xunNumber }}</div>
          待巡查
        </div>
      </div>
    </div> -->
    <Search v-model="form" @changeclickSearch="clickSearch" @changeonConfirm="onConfirm"></Search>

    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0" :immediate-check="false">
        <div class="box" v-for="(item, index) in Data" :key="index" @click="xiangqing(item)">
          <div class="contentBox">
            <img v-if="!xunShow" :src="item.imageUrl ? item.imageUrl : zhanweiicon" alt="" />
            <div class="word">
              <div class="title">
                <span>{{ item.name }}</span>
                <div class="chuli" v-show="item.patrolResult == 2">
                  <van-button @click.stop="dealWith(item)" type="info">处理</van-button>
                </div>
              </div>
              <div class="biaoti">巡查标题：{{ item.title }}</div>
              <div class="biaoti">
                巡查反馈：{{
                    form.examineState == 1
                      ? item.feedbackComment
                      : item.patrolResult == 1
                        ? '正常'
                        : item.patrolResult == 2
                          ? '异常'
                          : ''
                }}
              </div>
              <div class="biaoti">
                {{ form.examineState == 1 ? '发布时间：' : '上传时间：'
                }}{{
    form.examineState == 1 ? item.createTime : item.patrolTime
}}
              </div>
            </div>
            <div v-if="xunShow" @click.stop="chakan(item)" class="chakan">
              <van-button type="info">巡查</van-button>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <no-data v-if="Data.length==0"></no-data>
  </div>

</template>
<script>
import api from '@/api/Patrol/index';
import moment from 'moment';
import Nav from '../../../src/components/nav/nav.vue'
import noData from '@/components/no-data'
import Search from '@/components/Search'
export default {
  components: {
    Nav,
    noData,
    Search
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 10,
        keyWord: '',
        examineState: 3,
        pageNum: 1,
        // queryTime: moment().format('YYYY-MM-DD')
      },
      Data: [],
      zhanweiicon: require('../../assets/img/login/zhanwei.png'),
      searchIcon: require('../../assets/img/patrol/search.png'),
      yishenShow: true,
      yishenNumber: null,
      yishendian: true,
      daiShow: false,
      daishenNumber: 2,
      daishendian: true,
      xunShow: false,
      xunNumber: null,
      xundian: true,
      state: '已审核',
      loading: false,
      finished: false
    };
  },
  created () {
    this.yiShow = true;
    if (this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.daiShow == true) {
      this.daishendian = false;
    }
    this.daiShow = this.$route.query.show;
    this.yiShow = false;
    this.getList();
    this.getUnread();
  },
  methods: {
    onLoad () {
      this.form.pageNum++;
      api.xunchaList(this.form).then((res) => {
        if (res.rows.length !== 0) {
          for (var i in res.rows) {
            this.Data.push(res.rows[i]);
          }
        } else {
          this.finished = true;
          return;
        }
        this.loading = false;
      });
    },
    xiangxi (item) {
      this.$router.push({
        name: 'patrolForm',
        query: {
          id: item
        }
      });
    },

    clickSearch () {
      this.form.pageNum = 1
      this.getList();
    },
    onConfirm () {
      this.getList();
    },
    yishenClick () {
      this.state = '已审核';
      this.form.examineState = 3;
      this.getList();
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    //查看跳转详情
    chakan (item) {
      if (item.examineState == 2 || item.examineState == 4) {
        console.log('22');
        this.$router.push({
          name: 'startInspection',
          query: {
            id: item.id,
            state: '待巡查'
          }
        });
      } else {
        this.$router.push({
          name: 'patrolForm',
          query: {
            id: item.id,
            state: '待巡查'
          }
        });
      }
    },
    //异常项处理
    dealWith (i) {
      console.log(i);
      if (i.examineState == 4) {
        this.$router.push({
          name: 'startInspection',
          query: {
            id: i.id
          }
        });
      } else if (i.examineState == 3) {
        this.$router.push({
          name: 'patrolForm',
          query: {
            id: i.id
          }
        });
      }

      // api.statrPatrol({ id: i.id }).then((res) => {
      //   if (res.code == '200') {
      //     this.$router.push({
      //       name: 'startInspection',
      //       params: {
      //         id: i.id
      //       }
      //     });
      //   } else {
      //     this.$toast(res.message);
      //   }
      // });
    },
    daishenClick () {
      this.state = '待审核';
      this.form.examineState = 2;
      this.getList();
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
    },
    xunClick () {
      this.state = '待巡查';
      this.form.examineState = 1;
      this.getList();
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    },
    xiangqing (item) {
      //已审核跳转页面
      if (item.patrolResult == 2) {
        this.$router.push({
          name: 'xunchashenhe',
          query: {
            id: item.id,
            state: '待巡查'
          }
        });
        return;
      }
      if (this.form.examineState == 3) {
        this.$router.push({
          name: 'xunchashenhe',
          query: {
            id: item.id
          }
        });
      } else if (this.form.examineState == 1) {
        this.$router.push({
          name: 'patrolForm',
          query: {
            id: item.id,
            state: this.state
          }
        });
      }
    },
    //请求列表数据
    getList () {
      api.xunchaList(this.form).then((res) => {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true
        });
        this.Data = res.rows;
      });
    },
    //未读数量
    getUnread () {
      api.patrolListNum().then((res) => {
        if (res.code == '200') {
          console.log(res.data);
          this.yishenNumber = res.data.implementNum;
          this.xunNumber = res.data.unImplementNum;
        } else {
          this.$toast(res.message);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
::v-deep .custom-image .van-empty__image {
  width: 110px;
  height: 82px;
}
.myPatol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    height: 40px;
    width: 98%;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: #fff;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-icon__image {
          width: 12px;
          height: 12px;
        }
      }

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e8e9ec;

    .box {
      width: 73px;
      height: 100%;
      color: #0c90ff;
      font-size: 16px;
      border-bottom: 3px solid #0c90ff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dianstyle {
      width: 14px;
      height: 14px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: 2px;
      top: 2px;
      line-height: 14px;
      font-size: 12px;
      color: #fff;
    }

    .yishenheClick {
      .box();
    }

    .yishenheNoClick {
      width: 73px;
      height: 36px;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFang SC;
      line-height: 38px;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();
    }

    .xunClick {
      .box();
    }
  }

  .list {
    margin: 15px 0 50px 0;
    overflow-y: auto;
    padding: 0 10px;

    .box {
      &:first-child {
        margin-top: 0px;
      }

      margin-top: 10px;
      height: 120px;
      border-radius: 10px;
      position: relative;
      background: #fff;
      border-radius: 10px;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;

      .biaoshiStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        color: #fff;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
      }

      .lvbiaoshi {
        .biaoshiStyle();
        background: #50cb79;
        box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
      }

      .huangbiaoshi {
        background: #ffac20;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
      }

      .hongbiaoshi {
        background: #ff6661;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
      }

      .contentBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 16px 0px 16px;

        .word {
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          box-sizing: border-box;
          padding: 0px 10px;

          .title {
            font-size: 15px;
            font-weight: 600;
            color: #363a44;
            display: flex;
            justify-content: space-between;

            .chuli {
              display: flex;

              .van-button {
                width: 61px;
                height: 23px;
                box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
                border-radius: 10px;
                font-size: 12px;
              }
            }
          }

          .biaoti {
            width: 190px;
            margin-top: 4px;
            white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        img {
          height: 82px;
          width: 110px;
          border-radius: 8px;
        }

        .chakan {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .van-button {
            height: 21px;
            box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
            border-radius: 10px;
            font-size: 12px;
            background: #1c91ff;
            font-family: PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
