
export default {
  taskList: '/api/app/task/taskList',//获取任务列表
  addTask: '/api/app/task/addTask',//发布任务
  companyList: '/api//app/task/companyList',//获取单位信息列表
  taskDetail: '/api/app/task/taskDetail',//任务详情
  auditTaskDetail: '/api/app/task/auditTaskDetail', //已经审核过的详情
  auditTask: '/api/app/task/auditTask', //提交审核结果
  taskListNum: '/api/app/task/taskListNum', //任务未读数量
  releaseTask: '/api/app/task/releaseTask',//提交任务
  taskimmediateProcessing: '/api/app/task/immediateProcessing',//立即处理改变状态
}