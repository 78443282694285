<template>
  <div class="youke" :class="{ old: isElder }">
    <div id="youke">
      <van-tabs
        v-model="activeyouke"
        animated
        sticky
        title-active-color="#0C90FF"
        color="#0C90FF"
        @change="changevantab"
      >
        <van-tab style="font-size: 20px" title="建议反馈" name="建议反馈">
          <div class="bigBox">
            <div class="selectBox">
              <div class="jingqu" @click="selectedScenicArea">
                <span>{{ jingName }}</span>
                <div class="jiao"></div>
              </div>

              <van-popup
                v-model="jingshow"
                round
                position="bottom"
                class="jingxuan"
                :style="{ height: '50%' }"
                closeable
                close-icon="close"
                close-icon-position="top-right"
                get-container="#youke"
              >
                <van-picker
                  show-toolbar
                  :columns="ScenicAreaList"
                  @cancel="jingshow = false"
                  @confirm="onConfirm"
                  toolbar-position="bottom"
                />
              </van-popup>

              <div class="sheshi" @click="chooseFacilities">
                <span>{{ facility }}</span>
                <div class="jiao"></div>
              </div>

              <van-popup
                v-model="sheshishow"
                round
                position="bottom"
                :style="{ height: '50%' }"
                closeable
                close-icon="close"
                close-icon-position="top-right"
                get-container="#youke"
              >
                <van-picker
                  show-toolbar
                  :columns="FacilityList"
                  @cancel="sheshishow = false"
                  @confirm="onConfirmsheshi"
                  toolbar-position="bottom"
                />
              </van-popup>
              <div class="map">
                <img :src="map" alt="" />
              </div>
            </div>
            <div class="liuyanBox">
              <van-field
                v-model="form.context"
                rows="2"
                autosize
                type="textarea"
                placeholder="请详细描述事件发生地点、什么事、期望怎么解 决等等..."
              />
              <div class="imgtupian">
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    v-for="(item, index) in this.form.imageUrls"
                    :key="index"
                  >
                    <div class="bgtupianshow">
                      <div class="img-btn">
                        <van-icon
                          name="cross"
                          size="20"
                          @click="imgDel(item, index)"
                        />
                      </div>
                      <div>
                        <img class="tupianshow" color="#ffffff" :src="item" />
                      </div>
                    </div>
                  </div>
                  <oss-upload
                    ref="upload"
                    v-if="this.form.imageUrls.length < 8"
                    style="margin: 20px 0 0 0"
                    @change="uploadChange"
                  >
                  </oss-upload>
                </div>
              </div>
              <van-button
                style="margin: 50px 10px 0 10px"
                round
                type="info"
                @click="submit"
                size="50px"
                >确认提交</van-button
              >
            </div>
            <!-- <div class="jingquBox">
              <van-field v-model="form.location" type="input" placeholder="请输入您所在的位置" :left-icon="weizhi">
                <template #right-icon>
                  <span class="bixuan">必选</span>
                  <img :src="jiantou" class="jiantou" alt="" />
                </template>
              </van-field>
            </div> -->
            <!-- <div class="shifouBox">
              <van-cell title="是否公开" :icon="shifou">
                <template #right-icon>
                  <div style="display: flex">
                    <div style="font-size: 16px; color: #b3b5b9; margin: 0px 5px">
                      否
                    </div>
                    <van-switch v-model="form.isOpen" active-color="#1C91FF" inactive-color="#dcdee0" size="20px" />
                    <div style="font-size: 16px; color: #b3b5b9; margin: 0px 5px">
                      是
                    </div>
                  </div>

                </template>
              </van-cell>
            </div> -->
          </div>
        </van-tab>
        <van-tab title="景区评分" name="景区评分">
          <div class="bigBox" v-if="isshowscenic">
            <div class="selectBox">
              <div class="jingqu" @click="selectedScenicArea2" style="width:84%">
                <span>{{ jingName2 }}</span>
                <div class="jiao"></div>
              </div>
              <van-popup
                v-model="jingshow2"
                round
                position="bottom"
                class="jingxuan"
                :style="{ height: '50%' }"
                closeable
                close-icon="close"
                close-icon-position="top-right"
                get-container="#youke"
              >
                <van-picker
                  show-toolbar
                  :columns="ScenicAreaList"
                  @cancel="jingshow2 = false"
                  @confirm="onConfirm2"
                  toolbar-position="bottom"
                />
              </van-popup>
              <div class="map">
                <img :src="map" alt="" />
              </div>
            </div>
            <div class="youkecontern">
              <div v-for="(item, index) in listdata" :key="index">
                <p class="youkewen">{{ item.title }}</p>
                <div class="radiogroup">
                  <van-radio-group v-model="item.state" direction="horizontal">
                    <van-radio style="margin: 0 50px" name="1">是</van-radio>
                    <van-radio style="margin: 0 50px" name="0">否</van-radio>
                  </van-radio-group>
                </div>
              </div>
              <van-button
                style="margin: 50px 0 0 0; width: 100%"
                round
                type="info"
                @click="submit2"
                size="50px"
                >确认提交</van-button
              >
            </div>
          </div>
          <!-- <div class="shifouBox">
              <van-cell title="是否公开" :icon="shifou">
                <template #right-icon>
                  <div style="display: flex">
                    <div style="font-size: 16px; color: #b3b5b9; margin: 0px 5px">
                      否
                    </div>
                    <van-switch v-model="form2.isOpen" active-color="#1C91FF" inactive-color="#dcdee0" size="20px" />
                    <div style="font-size: 16px; color: #b3b5b9; margin: 0px 5px">
                      是
                    </div>
                  </div>
                </template>
              </van-cell>
            </div> -->
          <!-- <no-data v-if="!isshowscenic"></no-data> -->
        </van-tab>
      </van-tabs>
    </div>

    <div style="height: 50px">
      <Nav></Nav>
    </div>
  </div>
</template>

<script>
import noData from "@/components/no-data";
import Nav from "../../components/nav/zlbNav.vue";
import ossUpload from "../../components/ossUpload/ossUpload.vue";
import api from "@/api/home/index";
import request from "@/api/index";
export default {
  components: {
    Nav,
    ossUpload,
    noData,
  },
  data() {
    return {
      isshowscenic: true,
      listdata: [
        {
          title: "1.是否在醒目处对外公布日最大承载量和瞬时承载量",
          state: "1",
        },
        {
          title:
            "2.是否景区内无破损、腐烂建筑物和构筑物，公共休息设施完好，能正常使用。",
          state: "1",
        },
        {
          title:
            "3.是否食品卫生符合国家规定，餐饮服务配备消毒设施，不得使用对环境造成污染的一次性餐具。",
          state: "1",
        },
        {
          title:
            "4.是否为特定人群（老年人、儿童、残疾人等）配备旅游工具、用品，提供特殊服务。",
          state: "1",
        },
        {
          title:
            "5.是否消防、防盗、救护等设备要求齐全、完好、有效，交通、机电、游览、娱乐等设备完好，运行正常且无安全隐患。",
          state: "1",
        },
        {
          title:
            "6.是否景区内引导标识、游览（参观）路线或航道清晰、布局合理、顺畅，可观赏面大。",
          state: "1",
        },
        {
          title: "7.是否建立紧急救援机制，设立医务室，并配备专兼职医务人员。",
          state: "1",
        },
        {
          title:
            "8.是否危险地段标志明显，防护设施齐备、有效，高峰期有专人看守。",
          state: "1",
        },
      ],
      radio: "1",
      activeyouke: "建议反馈",
      fileList: [],
      form: {
        context: "",
        facilityId: "",
        scenicSpotId: "",
        imageUrls: [],
        filesUrls: []
      },
      form2: {
        scenicSpotId: "",
      },

      weizhi: require("../../assets/img/zlb/dingwei.png"),
      jiantou: require("../../assets/img/zlb/jiantou.png"),
      shifou: require("../../assets/img/zlb/shifou.png"),
      xuanze: require("../../assets/img/task/xuanze.png"),
      weixuan: require("../../assets/img/task/weixuan.png"),
      checked: false,
      message: "",
      map: require("../../assets/img/zlb/map.png"),

      jingshow: false,
      sheshishow: false,
      jingshow2: false,
      facility: "请选择设施",
      ScenicAreaList: [],
      FacilityList: [],
      jingName: "请选择景区",
      jingName2: "请选择景区",
      allData: [],
    };
  },
  mounted() {
    this.getScenicAreaList();
    this.getLocation();
  },
  computed: {
    userId() {
      const userId = this.$store.state.id;
      //   console.log('userId', userId);
      return userId;
    },
    userName() {
      const userName = this.$store.state.userName;
      return userName;
    },
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  methods: {
    onConfirm(value) {
      //   console.log(value);
      this.allData.forEach((i) => {
        if (i.name == value) {
          this.form.scenicSpotId = i.id;
          this.form.scenicSpotName = i.name;
          this.FacilityList = i.facilityList.map((j) => {
            return { text: j.facilityName, id: j.id };
          });
          console.log(this.FacilityList, "this.FacilityList");
        }
      });
      this.jingshow = false;
      this.jingName = value;
      this.facility = '请选择设施'
      this.form.facilityId = ''
    },

    getLocation() {
      function agree_obtain_location() {
        var option = {
          enableHighAccuracy: true,
          timeout: Infinity,
          maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(geoSuccess, geoError, option);
      }

      function geoSuccess(event) {
        console.log(event.coords.latitude + ", " + event.coords.longitude);
      }

      function geoError(event) {
        console.log("Error code " + event.code + ". " + event.message);
      }
      agree_obtain_location();
    },
    onConfirmsheshi(value) {
      this.sheshishow = false;
      this.facility = value.text;
      this.form.facilityId = value.id;
      this.form.facilityName = value.name;
    },

    onConfirm2(value) {
      //   console.log(value);
      this.allData.forEach((i) => {
        if (i.name == value) {
          this.form2.scenicSpotId = i.id;
        }
      });
      this.jingshow2 = false;
      this.jingName2 = value;
    },

    getPosition() {
      //   this.$router.push({
      //     name: 'zlbMap'
      //   });
    },
    //选择景区
    selectedScenicArea() {
      this.jingshow = true;
    },
    selectedScenicArea2() {
      this.jingshow2 = true;
    },
    //选择设施
    chooseFacilities() {
      //没有选择景区 先选择景区
      if (this.jingName == "请选择景区") {
        this.$toast("请先选择景区");
      } else {
        this.sheshishow = true;
      }
    },
    //获取景区列表
    getScenicAreaList() {
      api.scenicSpotList({ publicUser: true }).then((res) => {
        if (res.code == "200") {
          this.allData = res.data;
          this.ScenicAreaList = res.data.map((i) => {
            return i.name;
          });
          console.log(this.ScenicAreaList);
        } else {
          this.$toast(res.message);
        }
      });
    },

    initform() {
      this.form = {
        context: "",
        facilityId: "",
        scenicSpotId: "",
        imageUrls: [],
      };
    },
    initform2() {
      this.form2 = {
        scenicSpotId: "",
      };
    },
    changevantab(name) {
      //   console.log(name);
      if (name == "建议反馈") {
        this.jingName = "请选择景区";
        this.facility = "请选择设施";
        this.initform();
      } else if (name == "景区评分") {
        this.jingName2 = "请选择景区";
        this.initform2();
      }
    },

    uploadChange(value, file) {
      // console.log(value, "图片路径", file,{url: value[0], file: file});
      this.form.imageUrls.push(...value);
      this.form.filesUrls.push({url: value[0], file: file})
    },
    imgDel(item, index) {
      this.form.imageUrls.splice(index, 1);
      let file = this.form.filesUrls.map((filesUrl)=> {
        if (filesUrl.url == item) return filesUrl.file
      })
      this.$refs['upload'].delteFileList(file) // 删除组件中的文件，不然数据会不一致
      // console.log('删除图片', this.form.imageUrls)
    },
    //提交
    submit() {
      let params = { ...this.form };
      if (this.form.imageUrls.length) {
        params.imageUrls = this.form.imageUrls.join(",");
      } else {
        params.imageUrls = "";
      }
      params.userId = this.userId;
      params.userName = this.userName;
      params.isOpen = this.form.isOpen ? 1 : 0;
      params.scenicName = this.jingName;
      params.facilityName = this.facility;
      console.log(params);
      if (!this.form.scenicSpotId) {
        this.$toast("请选择景区");
        return;
      }
      if (!this.form.facilityId) {
        this.$toast("请选择设施");
        return;
      }
      if(params.imageUrls.toString().trim()== 0 && params.context==""){
        this.$toast("图片和内容至少填写一个");
        return
      }
      request.post2("suggestionSave", params).then((res) => {
        if (res.code == "200" && res.success) {
          this.$toast(res.message);
          this.$router.push({
            path: "/zhelibanPage/record",
            query: this.$route.query,
          });
        }
      });
    },
    submit2() {
      let params = { ...this.form2 };
      params.userId = this.userId;
      params.userName = this.userName;
      params.scenicName = this.jingName2;
      params.isOpen = this.form.isOpen ? 1 : 0;
      params.scoreList = this.listdata
        .map((item) => {
          return item.state;
        })
        .join(",");
      console.log(params);
      if (!this.form2.scenicSpotId) {
        this.$toast("请选择景区");
        return;
      }
      api.suggestionScoreSave(params).then((res) => {
        // request.post("/ext/suggestion/score/save ", params).then((res) => {
        if (res.code == "200" && res.success) {
          this.$toast(res.message);
          this.$router.push({
            path: "/zhelibanPage/record",
            query: {
              type: 1,
              ...this.$route.query,
            },
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
.bigBox {
  ::v-deep .radiogroup .van-radio-group--horizontal {
    justify-content: center;
  }
  ::v-deep .van-radio__label {
    font-size: 16px;
    font-weight: 400;
    color: #363a44;
  }
  ::v-deep .jingquBox span[data-v-3101530e],
  .shifouBox span[data-v-3101530e] {
    font-size: 16px !important;
  }
  .youkecontern {
    margin: 20px 10px;
  }
  .youkewen {
    font-size: 16px;
    font-weight: 400;
    color: #363a44;
    line-height: 24px;
  }
  .imgtupian {
    margin: 0 15px;
  }
  .img-btn {
    color: #ffffff;
    cursor: pointer;
    position: relative;
    left: 65px;
    top: 25px;
    z-index: 100;
    font-size: 18px;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .tupianshow {
    width: 80px;
    height: 80px;
    margin: 0px 10px;
    position: relative;
  }
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  .selectBox {
    padding: 17px 20px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .jingqu {
      width: 40%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .jiao {
        height: 6px;
        width: 8px;
        align-self: flex-end;
        background-size: 100% 100%;
        background-image: url("../../assets/img/zlb/youjiao.png");
      }
    }
    .jingxuan {
      display: flex;
      justify-content: center;
    }
    .sheshi {
      width: 40%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .jiao {
        height: 6px;
        width: 8px;
        background-image: url("../../assets/img/zlb/youjiao.png");
        background-size: 100% 100%;
        align-self: flex-end;
      }
    }
    .map {
      height: 22px;
      width: 34px;
      display: flex;
      border-left: 1px solid #c0c0c0;
      justify-content: space-around;

      img {
        width: 19px;
        height: 19px;
        align-self: flex-end;
      }
    }
  }
  .liuyanBox {
    margin-top: 20px;
    // height: 300px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    .img {
      padding: 68px 16px 16px 16px;
    }
  }
  .jingquBox {
    margin-top: 12px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    height: 78px;
    display: flex;

    .van-cell {
      align-items: center;
      .van-cell__left-icon {
        margin-top: 6px;
      }
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 26px;
    }
    .bixuan {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      line-height: 26px;
      margin-right: 10px;
    }
    .jiantou {
      height: 16px;
      width: 9px;
    }
  }
  .shifouBox {
    margin-top: 12px;
    height: 57px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    display: flex;
    .van-cell {
      align-items: center;
      .van-cell__left-icon {
        margin-top: 6px;
      }
    }
    .kejian {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      line-height: 26px;
      margin-right: 6px;
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 26px;
    }
  }
  .van-button {
    margin-top: 12px;
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  .popupBox {
    padding: 48px 34px 38px 34px;
    .title {
      align-self: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #363a44;
    }
    .xuanze {
      .van-checkbox {
        justify-content: space-between;
        &:first-child {
          margin: 0 0 0 0;
          font-size: 20px;
        }
        margin-top: 27px;
        font-size: 20px;
        height: 25px;
      }
    }
  }
  .buttomBox {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .close {
      padding: 8px 30px;
      border-radius: 27px;
      border: 1px solid #0085ff;
      color: #0085ff;
      font-size: 20px;
      text-align: center;
      margin: 0 10px;
    }
    .sure {
      padding: 8px 30px;
      border-radius: 27px;
      text-align: center;
      background: #1c91ff;
      color: #fff;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .lanse {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1c91ff;
  }
  .moren {
    // font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #686973;
  }
  .van-radio-group {
    .title {
      height: 50px;
      font-size: 24px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .van-radio {
      font-size: 24px;

      .van-radio__icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
/deep/.van-cell::after {
  border-bottom: 0px;
}
/deep/.van-picker__cancel {
  width: 100px;
  height: 40px;
  font-size: 18px;
  margin: 0 20px;
}
/deep/.van-picker__confirm {
  width: 100px;
  height: 40px;
  font-size: 18px;
  margin: 0 20px;
}
/deep/ .van-picker__toolbar {
  justify-content: center;
}
.old {
  /deep/ .van-tab {
    font-size: 20px;
  }
  /deep/ .bigBox .selectBox span {
    font-size: 20px;
  }
  /deep/ .van-cell__value {
    font-size: 18px;
  }
  /deep/ .bigBox .youkewen {
    font-size: 18px;
  }
  /deep/ .van-radio__label {
    font-size: 18px;
  }
  /deep/ .van-tabbar-item__text {
    font-size: 18px;
  }
}
</style>
