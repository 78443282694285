<template>
    <div class="wu">
        <img :src="wu" alt="" />
        <div class="word">
            暂无数据~
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            wu: require('../../assets/img/my/norenwu.png'),
        }
    }
}
</script>

<style lang="less" scoped>
.wu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 0.4rem;
    padding-top: 0.4rem;
    img {
        width: 4rem;
        height: 4rem;
    }
    .word {
        margin-top: 0.3rem;
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #B3B5B9;
        span {
            color: #317aed;
        }
    }
}
</style>