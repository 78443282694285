<template>
  <div class="bigBox" :class="{ old: isElder }">
    <div v-if="!isElder" class="topBox"></div>
    <div class="middleBox">
      <div class="leftBox">
        <p class="title">游客参与</p>
        <div class="smallBox">
          <div>游客体验高频事项<br />意见反馈</div>
          <img :src="youke" alt="" />
        </div>
        <van-button round type="info" @click="tourist">点击进入</van-button>
      </div>
      <div class="leftBox">
        <p class="title">景区巡检</p>
        <div class="smallBox">
          <div :style="isElder? 'width: 50%' : ''">提高治理能力防范<br />化解重大风险隐患</div>
          <img :src="qiye" alt="" />
        </div>
        <van-button
          @click="toHomeClick"
          :class="{ oldButton: isElder, 'van-button--disabled': disabled }"
          round
          type="info"
          >点击进入</van-button
        >
      </div>
    </div>
    <div class="shuoming" :class="{ shuomOld: isElder }" @click="onCall">
      本服务由浙江政务服务网、桐庐县文广旅体局提供
      <br />服务咨询热线：
      <div style="color: #0366f1; display: inline">
        {{ tel.substring(0, 4) + "-" + tel.substring(4) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qiye: require("../../assets/img/zlb/qiye.png"),
      youke: require("../../assets/img/zlb/youke.png"),
      tel: "057158598070",
    };
  },
  // 网页端测试适老化
  // created() {
  //   this.$store.commit("setUiStyle", 'elder');
  // },
  methods: {
    tourist() {
      this.$router.push({
        name: "zlbYou",
      });
    },
    onCall() {
      ZWJSBridge.phoneCall({
        corpId: this.tel,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toHomeClick() {
      console.log("this.disabled", this.disabled);
      if (this.disabled) {
        this.$toast("没有企业运营权限,请联系管理员授权");
      } else {
        this.$router.push({ path: "/home" });
      }
    },
  },
  computed: {
    disabled: function () {
      return !(this.$store.state.roleId && Number(this.$store.state.roleId));
    },
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
.bigBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .topBox {
    height: 151px;
    width: 100%;
    background-image: url("../../assets/img/zlb/top.png");
    background-size: 100% 100%;
  }
  .middleBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 8px;
    flex-wrap: wrap;

    .leftBox,
    .rightBox {
      margin-top: 10px;
      height: 210px;
      width: 175px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 0px 4px 0px 4px;
      border-radius: 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .title {
        font-size: 20px;
        font-family: PingFang SC;

        font-weight: 600;
        color: #363a44;
      }

      .smallBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 60px;
        background: #f0f8ff;
        border-radius: 3px;
        padding-left: 5px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #686b73;
        img {
          height: 39px;
        }
      }
    }
  }
  .shuoming {
    position: absolute;
    bottom: 20px;
    width: 312px;
    font-size: 13px;
    font-family: PingFang SC;
    // font-weight: 600;
    color: #b3b5b9;
    line-height: 25px;
    margin-top: 90px;
    text-align: center;
  }
  .shuomOld {
    font-size: 18px;
  }
}
/deep/.van-button {
  width: 156px;
  height: 36px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
// .oldButton {

.old {
  .leftBox {
    width: 100% !important;
  }
  .rightBox,
  .leftBox {
    height: 240px !important;
    .title {
      font-weight: bold !important;
      font-size: 25px !important;
    }
  }
  .smallBox {
    font-size: 34rpx !important;
    font-family: PingFang SC;
    font-weight: bold;
    color: #686b73;
    height: 80px !important;
    width: 70%;
    div {
      font-size: 20px !important;
    }
  }
  /deep/.van-button {
    margin: 10px;
    width: 200px;
    height: 43px;
    line-height: 43px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
