<template>
  <div class="HomeBOX" :class="{ old: isElder }">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div class="HomeTop">
        <div style="width: 85%">
          <van-notice-bar
            color="#1989fa"
            :scrollable="true"
            background="#fff"
            :left-icon="trumpet"
          >
            {{ title }}
          </van-notice-bar>
        </div>
        <div>
          <van-popover
            v-model="showPopover"
            trigger="click"
            placement="bottom-end"
          >
            <van-radio-group v-model="scenicSpotId" @change="changescenic">
              <div
                v-for="(item, index) in ScenicAreaList"
                :key="index"
                class="scenicidradio"
                :class="{ oldRadio: isElder }"
              >
                <van-radio :name="item.id">
                  <span
                    :style="{
                      color: scenicSpotId == item.id ? '#00AEFF' : '#999999',
                      'font-size': isElder ? '18px' : '16px',
                    }"
                  >
                    {{ item.name }}
                  </span>
                </van-radio>
                <van-divider :style="{ padding: '0', margin: '8px 0' }" />
              </div>
            </van-radio-group>
            <template #reference>
              <div class="scenicicon">
                <img src="@/assets/img/home/scenicicon.png" alt="" />
              </div>
            </template>
          </van-popover>
        </div>
      </div>
      <div class="thisMonth">
        <div class="thisMonthWaring">
          <div class="top">
            <span
              >本月预警<span class="big"> {{ topData.warningCount || 0 }} </span>
              <!-- {{ topData.warningCount }} -->

              次</span
            >
          </div>
          <div class="xian"></div>
          <div class="button">
            <span
              >本月已处理<span class="big">
                {{ topData.warningCount2 || 0 }}
                <!-- {{ topData.warningCount2 }} --> </span
              >次</span
            >
          </div>
        </div>
        <div class="thisMonthTask">
          <div class="top">
            <span
              >本月任务<span class="big">
                {{ topData.taskCount || 0 }} </span
              >个</span
            >
          </div>
          <div class="xian"></div>
          <div class="button">
            <span
              >本月已处理<span class="big">
                {{ topData.taskCount2 || 0 }} 
                </span
              >个</span
            >
          </div>
        </div>
        <div class="thisMonthExamine">
          <div class="top">
            <span
              >本月例巡 <span class="big">
                {{ topData.patrolCount || 0 }}
              </span>
              <!-- {{ topData.patrolCount }} -->
              次</span
            >
          </div>
          <div class="xian"></div>
          <div class="button">
            <span
              >本月已巡查<span class="big">
                {{ topData.patrolCount2 || 0 }}
                <!-- {{ topData.patrolCount2 }}  --> </span
              >次</span
            >
          </div>
        </div>
      </div>
      <div class="important">
        <div class="top">
          <div class="title">
            <div class="icon"></div>
            <span>重点关注</span>
          </div>
          <div class="more" @click="skip" v-if="viewVideo && dataList.length">
            <span>更多</span>
            <div class="back"></div>
          </div>
        </div>
        <div class="importanImg">
          <template v-if="viewVideo">
            <van-swipe
              v-if="dataList && dataList.length > 0"
              class="my-swipe"
              :autoplay="15000"
              indicator-color="white"
              @change="onChange"
            >
              <van-swipe-item v-for="(item, index) in dataList" :key="index">
                <!-- 监控视频 -->
                <!-- <flvPlayer ref="flvPlayer" :videoURL="videoURL"></flvPlayer> -->
                <LivePlayer
                  ref="LivePlayer"
                  v-if="
                    Math.abs(current - index) < 2 ||
                    Math.abs(current - index) == dataList.length
                  "
                  :poster="item.imgAddress"
                  :videoURL="item.hls"
                ></LivePlayer>
              </van-swipe-item>
            </van-swipe>
            <div v-else>
              <no-data></no-data>
            </div>
          </template>
          <div v-else class="no-auth-video">
            <img src="@/assets/img/home/web-img.png" class="video-img" />
            <div>暂无访问权限</div>
          </div>
        </div>
      </div>
      <div class="option">
        <div class="scenicArea" @click="map()">
          <div class="back"></div>
          <span>景区地图</span>
        </div>
        <div class="shebeiIcon" @click="waring">
          <div class="iconwarn" v-if="yujingtotal">
            <van-icon name="warning" color="#FF3A2F" size="18" />
          </div>
          <div class="back"></div>
          <span>设备预警</span>
        </div>
        <div class="yinhuan" @click="yinhuan()">
          <div class="back"></div>
          <span>排查预警</span>
        </div>
        <div class="anquan" @click="safetyForm()">
          <div class="back"></div>
          <span>安全评估</span>
        </div>
      </div>
      <div class="jingquList">
        <div class="top">
          <div class="title">
            <div class="icon"></div>
            <span>设施列表</span>
          </div>
          <div class="search">
            <van-field
              v-model="form.name"
              placeholder="搜索设施名称"
              type="text"
              input-align="center"
              @input="getList"
            >
              <template slot="left-icon">
                <img
                  style="width: 14px; height: 14px"
                  :src="searchIcon"
                  alt=""
                  class="searchInput"
                  @click="clickSearch"
                />
              </template>
            </van-field>
          </div>
        </div>
        <div class="listox">
          <template v-if="sheList.length > 0">
            <div class="list" v-for="(item, index) in sheList" :key="index">
              <div class="box" @click="waringClick(item)">
                <img
                  :src="item.imageUrl ? item.imageUrl : zhanweiicon"
                  class="jingImg"
                  alt=""
                />
                <div class="textBox">
                  <div class="title">{{ item.name }}</div>
                  <div class="shebei" style="margin-top: 4px">
                    设备总数：<span class="number"
                      >{{ item.equipmentCount }}个</span
                    >
                  </div>
                  <div class="xiangqing">
                    <div @click="waringClick(item)">
                      预警：<span class="number"
                        >{{ item.warningCount }}次</span
                      >
                    </div>
                    <div @click="patolClick(item)">
                      巡查：<span class="number">{{ item.patrolCount }}次</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <no-data class="noData"></no-data>
          </template>
        </div>
      </div>
      <Nav></Nav>
    </van-pull-refresh>
  </div>
</template>
<script>
import api from "../api/methods";
import api2 from "@/api/home/index";
import api3 from "@/api/waring/index";
import flvPlayer from "../components/monitor/flvPlayer.vue";
import LivePlayer from "../components/monitor/LivePlayer.vue";
import Nav from "../../src/components/nav/nav.vue";
import request from "@/api/index";
import { CheckIsAndroid } from "@/utils/index";
import noData from "@/components/no-data";
export default {
  name: "Home",
  components: {
    Nav,
    flvPlayer,
    LivePlayer,
    noData,
  },
  data() {
    return {
      videoURL: "",
      //   全局景区ID
      scenicSpotId: 0,
      ScenicAreaList: [],
      showPopover: false,
      current: 0,
      yujingtotal: 0,
      queryParams: {
        condition: "",
        facilityId: null,
        scenicSpotId: null,
        type: 1,
      },
      dataList: [],
      form: {
        name: "",
      },
      topData: {},
      sheList: [],
      zhanweiicon: require("../assets/img/login/zhanwei.png"),
      searchIcon: require("../assets/img/home/search.png"),
      //   title: '这是一条防疫的消息,疫情期间注意防控!',
      title: "",
      trumpet: require("../assets/img/home/trumpet.png"),
      importanImg: require("../assets/img/login/background.webp"),
      userName: "admin",
      // userName: 'tth',
      password: "qqqqqq",
      // userName: 'lxj',
      // password: '123456'
      isLoading: false,
    };
  },
  created() {
    // setTimeout(() => {
    //   this.$toast.loading({
    //     message: '加载中...',
    //     forbidClick: true
    //   });
    // }, 500);
  },
  mounted() {
    // console.log(CheckIsAndroid(), "JS判断手机是安卓还是IOS");
    this.getList();
    this.getAnnouncementInfo();
    this.getListyujing();
    this.getScenicAreaList();
  },
  methods: {
    changescenic(name) {
      console.log("changescenic", name);
      this.scenicSpotId = name;
      this.$store.commit("setScenicSpotId", this.scenicSpotId);
      this.getTopList();
      this.allMonitorInfo();
      this.getList();
      this.getAnnouncementInfo();
    },
    //获取景区列表
    getScenicAreaList() {
      api2.scenicSpotList().then((res) => {
        if (res.code == "200") {
          this.ScenicAreaList = res.data;
          this.showPopover = false;
          if (!this.$store.state.scenicSpotId) {
            this.$store.commit("setScenicSpotId", res.data && res.data[0].id);
          }
          this.scenicSpotId = this.$store.state.scenicSpotId;
          // this.showPopover = this.$route.query.first == 1;
          this.getTopList();
          this.allMonitorInfo();
          this.getList();
        } else {
          this.$toast(res.message);
        }
      });
    },
    onRefresh() {
      this.getList();
      this.isLoading = false;
    },
    map() {
      this.$router.push({
        name: "industryMap",
        query: {
          name: "景区地图",
        },
      });
    },

    //获取预警列表数据
    getListyujing() {
      api3
        .warnList({
          examineState: 1,
        })
        .then((res) => {
          if (res.code == "200") {
            this.yujingtotal = res.total;
          }
        });
    },

    //重点关注
    allMonitorInfo() {
      const scenicSpotId = this.$store.state.scenicSpotId;
      console.log("scenicSpotId", this.scenicSpotId);
      this.queryParams.scenicSpotId = scenicSpotId;
      request.post2("getOnlineMonitor", this.queryParams).then((res) => {
        if (res.code == "200" && res.success) {
          const { equipmentVOS } = res.data;
          const isFocusdata = equipmentVOS.filter((item) => {
            return item.isFocus == 1;
          });
          // this.videoURL = equipmentVOS[0] && equipmentVOS[0].webrtc
          this.dataList = isFocusdata;
        }
      });
    },

    //获取设施列表
    getList() {
      api2
        .list({ ...this.form, scenicSpotId: this.scenicSpotId })
        .then((res) => {
          if (res.code == "200") {
            this.sheList = res.data;
          }
        });
    },

    clickSearch() {
      this.getList();
    },

    onChange(index) {
      this.current = index;
    },
    skip() {
      this.$router.push({
        name: "zhongdianForm",
        query: {
          name: "重点关注",
        },
      });
    },
    safetyForm() {
      this.$router.push({
        name: "safetyForm",
        query: {
          name: "安全评估",
        },
      });
    },
    yinhuan() {
      this.$router.push({
        name: "yinhuanForm",
        query: {
          name: "排查预警",
        },
      });
    },

    //设施列表预警跳转页面
    waringClick(item) {
      this.$router.push({
        name: "yujinglist",
        query: {
          state: "yujing",
          id: item.id,
          name: item.name,
        },
      });
    },
    //设施列表巡查跳转页面
    patolClick(item) {
      this.$router.push({
        name: "yujinglist",
        query: {
          state: "xuncha",
          id: item.id,
          name: item.name,
        },
      });
    },

    //预警设备跳转列表
    waring() {
      this.$router.push({
        name: "waringList",
        query: {
          name: name,
        },
      });
    },

    //获取公告
    getAnnouncementInfo() {
      request
        .post2("announcementList", {
          page: 1,
          pageSize: 1,
          condition: null,
          type: 1,
          scenicSpotId:24
        })
        .then((res) => {
          this.title = res.data?.rows[0].details;
        });
    },
    //头部信息
    getTopList() {
      api2.toplist({ scenicSpotId: this.scenicSpotId }).then((res) => {
        if (res.code == "200") {
          console.log(res.data);
          this.topData = res.data;
        }
      });
    },
  },
  computed: {
    viewVideo() {
      return this.$store.getters.getViewVideo;
    },
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
.scenicicon {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  img {
    width: 20px;
    height: 15px;
    position: absolute;
    left: 10px;
    top: 12px;
  }
}
.scenicidradio {
  font-size: 16px;
  margin: 10px 10px;
}
.oldRadio {
  .van-radio {
    .van-radio__label {
      & span {
        font-size: 20px !important;
      }
    }
  }
}
.mar {
  margin-left: 10px;
  margin-right: 10px;
}

.HomeBOX {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: PingFang SC;
  font-weight: 400;

  .HomeTop {
    .mar();
    margin-top: 10px;
    height: 31px;
    display: flex;
    justify-content: space-around;
    .van-notice-bar {
      font-size: 16px;
      width: 90%;
      border-radius: 31px;
    }
  }

  .thisMonth {
    margin-top: 15px;
    // height: 61px;
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;

    .width {
      width: 119px;
      height: 100%;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      color: #fff;
      font-size: 14px;
      padding-left: 7.5px;
      padding-right: 7.5px;
      border-radius: 2px;
      padding: 10px 8px;

      .top {
        // height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .xian {
        width: 31px;
        height: 1px;
        background: #fff;
      }

      .button {
        margin-top: 10px;
        width: 100%;

        .big {
          min-width: 30px;
          font-size: 16px;
          display: inline-block;
          text-align: center;
        }
      }
    }

    .thisMonthWaring {
      .width();
      background-image: url("../assets/img/home/waring.png");
    }

    .thisMonthTask {
      .width();
      background-image: url("../assets/img/home/taskback.png");
    }

    .thisMonthExamine {
      .width();
      background-image: url("../assets/img/home/examine.png");
    }
  }

  .important {
    .mar();
    margin-top: 13px;
    // height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: PingFang SC;
    font-weight: 400;

    .top {
      display: flex;
      justify-content: space-between;
      margin-left: 5px;
      margin-right: 13px;
      margin-bottom: 10px;

      .title {
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          width: 12.5px;
          height: 16px;
          background-image: url("../assets/img/home/important.png");
          background-size: 100% 100%;
          margin-top: 3px;
        }

        span {
          font-size: 17px;
          color: #363a44;
        }
      }

      .more {
        width: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: #686b73;
          font-size: 12px;
          font-weight: 500;
        }

        .back {
          margin-top: 3px;
          height: 10px;
          width: 6px;
          background-image: url("../assets/img/home/more.png");
          background-size: 100% 100%;
        }
      }
    }

    .importanImg {
      width: 100%;
      height: 182px;
      border-radius: 4px;
      position: relative;
    }
  }

  .option {
    margin-top: 35px;
    // height: 95px;
    padding: 10px 0;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0.3rem 0;
    .mar();
    border-radius: 4px;

    .box {
      // width: 70px;
      font-size: 16px;
      color: #363a44;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    .boxback {
      background-size: 100% 100%;
      width: 44px;
      height: 44px;
      text-align: center;
    }

    .scenicArea {
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/ditu.png");
      }
    }

    .shebeiIcon {
      position: relative;
      .iconwarn {
        position: absolute;
        top: 10px;
        left: 30px;
      }
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/shebei.png");
      }
    }

    .yinhuan {
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/yinhuan.png");
      }
    }

    .anquan {
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/anquan.png");
      }
    }
  }

  .jingquList {
    margin-top: 15px;
    padding-bottom: 70px;
    .mar();

    .top {
      height: 30px;
      display: flex;
      justify-content: space-between;
      .mar();
      .title {
        width: 93px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          height: 14px;
          margin-top: 3px;
          width: 18px;
          background-image: url("../assets/img/home/\jingquList.png");
          background-size: 100% 100%;
        }
        span {
          font-size: 18px !important;
          color: #363a44;
        }
      }

      .search {
        width: 158px;
        height: 29px;
        .van-field {
          height: 100%;
          border-radius: 15px;
          padding: 4px 16px !important;
        }
      }
    }

    .listox {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background: #fff;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;

      > .list {
        &:last-child {
          border-bottom: 0px;
        }

        // height: 93px;
        width: 324px;
        border-bottom: 1px solid #e8e9ec;
        margin-top: 15px;
        padding-bottom: 10px;
        .box {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .jingImg {
            width: 100px;
            height: 80px;
            border-radius: 4px;
          }

          .textBox {
            flex: 1;
            // height: 80px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10.5px;

            .title {
              // height: 15px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #363a44;
            }

            .shebei,
            .xiangqing,
            .sheshi {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #686b73;

              .number {
                color: #3f8cec;
              }
            }

            .xiangqing {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }

  .nav {
    width: 100%;
  }
}

.no-auth-video {
  img.video-img {
    display: block;
    width: 220px;
    // height: 82px;
    margin: 20px auto;
  }
  div {
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    color: #5bb8f8;
    line-height: 25px;
  }
}
.old {
  .HomeTop{
    .van-notice-bar {
      font-size: 20px;
    }
  }

  .thisMonth {
    .thisMonthWaring,
    .thisMonthTask,
    .thisMonthExamine {
      padding: 20px 20px;
      width: 90%;
    }
    // height: 150px;
    flex-direction: column;
    .top {
      // height: 40px !important;
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 15px;
    }
    .xian {
      height: 2px !important;
    }
    .button {
      margin-top: 15px;
      font-size: 26px;
      line-height: 26px;
      .big {
        font-size: 26px !important;
      }
    }
  }
  font-size: 20px !important;
  .important {
    .mar();
    margin-top: 10px;
    // height: 172px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: PingFang SC;
    font-weight: 400;

    .top {
      display: flex;
      justify-content: space-between;
      margin-left: 5px;
      margin-right: 13px;
      margin-bottom: 10px;

      .title {
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 15px;
          height: 20px;
          background-image: url("../assets/img/home/important.png");
          background-size: 100% 100%;
          margin-top: 3px;
          padding-right: 16px;
        }

        span {
          padding-left: 10px;
          display: inline-block;
          white-space: nowrap;
          font-size: 20px;
          color: #363a44;
          font-weight: bold;
        }
      }

      .more {
        width: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: #686b73;
          font-size: 20px;
          font-weight: bold;
        }

        .back {
          margin-top: 3px;
          height: 10px;
          width: 6px;
          background-image: url("../assets/img/home/more.png");
          background-size: 100% 100%;
        }
      }
    }

    .importanImg {
      width: 100%;
      height: 182px;
      border-radius: 4px;
      position: relative;
    }
  }
  .jingquList {
    .top {
      .title {
        .icon {
          height: 20px;
          width: 25px;
          padding-right: 25px;
        }
        & span {
          font-size: 20px !important;
          font-weight: bold;
          white-space: nowrap;
          padding-left: 10px;
          display: inline-block;
        }
        
      }
    }
    .listox {
      .list {
        .box {
          .textBox {
            .title {
              font-weight: bold;
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
  .scenicArea,
  .shebeiIcon,
  .yinhuan,
  .anquan {
    width: 120px !important;
    span {
      font-size: 20px !important;
    }
    .back {
      background-size: 100% 100%;
      width: 80px !important;
      height: 80px !important;
      text-align: center;
    }
    margin-bottom: 20px;
  }

  .option {
    span {
      font-size: 18px;
    }
  }

  .no-auth-video {
    div {
      font-size: 20px;
    }
  }
  .shebei,
  .xiangqing,
  .number {
    font-size: 18px !important;
  }
  .number {
    font-size: 18px !important;
  }
}

.noData {
  position: relative;
  transform: translate(0 0);
}

/deep/ .search {
  .van-field__left-icon {
    & img {
      padding-top: 6px;
    }
  }
  .van-field__body {
    & input {
      text-align: left !important;
      padding-bottom: 1px;
    }
  }
}

</style>
