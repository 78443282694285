<template>
  <div class="bigBox">
    <van-search v-model="value" show-action background="#66B2FF" placeholder="请输入搜索关键词">
      <template #action>
        <div class="shousuo" @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div id="myMap"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      value: ''
    };
  },
  mounted () {
    this.getInitMap();
  },
  methods: {
    onSearch () {
      console.log("1111");
    },
    getInitMap () {
      var map;
      var zoom = 12;
      map = new T.Map('myMap');
      //设置显示地图的中心点和级别
      map.centerAndZoom(new T.LngLat(116.40969, 39.89945), zoom);
    }
  }
};
</script>
<style lang="less" scoped>
.bigBox {
  width: 100%;
  height: 100%;
  #myMap {
    width: 100%;
    height: 100%;
  }
  .shousuo {
    color: #ffffff;
  }
}
</style>
