<template>
  <div class="xiangqingBox" :class="{ old: isElder }">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
      </div>
      <div class="xiangxiList">
        <van-field
          readonly
          label-class="title"
          label="分类"
          :value="item.classify || '无'"
        />
        <van-field
          readonly
          autosize
          type="textarea"
          label-class="title"
          label="风险隐患状况"
          :value="item.content || '无'"
        />
        <van-field readonly label-class="title" label="涉及人数" value="10" />
        <van-field
          readonly
          label-class="title"
          label="包案领导"
          :value="item.contacts || '无'"
        />
        <van-field readonly label-class="title" label="职务" value="经理" />
        <van-field
          readonly
          label-class="title"
          label="联系电话"
          :value="item.mobile || '无'"
        />
        <van-field
          readonly
          label-class="title"
          label="化解防控措施"
          :value="item.feedbackContent || '无'"
        />
        <van-field
          readonly
          label-class="title"
          label="化解时间"
          :value="item.releaseTime || '无'"
        />
        <van-field
          readonly
          label-class="title"
          label="是否化解"
          :value="item.releaseTime ? '是' : '否'"
        />
        <van-field
          readonly
          label-class="title"
          label="哪级交办"
          :value="item.assign || '无'"
        />
        <van-field readonly label-class="title" label="是否新增" value="是" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      list: [
        {
          title: "分类",
          con: "公共安全风险",
        },
        {
          title: "风险隐患状况",
          con: "xxxxxxxxxx",
        },
        {
          title: "涉及人数",
          con: "2人",
        },
        {
          title: "包案领导",
          con: "xxx",
        },
        {
          title: "职务",
          con: "xxx",
        },
        {
          title: "联系电话",
          con: "123-5678-21321",
        },
        {
          title: "化解防控措施",
          con: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        },
        {
          title: "化解时间",
          con: "2022年4月20日",
        },
        {
          title: "是否化解",
          con: "已化解",
        },
        {
          title: "哪级交办",
          con: "xxxxxxxxxxxx",
        },
        {
          title: "是否新增",
          con: "是",
        },
      ],
    };
  },
  created() {
    let data = this.$route.query.data;
    let index = this.$route.query.index;
    this.list[0].con = index;
    // this.list[1].con = data.classify;
    // this.list[2].con = data.content;
    // this.list[3].con = '';
    // this.list[4].con = data.nickName;
    // this.list[5].con = '总经理';
    // this.list[6].con = data.mobile;
    // this.list[7].con = data.feedbackContent;
    // this.list[8].con = '';
    // this.list[9].con = '';
    // this.list[10].con = data.assign;
    // this.list[11].con = '';
  },
  methods: {},
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");

.xiangqingBox {
  .jiben {
    .xiangxiList {
      margin-top: 10px;
      .van-field {
        font-size: 16px;
        // height: 54.5px;
        display: flex;
        align-items: center;
      }
      ::v-deep.title {
        font-family: PingFang SC;
        font-weight: 400;
        color: #b3b5b9;
        font-size: 16px;
      }
    }
  }
}

.old {
  .jiben span{
    font-size: 20px !important;
  }
  .van-field {
    font-size: 18px !important;
  }
  ::v-deep.title {
    font-size: 18px !important;
  }
}
</style>
