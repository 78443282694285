<template>
  <div class="zhidingBox">
    <van-form @submit="onSubmit">
      <div class="jiben" style="height: 270px; margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>基本信息</span>
          <div class="xian"></div>
        </div>
        <div class="list">
          <!-- <div class="title">任务标题</div> -->
          <div class="con">
            <van-field
              v-model="form.title"
              label="任务标题"
              placeholder="请输入标题"
              input-align="right"
            />
          </div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <!-- <div class="title">任务标题</div> -->
          <div class="con">
            <van-cell
              @click="jieshouPopup"
              v-model="form.companyId"
              title="接收单位"
              is-link
              class="van-ellipsis"
            >
              {{ this.jieshouName.toString() }}
            </van-cell>
            <van-popup
              v-model="jieshouShow"
              round
              position="bottom"
              :style="{ height: '65%' }"
              close-icon-position="bottom-left"
            >
              <div class="popupBox">
                <div class="title">请选择单位</div>
                <div class="xuanze" style="margin-top: 50px">
                  <van-checkbox-group v-model="form.companyIds">
                    <van-checkbox
                      v-for="(item, index) in jieshouList"
                      :key="index"
                      :name="item.id"
                      @click="danweixuanzhong(item.checked, index, item.name)"
                      label-position="left"
                    >
                      <span :class="item.checked ? 'lanse' : 'moren'">
                        {{ item.name }}</span
                      >
                      <template #icon="props">
                        <img
                          class="img-icon"
                          style="wdith: 21px; height: 21px"
                          :src="props.checked ? xuanze : weixuan"
                        />
                      </template>
                    </van-checkbox>
                  </van-checkbox-group>
                </div>
                <div class="buttomBox">
                  <div class="close" @click="close">取消</div>
                  <div class="sure" @click="sure">确认</div>
                </div>
              </div>
            </van-popup>
          </div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <!-- <div class="title">任务标题</div> -->
          <div class="con">
            <van-cell
              @click="lianshenPopup"
              v-model="form.aduitDept"
              title="查阅单位"
              class="van-ellipsis"
              is-link
              >{{ this.lianshenName.toString() }}</van-cell
            >
            <van-popup
              v-model="lianshenShow"
              round
              position="bottom"
              :style="{ height: '65%' }"
              close-icon-position="bottom-left"
            >
              <div class="popupBox">
                <div class="title">请选择单位</div>
                <div style="margin-top: 50px" class="xuanze">
                  <van-checkbox-group v-model="form.aduitDept">
                    <van-checkbox
                      v-for="(item, index) in lianshenList"
                      :key="index"
                      :name="item.id"
                      @click="bumenxuanzhong(item.checked, index, item.name)"
                      label-position="left"
                    >
                      <span :class="item.checked ? 'lanse' : 'moren'">
                        {{ item.name }}</span
                      >
                      <template #icon="props">
                        <img
                          class="img-icon"
                          style="wdith: 21px; height: 21px"
                          :src="props.checked ? xuanze : weixuan"
                        />
                      </template>
                    </van-checkbox>
                  </van-checkbox-group>
                </div>
                <div class="buttomBox">
                  <div class="close" @click="close1">取消</div>
                  <div class="sure" @click="sure1">确认</div>
                </div>
              </div>
            </van-popup>
          </div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="jiben" style="height: 313px; margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>任务内容</span>
        </div>
        <div class="list" style="height: 161px">
          <van-field
            v-model="form.comment"
            rows="1"
            type="textarea"
            style="height: 138px; background-color: #f6f7f8; border-radius: 6px"
            placeholder="请输入内容"
          />
          <div class="xian"></div>
        </div>
        <div class="list" style="41px">
          <van-cell
            style="color: #363a44; font-size: 18px"
            center
            title="任务截至时间"
            @click="Timeshow = true"
            :value="form.taskTermStr"
          >
            <template #right-icon>
              <img
                style="width: 18px; height: 18px; margin-left: 10px"
                :src="timeIcon"
                alt=""
              />
            </template>
          </van-cell>
          <van-calendar
            :max-date="maxDate"
            color="#1C91FF"
            v-model="Timeshow"
            @confirm="onConfirm"
            show-subtitle:true
            title="请选择截至时间"
          />
          <div class="xian"></div>
        </div>
      </div>
    </van-form>
    <van-button
      round
      type="info"
      block
      @click="submit"
      native-type="submit"
      style="margin-top: 28px; margin-bottom: 28px; font-size: 20px"
      >确认</van-button
    >
  </div>
</template>
<script>
import api from '@/api/task/index';
export default {
  data() {
    return {
      form: {
        comment: '', //任务内容
        taskTermStr: '', //任务截至时间
        title: '', //任务标题
        companyIds: [], //接收单位
        aduitDept: [] //联审部门
      },
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      jieshouList1: [
        { name: '垂云通天河', checked: false },
        { name: '浪石金滩', checked: false },
        { name: '江南古村落', checked: false },
        { name: '天子地', checked: false },
        { name: '严子陵钓台', checked: false }
      ],
      lianshenList1: [
        { name: '县文广旅体局', checked: false },
        { name: '县市场监管局', checked: false },
        { name: '县城管局', checked: false },
        { name: '县住建局', checked: false },
        { name: '镇(街道)', checked: false },
        { name: '市生态环境局桐庐分局', checked: false }
      ],
      checkedList: [
        '垂云通天河',
        '浪石金滩',
        '江南古村落',
        '天子地',
        '严子陵钓台'
      ],
      jieshouShow: false,
      lianshenShow: false,
      result: ['a', 'b'],
      timeIcon: require('../../assets/img/task/time.png'),
      xuanze: require('../../assets/img/task/xuanze.png'),
      weixuan: require('../../assets/img/task/weixuan.png'),
      Timeshow: false,
      jieshouChecked: [],
      lianshenXhecked: [],
      jieshouList: [],
      lianshenList: [],
      jieshouName: ['请选择单位'],
      lianshenName: ['请选择单位']
    };
  },
  mounted() {
    this.getdanweiList();
  },
  methods: {
    //获取单位列表
    // 1 接收单位 2 联审部门
    getdanweiList() {
      api.companyList({ type: 1 }).then((res) => {
        if (res.code == '200') {
          this.jieshouList = res.data.map((i) => {
            return { id: i.id, name: i.companyName, checked: false };
          });
          console.log(this.jieshouList);
        }
      });
      api.companyList({ type: 2 }).then((res) => {
        if (res.code == '200') {
          this.lianshenList = res.data.map((i) => {
            return { id: i.id, name: i.companyName, checked: false };
          });
          console.log(this.lianshenList);
        }
      });
    },
    jieshouPopup() {
      this.jieshouShow = true;
    },
    lianshenPopup() {
      this.lianshenShow = true;
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    jialing(s) {
      return s < 10 ? '0' + s : s;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${this.jialing(
        date.getMonth() + 1
      )}-${this.jialing(date.getDate())}`;
    },
    onConfirm(date) {
      this.Timeshow = false;
      this.form.taskTermStr = this.formatDate(date);
    },
    danweixuanzhong(i, index, name) {
      console.log(i);
      this.jieshouList[index].checked = !i;
      if (this.jieshouName[0] == '请选择单位') {
        this.jieshouName = [];
      }
      this.jieshouName.push(name);
    },
    bumenxuanzhong(i, index, name, j) {
      this.lianshenList[index].checked = !i;
      if (this.lianshenName[0] == '请选择单位') {
        this.lianshenName = [];
      }
      this.lianshenName.push(name);
    },
    clean() {
      this.lianshenName = [];
    },

    close() {
      this.jieshouShow = false;
    },
    sure() {
      this.jieshouShow = false;
    },
    close1() {
      this.lianshenShow = false;
    },
    sure1() {
      this.lianshenShow = false;
    },
    validate() {
      let valid = true;
      if (this.form.taskTermStr == '') {
        this.$toast('请选择截止时间');
        return (valid = false);
      }
      if (this.form.title == ' ') {
        this.$toast('请输入任务标题');
        return (valid = false);
      }
      if (this.form.companyIds.length == 0) {
        this.$toast('请选择接收单位');
        return (valid = false);
      }
      if (this.form.aduitDept.length == 0) {
        this.$toast('请选择联审部门');
      }
      if (this.form.comment == '') {
        this.$toast('请输入任务内容');
      }
      return valid;
    },
    //提交
    submit() {
      if (this.validate()) {
        api.addTask(this.form).then((res) => {
          console.log(typeof this.form.companyIds);
          if (res.code == '200') {
            this.$toast({
              message: '制定完成'
            });
            setTimeout(() => {
              this.$router.push({
                name: 'Task'
              });
            }, 1000);
          } else {
            this.$toast({
              message: res.message
            });
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import url('../../assets/less/conment.less');
.lanse {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #1c91ff;
}
.moren {
  // font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #686973;
}
.zhidingBox {
  margin: 10px 10px 0px 10px;
}
.jiben {
  &:nth-child(2) {
    .list {
      .shangxia();
    }
  }
  .jiben();
  .list {
    height: 41px;
    align-items: flex-end;
    .con {
      width: 100%;
      .van-cell {
        color: #363a44 !important;
        font-size: 18px;
        padding: 10px 0px;
      }
      .van-cell__value {
        color: #b3b5b9;
      }
      .popupBox {
        padding: 48px 34px 38px 34px;
        .title {
          align-self: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #363a44;
        }
        .shangxia();
        .xuanze {
          .van-checkbox {
            justify-content: space-between;
            &:first-child {
              margin: 0 0 0 0;
              font-size: 20px;
            }
            margin-top: 27px;
          }
        }
        .buttomBox {
          width: 100%;
          height: 54px;
          .liangbian();
          align-items: center;
          margin-top: 103px;
          .close {
            height: 54px;
            width: 39%;
            border-radius: 27px;
            border: 1px solid #0085ff;
            color: #0085ff;
            font-size: 20px;
            text-align: center;
            line-height: 54px;
          }
          .sure {
            width: 39%;
            height: 54px;
            border-radius: 27px;
            text-align: center;
            line-height: 54px;
            background: #1c91ff;
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
