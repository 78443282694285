<template>
  <div class="recordinfor" :class="{ old: isElder }">
    <div class="recordhead">
      <div class="cardbox">
        <div class="handText1">已评分</div>
        <div class="handText2">感谢您的评分，欢迎下次再来！</div>
        <van-divider />
        <div style="height: 30px">
          <div
            style="
              display: inline-block;
              color: #b3b5b9;
              font-size: 14px;
              width: 80px;
            "
            :style="{ fontSize: isElder ? '20px' : '16px' }"
          >
            评分人
          </div>
          <span
            style="color: #363a44; font-size: 14px"
            :style="{ fontSize: isElder ? '20px' : '16px' }"
          >
            {{ scoreinfor.userName || "--" }}</span
          >
        </div>
        <div style="height: 30px">
          <div
            style="
              display: inline-block;
              color: #b3b5b9;
              font-size: 14px;
              width: 80px;
            "
            :style="{ fontSize: isElder ? '20px' : '16px' }"
          >
            评分日期
          </div>
          <span
            style="color: #363a44; font-size: 14px"
            :style="{ fontSize: isElder ? '20px' : '16px' }"
          >
            {{ scoreinfor.scoreTime | datetimejs }}</span
          >
        </div>
      </div>
    </div>

    <div class="bigBox">
      <div class="cardbox">
        <div class="youkecontern">
          <div v-for="(item, index) in listdata" :key="index">
            <p class="youkewen">{{ item.title }}</p>
            <div class="radiogroup">
              <van-radio-group
                v-model="item.state"
                direction="horizontal"
                disabled
              >
                <van-radio style="margin: 0 50px" name="1">是</van-radio>
                <van-radio style="margin: 0 50px" name="0">否</van-radio>
              </van-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 50px">
      <Nav></Nav>
    </div>
  </div>
</template>
<script>
import Nav from "../../components/nav/zlbNav.vue";
import request from "@/api/index";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      activerecord: "建议反馈",
      weizhi: require("../../assets/img/zlb/dingwei.png"),
      shifou: require("../../assets/img/zlb/shifou.png"),
      checked: false,
      listdata: [
        {
          title: "1.是否在醒目处对外公布日最大承载量和瞬时承载量",
        },
        {
          title:
            " 2.是否景区内无破损、腐烂建筑物和构筑物，公共休息设施完好，能正常使用。",
        },
        {
          title:
            " 3.是否食品卫生符合国家规定，餐饮服务配备消毒设施，不得使用对环境造成污染的一次性餐具。",
        },
        {
          title:
            " 4.是否为特定人群（老年人、儿童、残疾人等）配备旅游工具、用品，提供特殊服务。",
        },
        {
          title:
            "5.是否消防、防盗、救护等设备要求齐全、完好、有效，交通、机电、游览、娱乐等设备完好，运行正常且无安全隐患。",
        },
        {
          title:
            "6.是否景区内引导标识、游览（参观）路线或航道清晰、布局合理、顺畅，可观赏面大。",
        },
        {
          title: "7.是否建立紧急救援机制，设立医务室，并配备专兼职医务人员。",
        },
        {
          title:
            "8.是否危险地段标志明显，防护设施齐备、有效，高峰期有专人看守。",
        },
      ],
    };
  },
  mounted() {
    this.getscoreinfo();
  },
  computed: {
    id() {
      const id = this.$route.query.id || 0;
      return id;
    },

    isElder() {
      return this.$store.getters.getIsElder;
    },
    scoreinfor() {
      return this.$route.query.scoreinfor;
    },
  },
  methods: {
    getscoreinfo() {
      // const id = this.id;
      // request
      //   .post2("suggestionScoreViewList", {
      //     id: id,
      //   })
      //   .then((res) => {
      //     if (res.code == "200" && res.success) {
      //       // this.$toast(res.message);
      //       this.scoreinfor = res.data;
      this.listdata = this.listdata.map((item, index) => {
        const scoreinfordata = this.scoreinfor.scoreList.split(",");
        item.state = scoreinfordata[index];
        return item;
      });
      // console.log(this.listdata, "this.listdata");
      // }
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.recordinfor {
  width: 100%;
  .recordhead {
    .cardbox {
      background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
      border-radius: 6px;
      padding: 20px 20px;
      margin: 20px 10px;
      box-shadow: 0px -10px 1px #319cff;
      .handText1 {
        color: #319cff;
        font-size: 16px;
        font-weight: bold;
      }
      .handText2 {
        color: #686b73;
        font-size: 14px;
        margin: 5px 0 0 0;
      }
    }
  }
}

.bigBox {
  .cardbox {
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    padding: 20px 20px;
    margin: 20px 10px;
  }
  ::v-deep .radiogroup .van-radio-group--horizontal {
    justify-content: center;
  }
  ::v-deep .van-radio__label {
    font-size: 16px;
    font-weight: 400;
    color: #363a44;
  }
  .youkecontern {
    margin: 20px 0px;
  }
  .youkewen {
    font-size: 16px;
    font-weight: 400;
    color: #363a44;
    line-height: 24px;
  }
  .imgtupian {
    margin: 0 15px;
  }
  .img-btn {
    color: #ffffff;
    cursor: pointer;
    position: relative;
    left: 65px;
    top: 25px;
    z-index: 100;
    font-size: 18px;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .tupianshow {
    width: 80px;
    height: 80px;
    margin: 0 10px;
    position: relative;
  }
  .selectBox {
    padding: 17px 20px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .jingqu {
      width: 40%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .jiao {
        height: 6px;
        width: 8px;
        align-self: flex-end;
        background-size: 100% 100%;
        background-image: url("../../assets/img/zlb/youjiao.png");
      }
    }
    .jingxuan {
      display: flex;
      justify-content: center;
    }
    .sheshi {
      width: 40%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .jiao {
        height: 6px;
        width: 8px;
        background-image: url("../../assets/img/zlb/youjiao.png");
        background-size: 100% 100%;
        align-self: flex-end;
      }
    }
    .map {
      height: 22px;
      width: 34px;
      display: flex;
      border-left: 1px solid #c0c0c0;
      justify-content: space-around;

      img {
        width: 19px;
        height: 19px;
        align-self: flex-end;
      }
    }
  }
  .liuyanBox {
    margin-top: 12px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .img {
      padding: 68px 16px 16px 16px;
    }
  }
  .jingquBox {
    margin-top: 12px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    height: 78px;
    display: flex;

    .van-cell {
      align-items: center;
      .van-cell__left-icon {
        margin-top: 6px;
      }
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 26px;
    }
    .bixuan {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      line-height: 26px;
      margin-right: 10px;
    }
    .jiantou {
      height: 16px;
      width: 9px;
    }
  }
  .shifouBox {
    margin-top: 12px;
    height: 57px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    display: flex;
    .van-cell {
      align-items: center;
      .van-cell__left-icon {
        margin-top: 6px;
      }
    }
    .kejian {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      line-height: 26px;
      margin-right: 6px;
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 26px;
    }
  }
  .van-button {
    margin-top: 12px;
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  .popupBox {
    padding: 48px 34px 38px 34px;
    .title {
      align-self: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #363a44;
    }
    .xuanze {
      .van-checkbox {
        justify-content: space-between;
        &:first-child {
          margin: 0 0 0 0;
          font-size: 20px;
        }
        margin-top: 27px;
        font-size: 20px;
        height: 25px;
      }
    }
  }
  .buttomBox {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .close {
      padding: 8px 30px;
      border-radius: 27px;
      border: 1px solid #0085ff;
      color: #0085ff;
      font-size: 20px;
      text-align: center;
      margin: 0 10px;
    }
    .sure {
      padding: 8px 30px;
      border-radius: 27px;
      text-align: center;
      background: #1c91ff;
      color: #fff;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .lanse {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1c91ff;
  }
  .moren {
    // font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #686973;
  }
  .van-radio-group {
    .title {
      height: 50px;
      font-size: 24px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .van-radio {
      font-size: 24px;

      .van-radio__icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.old {
  .cardbox {
    font-size: 22px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .handText1 {
      font-size: 22px !important;
    }
    .handText2 {
      font-size: 22px !important;
    }
  }
  .time,
  .describe,
  .nameless,
  .pingfentime,
  .branchtitle {
    font-size: 20px !important;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
  }
  /deep/ .van-tab {
    font-size: 22px;
  }
  /deep/ .bigBox .selectBox span {
    font-size: 22px;
  }
  /deep/ .van-cell__value {
    font-size: 20px;
  }
  /deep/ .bigBox .youkewen {
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    font-size: 20px;
  }
  /deep/ .van-radio__label {
    font-size: 20px;
  }
  /deep/ .van-tabbar-item__text {
    font-size: 20px;
  }
}
</style>
