<template>
  <div id="app">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    // Nav
  },
  data() {
    return {
      property: "value",
    };
  },
  created() {
    console.log(process.env.VUE_APP_TYPE);
  },
};
</script>
<style lang="less">
#app {
  width: 375px;
  height: 100vh;
  margin: 0 auto;
}
body {
  margin: 0;
  padding: 0;
}
.content {
  // height: calc(100% - 50px);
  height: 100%;
  overflow-y: scroll;
  background: #f5f5f5;
}
.date {
  .van-calendar {
    .van-calendar__body {
      .van-calendar__month {
        .van-calendar__days {
          .van-calendar__day {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
.old {
  .van-field__control {
    font-size: 18px;
  }
  .van-field__right-icon,
  .van-button__text,
  .van-calendar__header-title,
  .van-calendar__header-subtitle,
  .van-calendar__month-title,
  .van-calendar__day,
  .van-list__finished-text {
    font-size: 18px;
  }
  .box div,
  .wu .word {
    font-size: 18px !important;
  }
  .search {
    .van-button.van-button--default.van-button--normal {
      min-width: 75px;
      .van-button__content {
        width: 60px;
      }
    }
  }
  .word {
    font-size: 18px;
  }
}
</style>
