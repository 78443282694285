<template>
  <div class="xiangqingBox" :class="{ old: isElder }">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>反馈内容</span>
      </div>
      <div class="list" style="height: 166px">
        <van-field v-model="formData.feedback" rows="5" autosize type="textarea" maxlength="100" placeholder="请输入内容" show-word-limit />
        <div class="xian"></div>
      </div>
    </div>

    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>图片上传</span><span class="illustrate">(至少2张照片)</span>
      </div>
      <div class="list1" style="">
        <van-uploader accept="image/*" :after-read="afterRead" v-model="imgUrl" :max-count="2">
          <div class="Box">
            <div class="round">
              <img :src="jia" alt="" />
            </div>
          </div>
        </van-uploader>
        <div class="titleSpecial">* 支持jpg,png的图片格式,图片无大小限制</div>
      </div>
    </div>

    <van-button round size="large" @click="dealWithNow" type="info">提交</van-button>
  </div>
</template>
<script>
import api from '@/api/waring/index';
import OSS from 'ali-oss';
export default {
  data () {
    return {
      imgUrl: [],
      form: { warningHandleId: null },
      Data: {},
      formData: {
        feedback: '',
        id: null,
        warningHandleImages: []
      },
      jia: require('../../assets/img/waring/jia.png')
    };
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    }
  },
  created () {
    this.form.warningHandleId = this.$route.query.warningHandleId;
    this.formData.id = this.$route.query.warningHandleId;
  },
  mounted () { },
  methods: {
    //上传图片到服务器
    afterRead (file) {
      let client = new OSS({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI5tNnSKQc9pb1dgcGBx8B',
        accessKeySecret: '7lhMacrNSHb2YZjho1F3PWZGorfzoD',
        bucket: 'tongluwenlv'
      });

      const fileN = file.content;
      const fileU = file.file.name;
      const base64 = fileN.split(',').pop();
      const fileType = fileN.split(';').shift().split(':').pop();
      const blob = this.toBlob(base64, fileType);
      this.readFile(blob).then((res) => {
        const fileExtension = fileU.substring(fileU.lastIndexOf('.'));
        const objectKey = 'gt/' + this.uuid() + fileExtension;
        const buffer = new OSS.Buffer(res.target.result);
        client
          .put(objectKey, buffer)
          .then((result) => {
            console.log(result);
            this.formData.warningHandleImages.push(result.url);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    readFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          resolve(event);
        };
      });
    },
    toBlob (urlData, fileType) {
      let bytes = window.atob(urlData);
      let n = bytes.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }
      return new Blob([u8arr], { type: fileType });
    },

    uuid () {
      //生成随机数

      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';

      var uuid = s.join('');
      return uuid;
    },

    //提交
    dealWithNow () {
      if (this.formData.feedback == '') {
        this.$toast('请输入反馈内容');
        return;
      }
      if (this.imgUrl.length < 2) {
        this.$toast('最少上传两张图片');
        return;
      } else {
        api.releaseWarningHandle(this.formData).then((res) => {
          if (res.code == '200') {
            this.$toast('提交成功');
            setTimeout(() => {
              this.$router.push({
                name: 'waringList'
              });
            }, 500);
          } else {
            this.$toast(res.message);
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
@import url("../../assets/less/vantChange.less");
.xiangqingBox {
  .jiben {
    margin-top: 20px;
    .infomation {
      align-items: center;
      .illustrate {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
    }

    &:nth-child(2) {
      .list {
        .shangxia();
      }
    }
    .jiben();
    .list1 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 16px 16px;
      background: #fff;
      margin: 0px 16px;
      border-radius: 10px;
      margin-top: 15px;
      .Box {
        width: 104px;
        height: 104px;
        background: #e9f3fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .round {
          width: 38px;
          height: 38px;
          background: #0c90ff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
    .list {
      align-items: flex-end;
      margin: 0px 16px;
      border-radius: 10px;
      margin-top: 15px;

      .van-cell {
        font-size: 18px;
        color: #686b73;
        background: transparent;
      }
      .con {
        width: 100%;
        .van-cell {
          color: #363a44 !important;
          font-size: 18px;
          padding: 10px 0px;
        }
        .van-cell__value {
          color: #b3b5b9;
        }
        .popupBox {
          padding: 48px 34px 38px 34px;
          .title {
            align-self: center;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #363a44;
          }
          .shangxia();
          .xuanze {
            .van-checkbox {
              justify-content: space-between;
              &:first-child {
                margin: 0 0 0 0;
              }
              margin-top: 27px;
            }
          }
          .buttomBox {
            width: 100%;
            height: 54px;
            .liangbian();
            align-items: center;
            margin-top: 103px;
            .close {
              height: 54px;
              width: 39%;
              border-radius: 27px;
              border: 1px solid #0085ff;
              color: #0085ff;
              font-size: 20px;
              text-align: center;
              line-height: 54px;
            }
            .sure {
              width: 39%;
              height: 54px;
              border-radius: 27px;
              text-align: center;
              line-height: 54px;
              background: #1c91ff;
              color: #fff;
              font-size: 20px;
            }
          }
        }
      }
      .uploader {
        width: 104px;
        height: 104px;
        background: #e9f3fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .round {
          width: 38px;
          height: 38px;
          background: #0c90ff;
          border-radius: 50%;
        }
      }
    }
  }
}

/deep/.van-button {
  position: fixed;
  bottom: 27px;
  left: 0px;
  margin: 0px 17px;
  font-size: 20px;
  width: 90%;
  justify-content: center;
}
/deep/.van-field__word-limit {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0c90ff;
}
/deep/.van-uploader__preview {
  padding: 0 0px 0 0;
  margin: 0 15px 0px 0px;
}
/deep/.van-uploader__preview-image {
  width: 104px;
  height: 104px;
}
.titleSpecial{
  color:red;
  font-size:14px;
  left: 25px;
  margin-top: 10px;
 }
.old {
  .titleSpecial{
    font-size:18px;
  }
  .jiben {
    .infomation span{
      font-size: 22px !important;
    }
  }
  ::v-deep.van-field__label {
    font-size: 20px !important;
    width: 81px;
  }
  ::v-deep.van-field__control {
    font-size: 20px !important;
  }
}
</style>
