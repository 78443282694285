<template>
  <div class="weizi" :class="{ old: isElder }">
    <van-uploader
      v-model="fileList"
      :after-read="afterRead"
      multiple
      :max-count="8"
      :preview-image="false"
    />
    <div class="titleSpecial">* 支持jpg,png的图片格式,图片无大小限制</div>
  </div>
</template>
<script>
import Client from "@/utils/ossClient";
export default {
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  props: {
    limit: {
      type: Number,
      default: null,
    },
    path: {
      type: String,
      default: "",
    }, // 图片路径，默认根目录
    limitSize: {
      type: Number,
    },
    accept: {
      type: String,
    },
  },
  methods: {
    async afterRead(files) {
      if (files.length) {
        for (let file of files) {
          await this.upLoadFile(file);
        }
      } else {
        this.upLoadFile(files);
      }
      for (let file of this.fileList) {
        // 此时可以自行将文件上传至服务器
        console.log(file);
      }
    },
    delteFileList(file, detail) {
      let index;
      this.fileList.forEach((files, indext)=> {
        if (files == file[0])
        index = indext
      })
      if (isNaN(index)) {
        this.fileList.splice(index, 1);
      }
      // console.log('this.fileList', this.fileList)

    },
    async upLoadFile(file) {
      let getSize = this.getLimitSize(file.file.size);
      let getType = this.getLimitType(file.file.type);
        // console.log(getSize, getType);
        if (!getSize) {
          this.$message.warning("图片超出限制范围！");
          return;
        }
        if (!getType) {
          this.$message.warning("图片格式不符合要求");
          return;
        }
        let client = Client();
        // console.log('file.file.type', file)
        let suffixName = file.file.type.split("/")[1];
        let fname = `${new Date().getTime()} + ${suffixName}`; //自定义文件名
        try {
          // object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，
          // 实现将文件上传至当前Bucket或Bucket下的指定目录。

          // console.log(this.path + fname, file)
          const result = await client.put(this.path + fname, file.file, {
            // progress,
          });
          console.log(result);
          this.$emit("change", result.res.requestUrls, file);
        } catch (e) {
          console.log(e);
        }
    },
    getLimitSize(size) {
      if (this.limitSize) {
        if (this.limitSize > size / 1024 / 1024) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getLimitType(type) {
      console.log(this.accept);
      if (this.accept) {
        if (this.accept.indexOf(type.split("/")[1]) > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
 .titleSpecial{
  color:red;
  font-size:14px;
  position: absolute;
  left: 25px;
 }
 .old{
  padding-bottom: 10px;
  .titleSpecial{
    font-size:18px;
  }
 }
</style>
