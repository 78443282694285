import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import zlbHome from '../views/zhelibanPage/homePage.vue' //浙里办首页
import zlbYou from '../views/zhelibanPage/youke.vue' //浙里办游客p
import zlbUploade from '../views/zhelibanPage/record.vue' //浙里办上传记录
import zlbInfor from '../views/zhelibanPage/recordinfor.vue' //浙里办上传记录详情
import zlbMap from '../views/zhelibanPage/map.vue' //浙里办游客选择位置地图

import Home from '../views/Home.vue'
import Nav from '../components/nav/nav.vue'
import Patrol from '../views/Patrol.vue'
import Task from '../views/Task.vue'
import My from '../views/My.vue'
//巡查详情
import patrolForm from '../views/patrol/Form.vue'
//开始巡查
import startInspection from '../views/patrol/startInspection.vue'

import zhidingForm from '../views/zhiding/Form.vue'
// import zhidingForm from '../views/zhiding/text.vue';
import zhongdianForm from '../views/important/Form.vue'
import safetyForm from '../views/safety/Form.vue'
//任务基本信息页面
import taskxiangqing from '../views/renwu/xiangqing.vue'
//审核任务通过不通过页面
import renwushenhe from '../views/renwu/shenhePage.vue'
//已经审核过的任务看审核详情页面
import renwuyishenhe from '../views/renwu/chakanPage.vue'
//员工任务详情页面
import dealWithNow from '../views/renwu/dealWaithNow.vue'

import yinhuanForm from '../views/yinhuan/Form.vue'
import yinhuanxiangxi from '../views/yinhuan/xiangqing.vue'
import Homexiangxi from '../views/Homexiangxi/Form.vue'
import xunchashenhe from '../views/patrol/shenheForm.vue'
//地图
// import map from '../views/map/index.vue'
//地图详情
// import mapxiangxi from '../views/map/xiangxi.vue'
//首页景区二级列表跳转后的预警列表页
import yujinglist from '../views/Homexiangxi/list.vue'
//我的 巡查记录
import myPatol from '../views/my/myPatol.vue'
//我的 消息
import message from '../views/my/message.vue'
//我的消息详情
import messagexiangxi from '../views/my/messagexiangxi.vue'
//我的 任务记录
import myTaskxiangxi from '../views/my/myTaskxiangxi.vue'
//我的 任务记录
import myTask from '../views/my/myTask.vue'
//我的 景区信息
import jingqu from '../views/my/jingquinformation.vue'
//我的 预警记录
import myWaring from '../views/my/myWaring.vue'
//首页设备预警列表
import waringList from '../views/waring/list.vue'
//预警设备详情
import waringForm from '../views/waring/xiangqing.vue'
//预警审核页面
import waringshenhe from '../views/waring/waringshen.vue'
//预警审核已通过页面
import waringchakan from '../views/waring/waringchakan.vue'
//预警处理页面
import dealWith from '../views/waring/dealWith.vue'

import { zwlog } from '@/utils/zlog.js'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  // {
  //   path: '',
  //   redirect: '/zhelibanPage/zlbHome'
  // },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/zhelibanPage/zlbHome',
    name: 'zlbHome',
    component: zlbHome,
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '景区巡检'
    },
    component: Home,
  },
  {
    path: '/nav',
    name: 'Nav',
    meta: {
      title: '景区巡检'
    },
    component: Nav,
  },
  {
    path: '/patrol',
    name: 'Patrol',
    meta: {
      title: '景区巡检'
    },
    component: Patrol,
  },
  {
    path: '/task',
    name: 'Task',
    meta: {
      title: '景区巡检'
    },
    component: Task,
  },
  {
    path: '/my',
    name: 'My',
    component: My,
  },
  {
    path: '/patrol/from',
    name: 'patrolForm',
    meta: {
      title: '巡查'
    },
    component: patrolForm,
  },
  {
    path: '/patrol/startInspection',
    name: 'startInspection',
    component: startInspection,
  },
  {
    path: '/zhiding/from',
    name: 'zhidingForm',
    component: zhidingForm,
  },
  {
    path: '/important/from',
    name: 'zhongdianForm',
    meta: {
      title: '重点关注'
    },
    component: zhongdianForm,
  },
  {
    path: '/safety/from',
    name: 'safetyForm',
    meta: {
      title: '安全评估'
    },
    component: safetyForm,
  },
  {
    path: '/renwu/xiangqing',
    name: 'taskxiangqing',
    component: taskxiangqing,
  },
  {
    path: '/renwu/dealWaithNo',
    name: 'dealWithNow',
    component: dealWithNow,
  },
  {
    path: '/yinhuan/form',
    name: 'yinhuanForm',
    meta: {
      title: '排查预警'
    },
    component: yinhuanForm,
  },
  {
    path: '/yinhuan/xiangqing',
    name: 'yinhuanxiangxi',
    component: yinhuanxiangxi,
  },
  {
    path: '/Home/xiangxi',
    name: 'Homexiangxi',
    component: Homexiangxi,
  },
  {
    path: '/patrol/shenhform',
    name: 'xunchashenhe',
    meta: {
      title: '巡查详情'
    },
    component: xunchashenhe,
  },
  // {
  //   path: '/map/index',
  //   name: 'map',
  //   component: map,
  // },
  {
    path: '/industryMap',
    name: 'industryMap',
    meta: {
      title: '景区地图'
    },
    component: () => import('@/views/industryMap/scenesMap.vue'),
  },
  {
    path: '/industryMap/detail',
    name: 'sceneDetail',
    component: () => import('@/views/industryMap/sceneDetail.vue'),
  },
  {
    path: '/oneMap',
    name: 'oneMap',
    component: () => import('@/views/industryMap/oneMap.vue'),
  },
  {
    path: '/oneMap/detail',
    name: 'facilityDetail',
    component: () => import('@/views/industryMap/facilityDetail.vue'),
  },
  {
    path: '/twoMap',
    name: 'twoMap',
    component: () => import('@/views/industryMap/twoMap.vue'),
  },
  // {
  //   path: '/map/xiangxi',
  //   name: 'mapxiangxi',
  //   component: mapxiangxi,
  // },
  {
    path: '/Homexiangxi/list',
    name: 'yujinglist',
    meta: {
      title: '设备详情'
    },
    component: yujinglist,
  },
  {
    path: '/my/myPatol',
    name: 'myPatol',
    component: myPatol,
  },
  {
    path: '/my/message',
    name: 'message',
    component: message,
  },
  {
    path: '/my/myTask',
    name: 'myTask',
    component: myTask,
  },
  {
    path: '/my/jingquinformation',
    name: 'jingqu',
    component: jingqu,
  },
  {
    path: '/my/messagexiangxi',
    name: 'messagexiangxi',
    component: messagexiangxi,
  },
  {
    path: '/my/myTaskxiangxi',
    name: 'myTaskxiangxi',
    component: myTaskxiangxi,
  },

  {
    path: '/my/myWaring',
    name: 'myWaring',
    component: myWaring,
  },
  {
    path: '/renwu/shnhePage',
    name: 'renwushenhe',
    meta: {
      title: '审核'
    },
    component: renwushenhe,
  },
  {
    path: '/renwu/renwuyishenhe',
    name: 'renwuyishenhe',
    meta: {
      title: '审核详情'
    },
    component: renwuyishenhe,
  },

  {
    path: '/waring/list',
    name: 'waringList',
    meta: {
      title: '设备预警'
    },
    component: waringList,
  },
  {
    path: '/waring/xiangqing',
    name: 'waringForm',
    component: waringForm,
  },
  {
    path: '/waring/waringshen',
    name: 'waringshenhe',
    component: waringshenhe,
  },
  {
    path: '/waring/waringchakan',
    name: 'waringchakan',
    component: waringchakan,
  },
  {
    path: '/waring/dealWith',
    name: 'dealWith',
    component: dealWith,
  },
  {
    path: '/zhelibanPage/homePage',
    name: 'zlbHome',
    meta: {
      title: '游客参与'
    },
    component: zlbHome,
  },
  {
    path: '/zhelibanPage/record',
    name: 'zlbUploade',
    meta: {
      title: '游客参与'
    },
    component: zlbUploade,
  },
  {
    path: '/zhelibanPage/record/recordinfor',
    name: 'zlbInfor',
    meta: {
      title: '游客参与'
    },
    component: zlbInfor,
  },
  {
    path: '/zhelibanPage/youke',
    name: 'zlbYou',
    meta: {
      title: '游客参与'
    },
    component: zlbYou,
  },
  {
    path: '/zhelibanPage/map',
    name: 'zlbMap',
    component: zlbMap,
  },
]
// router.beforeEach((to, from, next) => {
//   let token = sessionStorage.getItem('tongluUser');
//   if (token) {
//     if (to.path === '/') {
//       next({ path: '/location' });
//     } else {
//       next();
//     }
//   } else {
//     if (to.path !== '/') {
//       next({ path: '/' });
//     } else {
//       next();
//     }
//   }

// })
const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  if (from.path == "/zhelibanPage/zlbHome" && to.path == "/") {
    console.log('close');
    ZWJSBridge.close();
    return;
  }
  next();
})
router.afterEach((to, from) => {
  document.title = to.meta.title || '文旅新业态'
  ZWJSBridge.setTitle({
    title: document.title
  })
  if (to.path != '/') {
    zwlog.sendPV(to);
  }

})
export default router
