<template>
  <div class="formBox" :class="{ old: isElder }">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>反馈内容</span>
      </div>
      <van-field v-model="from.feedbackComment" rows="5" autosize type="textarea" maxlength="100" placeholder="请输入内容" show-word-limit />
    </div>

    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>图片上传<span class="small">(请上传至少2张图片)</span></span>
      </div>
      <div class="img-box">
        <img-upload v-model="from.taskImages" capture="camera"></img-upload>
      </div>
    </div>

    <div class="bottomBox">
      <van-button round size="large" @click="dealWithNow" type="info">提交</van-button>
    </div>
  </div>
</template>
<script>
import api from "@/api/task/index";
import imgUpload from '../../components/ossUpload/imgUpload.vue';
export default {
  name: "dealWithNow",
  data () {
    return {
      from: {
        id: "",
        feedbackComment: "",
        taskImages: [],
      },
    };
  },
  created () {
    this.from.id = this.$route.query.id;
  },
  mounted () { },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  methods: {
    //提交任务
    dealWithNow () {
      console.log(this.from);
      if (this.from.feedbackComment == "") {
        this.$toast("请输入反馈内容");
        return;
      }
      if (this.from.taskImages.length < 2) {
        this.$toast("最少上传两张图片");
        return;
      }
      api.releaseTask(this.from).then((res) => {
        if (res.code == "200") {
          this.$toast("提交成功");
          setTimeout(() => {
            this.$router.push({
              name: "Task",
              query: {
                examineState: 1,
              },
            });
          }, 500);
        } else {
          this.$toast(res.message);
        }
      });

    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");
.formBox {
  padding: 0px 15px;
  .jiben {
    // margin-top: 21px;
    .infomation {
      align-items: center;
      padding-left: 0px;
      span {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 5px;
      }
      .small {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .kuai {
        height: 16px;
      }
    }
  }
  .projectBox {
    padding: 20px 16px 20px 16px;
    margin-top: 15px;
    background: #fff;
    .smallBox {
      .fankui {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        border-bottom: 1px solid #e8e9ec;
        margin-top: 20px;
        &:first-child {
          margin-top: 0px;
        }
        &:last-child {
          border-bottom: 0px;
        }
        .fankuixiangqing {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
          line-height: 23px;
        }
        .van-radio-group--horizontal {
          justify-content: space-around;
          margin: 51px 0px;
        }
        .van-radio--horizontal {
        }
        .img-icon {
          height: 16px;
        }
        .fankuiImg {
          display: flex;
          flex-direction: column;
          .word {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #363a44;
          }
          .illustrate {
            margin-top: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #b3b5b9;
          }
          img {
            width: 87px;
            height: 75px;
            border-radius: 5px;
            border: none;
          }
          .Box {
            width: 104px;
            height: 104px;
            background: #e9f3fe;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .round {
              width: 38px;
              height: 38px;
              background: #0c90ff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 19px;
                height: 19px;
              }
            }
          }
        }
        .abnormal {
          margin-bottom: 21px;
          span {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #363a44;
          }
        }
        .measure {
          span {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #363a44;
          }
        }
      }
    }
    .result {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #363a44;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e8e9ec;
      padding-bottom: 21px;
      .van-radio-group--horizontal {
        justify-content: space-around;
        width: 176px;
      }
      .img-icon {
        height: 16px;
      }
    }
    .Content {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #363a44;
      margin: 21px 0;
      border-bottom: 0px;
    }
    .fankuiImg {
      display: flex;
      flex-direction: column;
      .word {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #363a44;
      }
      .illustrate {
        margin-top: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #b3b5b9;
      }
      img {
        width: 87px;
        height: 75px;
        border-radius: 5px;
        border: none;
      }
      .Box {
        width: 104px;
        height: 104px;
        background: #e9f3fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .round {
          width: 38px;
          height: 38px;
          background: #0c90ff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
    .beTime {
      background: transparent;
      padding: 0 0;
      img {
        width: 18px;
        height: 17px;
        margin-right: 2px;
      }
      span {
        margin-right: 10px;
      }
    }
  }
  .bottomBox {
    width: 100%;
    height: 85px;
    position: fixed;
    bottom: 0px;
    background: #fff;
    margin-left: -15px;
  }
}
/deep/.van-button {
  left: 0px;
  margin: 10px 17px;
  font-size: 20px;
  width: 90%;
  justify-content: center;
}
/deep/ .van-radio__label {
  font-size: 17px;
  margin-left: 10px;
}
/deep/.van-radio__icon {
  width: 17px;
  height: 17px;
}
/deep/.van-uploader__preview-image {
  width: 104px;
  height: 104px;
}
/deep/.van-uploader__preview {
  padding: 0 0px 0 0;
  margin: 0 15px 0px 0px;
}
/deep/.van-uploader {
  margin-top: 10px;
  margin-bottom: 21px;
}
/deep/.van-field__word-limit {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0c90ff;
}
/deep/ .van-cell {
  margin-top: 15px;
  background: #fff;
  border-radius: 9px;
  padding: 16px 16px;
  font-size: 17px;
}
/deep/.van-cell::after {
  border-bottom: 0px;
}
.img-box {
  padding: 0 10px;
  background: #fff;
  margin-top: 15px;
  /deep/.img-item {
    margin-right: 2px;
    margin-bottom: 8px;
  }
}
.old {
  .jiben {
    .infomation span{
      font-size: 22px !important;
    }
  }
  ::v-deep.van-field__label {
    font-size: 20px !important;
    width: 81px;
  }
  ::v-deep.van-field__control {
    font-size: 20px !important;
  }
}
</style>
