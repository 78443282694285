import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    id: null,
    roleId: null,
    viewVideo: null,
    scenicSpotId: null,
    tongluUser: null,
    companyId: null,
    type: null,
    userName: null,
    companyName: null,
    mobile: null,
    uiStyle: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setId(state, id) {
      state.id = id
    },
    setRoleId(state, roleId) {
      state.roleId = roleId
    },
    setViewVideo(state, viewVideo) {
      state.viewVideo = viewVideo
    },
    setScenicSpotId(state, scenicSpotId) {
      state.scenicSpotId = scenicSpotId
    },
    setTongluUser(state, tongluUser) {
      state.tongluUser = tongluUser
    },
    setCompanyId(state, companyId) {
      state.companyId = companyId
    },
    setType(state, type) {
      state.type = type
    },
    setUserName(state, userName) {
      state.userName = userName
    },
    setCompanyName(state, companyName) {
      state.companyName = companyName
    },
    setMobile(state, mobile) {
      state.mobile = mobile
    },
    setUiStyle(state, uiStyle) {
      state.uiStyle = uiStyle
    }
  },
  getters: {
    getTongluUser(state) {
      return state.tongluUser != null
        ? state.tongluUser
        : ''
    },
    getViewVideo(state) {
      return state.viewVideo == 'true' || state.viewVideo == true
    },
    getIsElder(state) {
      return state.uiStyle == "elder"
    },
    getUserId(state) {
      return state.id
    }
  },
  actions: {
  },
  modules: {
  }
})
