<template>
  <div class="xiangxiBox">
    <div class="list" v-for="(item, index) in list1" :key="index">
      <img :src="item.imageUrl" alt="" />
      <div class="cont">
        <span>{{ item.name }}</span>
        <div class="shebei">设备总数{{ item.equipmentCount }}个</div>
        <div class="bottom">
          <div class="yujing" @click="yujingClick(item.id)">
            预警{{ item.warningCount }}次
            <img :src="jiantou" alt="" />
          </div>
          <div class="xuncha" @click="xunchaClick(item.id)">
            巡查{{ item.patrolCount2 }}次
            <img :src="jiantou" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/home/index';
export default {
  data() {
    return {
      jiantou: require('../../assets/img/home/xiangqingjiantou.png'),
      list: [
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        },
        {
          name: '垂云通天河-玻璃吊桥',
          yujing: 1,
          shebei: 2,
          xuncha: 4
        }
      ],
      list1: [],
      scenicSpotId: null,
      form: {
        scenicSpotId: 0,
        name: ''
      },
      name: ''
    };
  },
  created() {
    this.form.scenicSpotId = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getlist();
  },
  methods: {
    yujingClick(id) {
      console.log(id);
      this.$router.push({
        name: 'yujinglist',
        query: {
          state: 'yujing',
          id: id,
          name: this.name
        }
      });
    },
    xunchaClick(id) {
      console.log(id, '11111111');
      this.$router.push({
        name: 'yujinglist',
        query: {
          state: 'xuncha',
          id: id,
          name: this.name
        }
      });
    },
    getlist() {
      api.jingqulist(this.form).then((res) => {
        this.list1 = res.data;
        console.log(this.list1);
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url('../../assets/less/conment.less');
.xiangxiBox {
  padding: 0px 10px 0px 10px;
  .list {
    margin-top: 12px;
    height: 143px;
    background: #ffffff;
    border-radius: 11px;
    display: flex;
    align-items: center;
    img {
      width: 123px;
      height: 106px;
      margin-left: 17px;
      border-radius: 13px;
    }
    .cont {
      margin-left: 11px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #1c91ff;
      height: 95px;
      width: 226px;
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #363a44;
      }
      .shangxia();
      .shebei {
        height: 25px;
        width: 110px;
        background: rgba(28, 145, 255, 0.08);
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .bottom {
        .liangbian();
        // width: 228px;
        .yujing {
          height: 25px;
          width: 49%;
          background: rgba(28, 145, 255, 0.08);
          border-radius: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 13px;
            height: 13px;
          }
        }
        .xuncha {
          height: 25px;
          width: 49%;
          background: rgba(28, 145, 255, 0.08);
          border-radius: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }
}
</style>
