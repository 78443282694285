<template>
  <div class="nav">
    <van-tabbar route>
      <van-tabbar-item
        replace
        :to="{ path: '/zhelibanPage/youke', query: this.$route.query }"
        >景区
        <template #icon="props">
          <img :src="props.active ? home : homenoclick" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        replace
        :to="{ path: '/zhelibanPage/record', query: this.$route.query }"
        >我的
        <template #icon="props">
          <img :src="props.active ? my : mynoclick" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      home: require("../../assets/img/home/home.png"),
      homenoclick: require("../../assets/img/home/homenoclick.png"),
      mynoclick: require("../../assets/img/home/mynoclick.png"),
      my: require("../../assets/img/home/my.png"),
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
