<template>
  <div class="chakanBox" :class="{ old: isElder }">
    <div class="time">{{ allData.businessDate }}</div>
    <div class="buzou">
      <van-steps
        direction="vertical"
        :inactive-icon="buzouIcon"
        :active-icon="buzouIcon"
        active="0"
      >
        <div v-for="(item, index) in allData.operateLogList" :key="index">
          <van-step v-if="item.title == '巡查发布'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox" style="padding: 0px 0px">
              <div class="list">
                <div class="title">巡查编号</div>
                <div class="con">
                  {{ item.jsonObject.patrolCode || '无'}}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">巡查标题</div>
                <div class="con van-ellipsis" style="width: 64%">
                  {{ item.jsonObject.title || '无'}}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">巡查设施</div>
                <div class="con van-ellipsis">
                  {{ item.jsonObject.facilityName || '无'}}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">接收单位</div>
                <div class="con van-ellipsis">
                  {{ item.jsonObject.companyName || '无'}}
                </div>
                <div class="xian"></div>
              </div>
            </div>
          </van-step>
          <van-step v-else-if="item.title == '开始巡查'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox" style="padding: 0px 0px">
              <div class="list">
                <div class="title">运营单位</div>
                <div class="con">{{ item.jsonObject.companyName || '无'}}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">执行人员</div>
                <div class="con">
                  {{ item.jsonObject.patrolPerson || '无'}}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">联系方式</div>
                <div class="con">
                  {{ item.jsonObject.mobile || '无'}}
                </div>
                <div class="xian"></div>
              </div>
            </div>
          </van-step>

          <van-step v-else-if="item.title == '巡查反馈'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox">
              <div class="list">
                <div class="title">巡查项目</div>
                <div class="xian"></div>
              </div>
              <div class="list" style="display: table-cell">
                <div
                  class="fankui"
                  v-for="(item, index) in fankuidata"
                  :key="index"
                  style="heght: 280px"
                >
                  <span class="fankuixiangqing">{{ item.termContent }} </span>
                  <van-radio-group
                    v-model="item.termState"
                    disabled
                    direction="horizontal"
                  >
                    <van-radio :name="1">
                      正常
                      <template #icon="props">
                        <img
                          class="img-icon"
                          :src="props.checked ? activeIcon : inactiveIcon"
                        />
                      </template>
                    </van-radio>
                    <van-radio :name="2">
                      异常
                      <template #icon="props">
                        <img
                          class="img-icon"
                          :src="props.checked ? activeIcon : inactiveIcon"
                        />
                      </template>
                    </van-radio>
                  </van-radio-group>
                  <div class="result1" v-show="item.termState !== 1">
                    <div>异常原因</div>
                    <div>{{ item.feedbackContent }}</div>
                  </div>
                  <div class="fankuiImg">
                    <div class="imgBox">
                      <van-image v-for="(item, index) in item.picList"
                        :key="index"
                        :src="item">
                        <template v-slot:error>加载失败</template>
                      </van-image>
                      <!-- <img
                        v-for="(item, index) in item.picList"
                        :key="index"
                        :src="item"
                        alt=""
                      /> -->
                    </div>
                  </div>

                  <div v-show="item.termState !== 1">
                    <div class="result1">
                      <div class="">处理措施</div>
                      <div>{{ item.handleIdea || '无'}}</div>
                    </div>
                  </div>
                </div>
                <div class="result">
                  <div>巡查结果</div>
                  <div>
                    {{ item.jsonObject.patrolResult == 1 ? "正常" : "异常" }}
                  </div>
                </div>

                <div v-if="item.jsonObject.patrolResult !== 1">
                  <div class="result">
                    <div class="result_title">处理措施</div>
                    <div class="result_con">{{ item.jsonObject.handleIdea || '无'}}</div>
                  </div>
                  <div class="result">
                    <div class="result_title">截至时间</div>
                    <div class="result_con">{{ item.jsonObject.handleTime || '无'}}</div>
                  </div>
                </div>
                <div v-else class="result">
                  <div class="result_title">反馈内容</div>
                  <div class="result_con">{{ item.jsonObject.feedbackComment || '无'}}</div>
                </div>
              </div>
            </div>
          </van-step>

          <van-step v-else-if="item.title == '开始处理'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox" style="padding: 0px 0px">
              <div class="list">
                <div class="title">运营单位</div>
                <div class="con">{{ item.jsonObject.companyName || '无'}}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">执行人员</div>
                <div class="con">
                  {{ item.jsonObject.patrolPerson || '无'}}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">联系方式</div>
                <div class="con">
                  {{ item.jsonObject.mobile || '无'}}
                </div>
                <div class="xian"></div>
              </div>
            </div>
          </van-step>
          <van-step v-else-if="item.title == '处理反馈'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox">
              <div class="list">
                <div class="title">异常项目</div>
                <div class="xian"></div>
              </div>
              <div class="list" style="display: table-cell">
                <div
                  class="fankui"
                  v-for="(item, index) in item.jsonObject.patrolTerm"
                  :key="index"
                  style="heght: 280px"
                >
                  <span class="fankuixiangqing">{{ item.termContent }} </span>
                  <van-radio-group
                    v-model="item.termState"
                    disabled
                    direction="horizontal"
                  >
                    <van-radio :name="1">
                      正常
                      <template #icon="props">
                        <img
                          class="img-icon"
                          :src="props.checked ? activeIcon : inactiveIcon"
                        />
                      </template>
                    </van-radio>
                    <van-radio :name="2">
                      异常
                      <template #icon="props">
                        <img
                          class="img-icon"
                          :src="props.checked ? activeIcon : inactiveIcon"
                        />
                      </template>
                    </van-radio>
                  </van-radio-group>
                  <div class="result1" v-show="item.termState !== 1">
                    <div>异常原因</div>
                    <div>{{ item.feedbackContent }}</div>
                  </div>
                  <div class="fankuiImg">
                    <div class="imgBox">
                      <img
                        v-for="(item, index) in item.picList"
                        :key="index"
                        :src="item"
                        alt=""
                      />
                    </div>
                  </div>
                  <div v-show="item.termState !== 1">
                    <div class="result1">
                      <div class="">处理措施</div>
                      <div>{{ item.handleIdea || '无'}}</div>
                    </div>
                  </div>
                </div>
                <div class="result">
                  <div class="result_title">巡查结果</div>
                  <div class="result_con">
                    {{ item.jsonObject.patrolResult == 1 ? "正常" : "异常" }}
                  </div>
                </div>
                <div v-if="item.jsonObject.patrolResult !== 1">
                  <div class="result">
                    <div class="result_title">处理措施</div>
                    <div class="result_con">{{ item.jsonObject.handleIdea || '无'}}</div>
                  </div>
                  <div class="result">
                    <div class="result_title">截至时间</div>
                    <div class="result_con">{{ item.jsonObject.handleTime || '无'}}</div>
                  </div>
                </div>
                <div v-else class="result">
                  <div class="result_title">反馈内容</div>
                  <div class="result_con">{{ item.jsonObject.feedbackComment || '无'}}</div>
                </div>
              </div>
            </div>
          </van-step>
        </div>
      </van-steps>
    </div>
    <div class="buttonBox" v-if="showButton">
      <van-button round size="large" @click="dealWith" type="info"
        >处理异常</van-button
      >
    </div>
  </div>
</template>
<script>
import api from "@/api/Patrol/index";
export default {
  name: "xunchashenhe",
  data() {
    return {
      activeIcon: require("../../assets/img/patrol/zhengchang.png"),
      inactiveIcon: require("../../assets/img/patrol/yichang.png"),
      buzouIcon: require("../../assets/img/task/buzouyuan.png"),
      pass: require("../../assets/img/patrol/pass.png"),
      nopass: require("../../assets/img/patrol/nopass.png"),
      form: {
        patrolId: 0,
      },
      allData: {},
      fankui: [],
      shixiang: "",
      activeName: "1",
      ImageList: [],
      fabuData: {},
      fabudata: {},
      kaishiData: {},
      kaishidata: {},
      fankuiData: {},
      fankuidata: [],
      shenheData: [],
      showButton: false,
    };
  },
  created() {
    this.form.patrolId = this.$route.query.id;
    this.showButton = this.$route.query.processing == 1 || this.$route.query.patrolResult == 2;
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      api.auditPatrolDetail(this.form).then((res) => {
        if (res.code == "200") {
          this.allData = res.data;
          console.log(res.data);
          console.log(res.data.operateLogList[0]);
          this.fabuData = res.data.operateLogList[0];
          this.fabudata = this.fabuData.jsonObject;
          this.kaishiData = res.data.operateLogList[1];
          this.kaishidata = this.kaishiData.jsonObject;
          this.fankuiData = res.data.operateLogList[2];
          this.fankuidata = res.data.operateLogList[2].jsonObject.patrolTerm;
          const str = typeof this.fankuidata;
          if (str == "string") {
            const b = JSON.parse(this.fankuidata);
            this.fankuidata = b;
          }
          console.log(this.fankuidata);

          let a = JSON.parse(JSON.stringify(this.allData.operateLogList));
          let a1 = a;
          let arr = a1.splice(3);
          let Arr = [];
          Arr.push(arr);
          Arr.forEach((i) => {
            i.forEach((j) => {
              this.shenheData.push(j);
            });
          });
          console.log(this.shenheData);
        }
      });
    },
    dealWith() {
      if (this.$route.query.examineState == 4) {
        this.$router.push({
          name: "startInspection",
          query: {
            id: this.form.patrolId,
            patrolResult: this.$route.query.patrolResult
          },
        });
      } else if (this.$route.query.examineState == 3 || this.$route.query.patrolResult == 2) {
          this.$router.push({
            name: "patrolForm",
            query: {
              id: this.form.patrolId,
              patrolResult: this.$route.query.patrolResult
            },
          });
      }
    },
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
@import url('../../assets/less/conment.less');

.hengxian {
  width: 90%;
  height: 1px;
  background: #e8e9ec;
  align-self: flex-end;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.chakanBox {
  margin: 10px 10px;
  background: #fff;
  padding: 10px 0px 72px 0px;
  border-radius: 10px;



  .time {
    height: 33px;
    width: 220px;
    margin-left: 10px;
    background: #1c91ff;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 33px;
  }

  .renwutime {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1c91ff;
  }

  .buzou {
    margin-top: 12px;
  }

  .buzouBox {
    margin-top: 18px;
    background: #f6f7f8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .list {
      position: relative;
      padding: 17px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;

      .title {

        color: #686b73;
      }

      .con {
        color: #363A44;
        margin-left: 16px; // width: 71%;
        word-break: break-all;
        line-height: 20px;
      }

      .xian {
        .hengxian();
      }

      .tuBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imgBox {
          img {
            width: 95px;
            height: 82px;

            &:last-child {
              margin-left: 10px;
            }
          }
          ::v-deep .van-image{
            width: 95px;
            height: 82px;
          }
        }
      }

      .fankui {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        padding: 21px 0px;
        border-bottom: 1px solid #e8e9ec;

        &:last-child {
          border-bottom: 0px;
        }

        .fankuixiangqing {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
          line-height: 23px;
        }

        .van-radio-group--horizontal {
          justify-content: space-between;
          padding: 50px;
        }

        .img-icon {
          height: 16px;
        }

        .fankuiImg {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .word {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #686b73;
          }
          .van-image{
            width: 87px;
            height: 75px;
            border-radius: 5px;
            border: none;
            margin-right: 10px;
          }
          img {
            width: 87px;
            height: 75px;
            border-radius: 5px;
            border: none;
            margin-right: 10px;

            &:first-child {
              margin-left: 0px;
            }
          }
        }

        .xian {
          .hengxian();
        }
      }

      .result1 {
        height: 33px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 18px 0px;

        div {
          &:first-child {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #B3B5B9;
          }

          &:nth-child(2) {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #363a44;
            margin-left: 16px
          }
        }

        .xian {
          .hengxian();
        }
      }

      .result {
        height: 33px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 18px 0px;
        border-bottom: 1px solid #e8e9ec;
        .result_title{
          white-space: nowrap;
        }
        .result_con{
          word-break: break-all;
        }

        div {
          &:first-child {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #B3B5B9;
          }

          &:nth-child(2) {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #363a44;
            margin-left: 16px
          }
        }

        .xian {
          .hengxian();
        }
      }
    }

    .shenhe {
      display: flex;
      padding-left: 12px;
      padding-right: 25px;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        width: 100%;

        .danwei {
          color: #686b73;
        }

        .state {
          color: #363a44;
        }
      }

      img {
        width: 23px;
        height: 23px;
      }
    }

    .xian1 {
      width: 90%;
      height: 1px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background: #e8e9ec;
      margin: 25px 0;
    }

    .cause {
      padding: 0px 25px 0px 12px;

      .content {
        color: #363a44;
      }
    }
  }
}

/deep/ .van-cell {
  font-size: 17px;
  color: #686b73 !important;
  padding: 10px 10px;
}

/deep/ .van-step--vertical .van-step__line {
  width: 0 !important;
  border: 1px dashed #aaaaaa !important;
}

/deep/.van-step--vertical:not(:last-child)::after {
  border-bottom: 0px;
}
.old{
  ::v-deep .van-radio__label{
    font-size: 20px !important;
  }
  .time,.renwutime,.title,.con{
    font-size: 20px ;
  }
  .fankuixiangqing,.result1 div,.result div{
    font-size: 20px !important;
  }
  .title {
    white-space: nowrap;
  }
}
</style>
