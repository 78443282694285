<template>
  <div class="palyer">
    <LivePlayer
      :poster="poster"
      ref="palyer"
      :videoUrl="video"
      autoplay
      live
      fluent
      stretch
      :controls ="false"
      :hide-snapshot-button="true"
    />
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
export default {
  name: "LivePlayerDemo",
  components: {
    LivePlayer,
  },
  props: {
    videoURL: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      video: this.videoURL,
    };
  },
  created() {
    console.log("created");
  },
  watch: {
    // videoURL () {
    //   if (this.videoURL)
    //   this.play();
    // }
  },
  computed: {},

  methods: {
    // 播放
    play() {
      this.$refs.palyer.play();
    },
    // 暂停
    pause() {
      this.$refs.palyer.pause();
    },
  },
  beforeDestroy() {
    this.video = "";
  },
};
</script>

<style scoped>
.palyer {
  width: 100%;
  height: 100%;
  margin: auto;
}
</style>
