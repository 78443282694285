<template>
  <div class="patrolBox" :class="{ old: isElder }">
    <div class="topBox">
      <div @click="option('yujing')" :class="topClick ? 'noclick' : 'click'">
        预警
      </div>
      <div
        @click="option('xuncha')"
        :class="topClick ? 'partolClick' : 'partolnoclick'"
      >
        巡查
      </div>
    </div>

    <!-- 预警搜索 -->
    <Search
      v-if="isshowyujing"
      v-model="formyujing"
      @changeclickSearch="clickSearchyujing"
      @changeonConfirm="onConfirmyujing"
    ></Search>
    <!-- 巡查搜索 -->
    <Search
      v-else
      v-model="formxuncha"
      @changeclickSearch="clickSearchxuncha"
      @changeonConfirm="onConfirmxuncha"
    ></Search>
    <!-- 巡查搜索 -->

    <!-- 这是预警记录 传过来的值判断 -->
    <div v-if="state == 'yujing'" class="list">
      <div>
        <van-list
          v-model="loadingyujing"
          :finished="finishedyujing"
          finished-text=""
          @load="onLoadyujng"
          :offset="0"
          :immediate-check="false"
        >
          <div
            class="item"
            @click="xiangqingyujing(item)"
            v-for="(item, index) in Datayujing"
            :key="index"
          >
            <div class="left">
              <van-image :src="item.imgUrl ? item.imgUrl : zhanweiicon" alt="" >
                <template v-slot:error>加载失败</template>
              </van-image>
              <!-- <img :src="item.imgUrl ? item.imgUrl : zhanweiicon" alt="" /> -->
            </div>
            <div class="right">
              <div class="word">
                <div class="title">{{ item.facilityName || "无" }}</div>
                <div
                  class="biaoti van-ellipsis"
                  style="width: 200px; margin-top: 4px"
                >
                  预警设备：{{ item.equipmentCode || "无" }}
                </div>
                <div
                  class="biaoti van-ellipsis"
                  style="width: 200px; margin-top: 2px"
                >
                  预警项：{{ item.monitorTerm || "无" }}
                </div>

                <div class="time" style="margin-top: 2px">
                  {{
                    formyujing.examineState == 3
                      ? "解除时间："
                      : formyujing.examineState == 2
                      ? "上传时间："
                      : "预警时间："
                  }}{{
                    formyujing.examineState == 3
                      ? item.removeTime || "无"
                      : formyujing.examineState == 2
                      ? item.createTime || "无"
                      : item.warningTime || "无"
                  }}
                </div>
              </div>
            </div>
          </div>
          <no-data v-if="Datayujing.length == 0"></no-data>
        </van-list>
      </div>
    </div>
    <!-- 这是巡查记录 -->
    <div v-else class="list">
      <div>
        <van-list
          v-model="loadingxuncha"
          :finished="finishedxuncha"
          finished-text=""
          @load="onLoadxuncha"
          :offset="0"
          :immediate-check="false"
        >
          <div
            class="item"
            v-for="(item, index) in Dataxuncha"
            :key="index"
            @click="xiangqingxuncha(item)"
          >
            <div class="left">
              <van-image v-if="!xunShow"
                :src="item.imageUrl ? item.imageUrl : zhanweiicon" >
                <template v-slot:error>加载失败</template>
              </van-image>
              <!-- <img
                v-if="!xunShow"
                :src="item.imageUrl ? item.imageUrl : zhanweiicon"
                alt=""
              /> -->
            </div>
            <div class="right">
              <div class="word">
                <div class="title">
                  <span>{{ item.facilityName || "无" }}</span>
                </div>
                <div
                  class="biaoti van-ellipsis"
                  style="width: 200px; margin-top: 4px"
                >
                  巡查标题：{{ item.title }}
                </div>
                <div class="biaoti">
                  巡查反馈：{{
                    formxuncha.examineState == 1
                      ? item.feedbackComment || "无"
                      : item.patrolResult == 1
                      ? "正常"
                      : item.patrolResult == 2
                      ? "异常"
                      : "无"
                  }}
                </div>
                <div class="biaoti">
                  {{ formxuncha.examineState == 1 ? "发布时间：" : "上传时间："
                  }}{{
                    formxuncha.examineState == 1
                      ? item.createTime
                      : item.patrolTime
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="item.patrolResult == 2"
              @click.stop="xiangqingxuncha(item)"
              class="chuli"
            >
              <van-button class="button" size="small" round type="info"
                >处理</van-button
              >
            </div>
            <!-- <div class="contentBox">
              <img :src="item.imageUrl ? item.imageUrl : zhanweiicon" alt="" />
              <div class="word">
                <div class="title">
                  <span>{{ item.facilityName || "--" }}</span>
                </div>
                <div
                  class="biaoti van-ellipsis"
                  style="width: 200px; margin-top: 4px"
                >
                  巡查标题：{{ item.title }}
                </div>
                <div class="biaoti">
                  巡查反馈：{{
                    formxuncha.examineState == 1
                      ? item.feedbackComment
                      : item.patrolResult == 1
                      ? "正常"
                      : item.patrolResult == 2
                      ? "异常"
                      : ""
                  }}
                </div>
                <div class="biaoti">
                  {{ formxuncha.examineState == 1 ? "发布时间：" : "上传时间："
                  }}{{
                    formxuncha.examineState == 1
                      ? item.createTime
                      : item.patrolTime
                  }}
                </div>
              </div>
              <van-button
                class="chuli"
                v-if="item.patrolResult == 2"
                type="info"
                >处理</van-button
              >
            </div> -->
          </div>
          <no-data v-if="Dataxuncha.length == 0"></no-data>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import api2 from "@/api/Patrol/index";
import api from "@/api/waring/index";
import moment from "moment";
import noData from "@/components/no-data";
import Search from "@/components/Search";
export default {
  components: {
    noData,
    Search,
  },
  data() {
    return {
      isshowyujing: true,
      formxuncha: {
        keyWord: "",
        facilityId: 0,
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
      },
      formyujing: {
        keyWord: "",
        facilityId: 0,
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
      },
      finishedyujing: false,
      finishedxuncha: false,
      loadingyujing: false,
      loadingxuncha: false,
      Datayujing: [], //预警数据
      Dataxuncha: [], //巡查数据
      searchIcon: require("../../assets/img/patrol/search.png"),
      state: "yujing",
      name: "",
      riliyujing: false,
      rilixuncha: false,
      riqi: require("../../assets/img/my/xiajiantou.png"),
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      topClick: false,
      zhanweiicon: require("../../assets/img/login/zhanwei.png"),
    };
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
  created() {
    this.state = this.$route.query.state;
    this.name = this.$route.query.name;
    this.formxuncha.facilityId = this.$route.query.id;
    this.formyujing.facilityId = this.$route.query.id;
    if (this.state == "xuncha") {
      this.topClick = true;
      this.isshowyujing = false;
      this.getListxuncha();
    } else {
      this.topClick = false;
      this.isshowyujing = true;
      this.getListyujing();
    }
  },
  mounted() {},
  methods: {
    clickSearchyujing() {
      this.formyujing.pageNum = 1;
      this.getListyujing();
    },
    clickSearchxuncha() {
      this.formxuncha.pageNum = 1;
      this.getListxuncha();
    },

    onConfirmyujing() {
      this.getListyujing();
    },
    onConfirmxuncha() {
      this.getListxuncha();
    },
    option(i) {
      this.state = i;
      if (i === "xuncha") {
        this.$router.replace({
          path: "/Homexiangxi/list",
          query: {
            state: "xuncha",
            type: 1,
          },
        });
        this.topClick = true;
        this.isshowyujing = false;
        this.formxuncha.pageNum = 1;
        this.formxuncha.keyWord = "";
        this.formxuncha.startTime = "";
        this.formxuncha.endTime = "";
        this.getListxuncha();
      } else {
        this.$router.replace({
          path: "/Homexiangxi/list",
          query: {
            state: "yujing",
          },
        });
        this.topClick = false;
        this.isshowyujing = true;
        this.formyujing.pageNum = 1;
        this.formyujing.keyWord = "";
        this.formyujing.startTime = "";
        this.formyujing.endTime = "";
        this.getListyujing();
      }
    },
    onLoadxuncha() {
      this.formxuncha.pageNum++;
      api2.xunchaList(this.formxuncha).then((res) => {
        if (res.data.rows.length !== 0) {
          for (var i in res.data.rows) {
            this.Dataxuncha.push(res.data.rows[i]);
          }
        } else {
          this.finishedxuncha = true;
          return;
        }
        this.loadingxuncha = false;
      });
    },
    //请求巡查列表数据
    getListxuncha() {
      api2.xunchaList(this.formxuncha).then((res) => {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        this.Dataxuncha = res.data.rows || [];
      });
    },
    xiangqingxuncha(item) {
      //已审核跳转页面
      if (item.patrolResult == 2) {
        this.$router.push({
          name: "xunchashenhe",
          query: {
            id: item.id,
            state: "待巡查",
            patrolResult: item.patrolResult
          },
        });
        return;
      }
      if (this.formxuncha.examineState == 3) {
        this.$router.push({
          name: "xunchashenhe",
          query: {
            id: item.id,
          },
        });
      } else if (this.formxuncha.examineState == 1) {
        this.$router.push({
          name: "patrolForm",
          query: {
            id: item.id,
            state: this.state,
          },
        });
      }
    },
    onLoadyujng() {
      this.formyujing.pageNum++;
      this.loadingyujing = false;
      api.warnList(this.formyujing).then((res) => {
        if (res.data.rows.length !== 0) {
          for (var i in res.data.rows) {
            this.Datayujing.push(res.data.rows[i]);
          }
        } else if (res.data.rows.length == 0) {
          this.finishedyujing = true;
          return;
        }
      });
    },

    //获取预警列表数据
    getListyujing() {
      api.warnList(this.formyujing).then((res) => {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        if (res.data.code == "200") {
          this.Datayujing = res.data.rows || [];
        }
      });
    },
    xiangqingyujing(item) {
      //已审核跳转页面
      if (this.formyujing.examineState == 3) {
        this.$router.push({
          name: "waringchakan",
          query: {
            name: "预警已审核的页面",
            id: item.id,
          },
        });
      } else if (this.formyujing.examineState == 2) {
        //未审核跳转页面
        this.$router.push({
          name: "waringshenhe",
          query: {
            name: "预警审核页面",
            id: item.id,
          },
        });
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.patrolBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;

  .topBox {
    width: 100%;
    height: 39px;
    margin-top: 10px;
    border-radius: 0px 8px 8px 0px;
    display: flex;

    .waring,
    .patorl {
      font-size: 17px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .click {
      background: #0c90ff;
      color: #fff;
      font-size: 17px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0px 0px 8px;
    }

    .noclick {
      background: #fff;
      color: #363a44;
      font-size: 17px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0px 0px 8px;
      border: 1px solid #0c90ff;
    }

    .partolClick {
      background: #0c90ff;
      color: #fff;
      font-size: 17px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 8px 8px 0px;
    }

    .partolnoclick {
      background: #fff;
      color: #363a44;
      font-size: 17px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #0c90ff;
    }
  }

  .option {
    margin-top: 22px;
    height: 50px;
    border-radius: 25px;
    background: #f1f1f1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .box {
      width: 100px;
      height: 36px;
      border-radius: 36px;
      background: #1c91ff;
      color: #fff;
      font-size: 14px;
      box-shadow: 0px 4px 4px rgba(63, 141, 235, 0.3);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .iconstyle {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-top: 3px;
    }

    .dianstyle {
      width: 16px;
      height: 16px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: -20px;
      top: -3px;
      line-height: 16px;
      font-size: 16px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("../../assets/img/patrol/yishenhe.png");
      }
    }

    .yishenheNoClick {
      width: 100px;
      height: 36px;
      color: #333333;
      font-weight: 600;
      font-size: 15px;
      font-family: PingFang SC;
      line-height: 38px;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("../../assets/img/patrol/daishenhe.png");
      }
    }

    .xunClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("../../assets/img/patrol/daixuncha.png");
      }
    }
  }

  .list {
    margin: 15px 0 30px 0;

    .item {
      background: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 5px 8px;

      .biaoshi {
        position: absolute;
        top: 0;
        left: 0;
      }
      .left {
        .user {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          width: 20px;
          height: 18px;
          margin-right: 10px;
        }
        img {
          height: 85px;
          width: 96px;
          border-radius: 10px;
        }
        ::v-deep img{
          border-radius: 10px;
        }
        ::v-deep .van-image__error{
          border-radius: 10px;
        }
      }

      .right {
        display: flex;
        flex: 1;
        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 12px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-left: 12px;
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 4px;
            font-size: 16px;
            // width: 195px;
            // white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }
      }

      .chakan {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 4px;
            // width: 195px;
            white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }
        > .time {
          color: #686b73;
          font-size: 16px;
          align-self: center;
        }

        .button {
          width: 80px;
          justify-items: end;
        }
      }
    }
    // .box {
    //   margin-top: 10px;
    //   height: 127px;
    //   border-radius: 10px;
    //   position: relative;
    //   background: #fff;
    //   border-radius: 10px;
    //   display: flex;
    //   align-content: center;
    //   flex-direction: column;
    //   justify-content: center;

    //   .biaoshiStyle {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 50px;
    //     height: 20px;
    //     color: #fff;
    //     border-radius: 10px 0px 10px 0px;
    //     font-size: 12px;
    //     text-align: center;
    //     line-height: 20px;
    //   }

    //   .lvbiaoshi {
    //     .biaoshiStyle();
    //     background: #50cb79;
    //     box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
    //   }

    //   .huangbiaoshi {
    //     background: #ffac20;
    //     .biaoshiStyle();
    //     box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
    //   }

    //   .hongbiaoshi {
    //     background: #ff6661;
    //     .biaoshiStyle();
    //     box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
    //   }

    //   .contentBox {
    //     display: flex;
    //     margin: 5px 16px 0px 16px;
    //     align-items: center;
    //     .word {
    //       font-family: PingFang SC;
    //       color: #686b73;
    //       font-size: 12px;
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: space-around;
    //       margin: 0 0 0 15px;

    //       .title {
    //         font-size: 16px;
    //         font-weight: 600;
    //         color: #363a44;
    //       }

    //       .biaoti {
    //         font-size: 14px;
    //         margin-top: 5px;
    //       }
    //       .time {
    //         font-size: 14px;
    //       }
    //     }

    //     img {
    //       height: 79px;
    //       width: 106px;
    //       border-radius: 5px;
    //     }
    //     .chuli {
    //       width: 80px;
    //       ::v-deep.van-button__content {
    //         width: 80px;
    //       }
    //     }
    //     .chakan {
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: center;

    //       .van-button {
    //         width: 61px;
    //         height: 23px;
    //         box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
    //         border-radius: 10px;
    //         font-size: 12px;
    //       }
    //     }
    //   }
    // }
  }
}
.old {
  .topBox {
    .noclick, .partolClick {
      font-size: 20px !important;
    }
    .waring,
    .patorl {
      font-size: 18px;
    }
    .click, .partolnoclick {
      font-size: 20px;
    }
    
  }
  .list {
    .title {
      font-size: 24px !important;
    }
    
    .item {
      overflow: hidden;
      .left img {
        height: 110px !important;
        width: 105px !important;
      }
      .left  .van-image {
          height: 110px !important;
          width: 105px !important;
        }
      .right {
        width: 58%;
        .biaoti, .time {
          font-size: 18px !important;
          width: 70%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .chuli {
        .van-button--small {
          width: 40px;
          height: 62px;
        }
      }
    }
  }
  .option .yishenheNoClick .word .dian {
    min-width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
  }
}
</style>
