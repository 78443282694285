import request from '@/api/index'

export default {
  //首页景区列表
  list(data) {
    return request.post('list', data)
  },
  //重点关注列表
  importantList(data) {
    return request.get('importantList', data)
  },
  //取消或者关注重点
  editFocus(data) {
    return request.post2('editFocus', data)
  },
  //头部公告
  getAnnouncementInfo(data) {
    return request.post2('getAnnouncementInfo', data)
  },
  //头部列表
  toplist(data) {
    return request.post2('toplist', data)
  },
  //排查预警列表
  patrolPageList(data) {
    return request.get('patrolPageList', data)
  },
  //景区列表二级页面
  jingqulist(data) {
    return request.post('jingqulist', data)
  },
  //景区地图一级数据
  map1(data) {
    return request.get('map1', data)
  },
  //景区地图二级数据
  map2(data) {
    return request.get('map2', data)
  },
  map3(data) {
    return request.get('map3', data)
  },
  //预警记录
  warningHandleRecord(data) {
    return request.get('warningHandleRecord', data)
  },
  //巡查记录
  patrolRecord(data) {
    return request.get('patrolRecord', data)
  },
  //浙里办获取景区设施列表
  scenicSpotList(data) {
    return request.get('scenicSpotList', data)
  },
  //提交游客参与
  touristPartake(data) {
    return request.post2('touristPartake', data)
  },
  //任务记录
  taskRecord(data) {
    return request.get('taskRecord', data)
  },
  //游客上传记录
  touristRecord(data) {
    return request.get('touristRecord', data)
  },
  suggestionScoreSave(data) {
    return request.post2('suggestionScoreSave', data)
  }
}
