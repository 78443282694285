<template>
  <div class="loginBox" v-if="!zlbEnv">
    <div class="imgBox">
      <van-image
        fit="cover"
        height="100%"
        width="100%"
        :src="backgroundImage"
      />
    </div>
    <div class="login">
      <div class="loginBox">
        <span class="title">欢迎登录文旅新业态</span>
        <van-field
          v-model="form.userName"
          :left-icon="user"
          placeholder="请输入您的用户名"
          clearable
        />
        <van-field
          :left-icon="passwordIcon"
          v-model="form.password"
          placeholder="请输入密码"
          :type="showPWD ? 'text' : 'password'"
        >
          <template slot="right-icon">
            <div
              :class="eyea ? 'eyeaclose' : 'eyeaopen'"
              style="width: 16px; height: 9px"
              @click="clickEyea"
            ></div>
          </template>
        </van-field>
        <van-checkbox v-model="checked">下次自动登录</van-checkbox>
      </div>
      <van-button type="info" @click="login()">登录</van-button>
    </div>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
const publicKey =
  "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdH\n" +
  "nzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ==";
import api from "@/api/methods";
import { setZwLog } from "@/utils/zlog.js";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      showPWD: false,
      property: "value",
      form: {
        userName: "",
        password: "",
        loginPorts: 3,
        ticketId: "",
      },
      backgroundImage: require("@/assets/img/login/background.jpg"),
      user: require("@/assets/img/login/user.png"),
      passwordIcon: require("@/assets/img/login/password.png"),
      eyesUrl: null,
      eyea: true,
      checked: false,
      form1: {
        authCode: "",
        loginPorts: 3,
      },
      flag: true,
      zlbEnv: true,
    };
  },
  created() {
    ZWJSBridge.onReady(() => {
      if (ZWJSBridge.ssoTicket) {
        ZWJSBridge.ssoTicket({}).then((res) => {
          console.log("res", res);
          if (res && res.result === true) {
            // TODO 应用方服务端单点登录接口
            this.zlbLogin(res.ticketId);
          } else {
            this.zlbEnv = false;
            this.$toast("异常情况：当前环境不支持 “浙里办”统一单点登录");
          }
        });
      } else {
        this.zlbEnv = false;
        console.log("ZWJSBridge Load erro");
      }
    });
  },
  mounted() {},
  methods: {
    // 加密
    encrypt(txt) {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey); // 设置公钥
      return encryptor.encrypt(txt); // 对数据进行加密
    },
    zlbLogin(ticketId) {
      console.log('ticketId',ticketId);
      api.zlbLogin({ ticketId }).then((res) => {
        if (res.code == 200) {
          // this.$router.push({ name: "zlbHome" });
          // localStorage.setItem("type", res.data.type);
          // localStorage.setItem("tongluUser", res.data.token);
          // localStorage.setItem("companyId", res.data.companyId);
          // localStorage.setItem("userName", res.data.userName);
          // localStorage.setItem("companyName", res.data.companyName);
          // localStorage.setItem("mobile", res.data.mobile);
          // localStorage.setItem("viewVideo", res.data.viewVideo);
          this.$store.commit("setId", res.data.id);
          this.$store.commit("setTongluUser", res.data.token);
          this.$store.commit("setCompanyId", res.data.companyId);
          this.$store.commit("setRoleId", res.data.roleId);
          this.$store.commit("setType", res.data.type);
          this.$store.commit("setUserName", res.data.userName);
          this.$store.commit("setCompanyName", res.data.companyName);
          this.$store.commit("setViewVideo", res.data.viewVideo);
          this.$store.commit("setMobile", res.data.mobile);
          this.$store.commit("setToken", res.data.token);
          setZwLog(res.data.id, res.data.userName);
          ZWJSBridge.getUiStyle({}).then((res) => {
          this.$store.commit("setUiStyle", res.uiStyle);
            console.log("res", res);
            this.$router.push({
              name: "zlbHome",
            });
          });
        }
      });
    },
    clickEyea() {
      this.showPWD = !this.showPWD;
      this.eyea = !this.eyea;
    },
    login() {
      if (this.form.userName == "") {
        this.$toast("请输入用户名");
      } else if (this.form.password == "") {
        this.$toast("请输入密码");
      } else {
        let form = { ...this.form };
        form.password = this.encrypt(this.form.password);
        api.login(form).then((res) => {
        console.log('login', res)
        if (!res.code || res.code == "200") {
          // request.post("/login/up", this.form).then((logRes) => {
          this.$toast("登录成功");
          this.$store.commit("setId", res.id);
          this.$store.commit("setTongluUser", res.token);
          this.$store.commit("setCompanyId", res.companyId);
          this.$store.commit("setRoleId", res.roleId);
          this.$store.commit("setType", res.type);
          this.$store.commit("setUserName", res.userName);
          this.$store.commit("setCompanyName", res.companyName);
          this.$store.commit("setViewVideo", true);
          // this.$store.commit("setViewVideo", res.viewVideo);
          this.$store.commit("setMobile", res.mobile);
          this.$store.commit("setToken", res.token);
          
          // this.$store.commit("setUiStyle", res.uiStyle);
          this.$store.commit("setUiStyle", 'elder');
          setZwLog(res.id, res.userName);
          this.$router.push({
            name: "zlbHome",
          });
          // });
        } else {
          this.$toast(res.message);
        }
      });
        
      }
    },
  },
  watch: {
    showPWD(newVal) {
      console.log(newVal);
      if (newVal) {
        this.eyesUrl = this.eyeaopen;
      } else {
        this.eyesUrl = this.eyeaclose;
      }
    },
  },
};
</script>
<style lang="less">
.loginBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .imgBox {
    width: 100%;
    height: 336px;
    display: inline-block;
  }
  .login {
    top: 20vh;
    min-height: 400px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loginBox {
    width: 340px;
    height: 261px;
    background: #fff;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    //top: -100px;
    .title {
      text-align: center;
      // width: 200px;
      height: 20px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #2a96fb;
    }
    .van-field {
      width: 80%;
      input:-intenal-autofill-selected {
        background-color: #fff;
      }
    }
    .van-checkbox {
      .van-checkbox__label {
        color: #b3b5b9;
        font-size: 12px;
      }
    }
  }
  .van-button {
    position: absolute;
    bottom: 50px;
    width: 250px;
    height: 40px;
    background: #2093fe;
    box-shadow: 0px 0px 3px 0px rgba(218, 229, 237, 0.1);
    border-radius: 20px;
    font-weight: 400;
    font-size: 18px;
  }
}
.eyeaclose {
  background: url("../assets/img/login/eyeaclose.png");
  background-size: 100% 100%;
  border: none !important;
}
.eyeaopen {
  background: url("../assets/img/login/eyeaopen.png");
  background-size: 100% 100%;
}
</style>
