import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
import router from '../router/index'
import store from "@/store/index.js";

let vm = new Vue()

import apiList from './api_list'

let request = axios.create({
  // baseURL: 'https://5a8a7dad.r9.cpolar.top',
  // baseURL: 'https://47.114.161.173:3336/',
  // baseURL: 'https://wenlv.borunspace.com:3336/', //线上接口',
  baseURL: 'https://test.wenlv.zlb.borunspace.com/', //线上接口',
  timeout: 12000,
})


request.interceptors.request.use(
  function (config) {
    // config.url = config.url.replace('/api','')
    // 在发送请求之前做些什么
    config.headers.token = store.state.token
    console.log(config)
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    //错误code码
    console.log('response.code', response);
    if (response.data.code == 'A0002') {
      router.push("/");
    }

    if (response.data.code === 1) {
      vm.$message({
        message: response.data.msg,
        type: 'error',
      })
      return Promise.reject()
    }

    const errCodes = [401, 403]

    if (errCodes.indexOf(response.data.code) != -1) {
      vm.$message({
        message: response.data.msg,
        type: 'error',
      })
      return response.data
    }

    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

// let baseURL = "http://192.168.3.155:8083";
// let token = sessionStorage.getItem('1')

export default {
  request: request,
  post(apiName, data) {
    let url = apiList[apiName]
    return request({
      method: 'post',
      // baseURL: baseURL,
      url: url,
      data: qs.stringify(data),
    })
  },
  post2(apiName, data) {
    let url = apiList[apiName]
    return request({
      method: 'post',
      // baseURL: baseURL,
      url: url,
      data: data,
    })
  },
  get(apiName, data) {
    let url = apiList[apiName]
    return request({
      method: 'get',
      // baseURL: baseURL,
      url: url,
      params: data,
    })
  },
  get2(apiName, data) {
    let url = apiList[apiName]
    return request({
      method: 'get',
      // baseURL: baseURL,
      url: url,
      data,
    })
  },
}
