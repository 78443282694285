<template>
  <div class="myTask">
    <!-- <div class="option">
      <div @click="yishenClick('已审核')" :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'">
        <div class="word">
          <div class="dian" v-show="yishendian">{{ yishenNumber }}</div>
          已处理
        </div>
      </div>
      <div @click="xunClick('')" :class="xunShow ? 'xunClick' : 'yishenheNoClick'">
        <div class="icon"></div>
        <div class="word">
          <div class="dian" v-show="xunNumber !== 0">{{ xunNumber }}</div>
          待处理
        </div>
      </div>
    </div> -->
    <Search v-model="form" @changeclickSearch="clickSearch" @changeonConfirm="onConfirm"></Search>
    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0" :immediate-check="false">
        <div class="box" @click="xiangqing(item.id, item.examineState)" v-for="(item, index) in Data" :key="index">
          <div v-show="xunShow && item.examineState == 5" :class="
            item.examineState == 4
              ? 'lvbiaoshi'
              : item.examineState
                ? 'huangbiaoshi'
                : ''
          ">
            {{
                item.examineState == 4
                  ? '已阅'
                  : item.examineState == 5
                    ? '已退回'
                    : ''
            }}
          </div>
          <div v-show="!xunShow" :class="
            item.examineState == 4
              ? 'lvbiaoshi'
              : item.examineState == 3
                ? 'huangbiaoshi'
                : ''
          ">
            {{
                item.examineState == 4
                  ? '已阅'
                  : item.examineState == 3
                    ? '待阅'
                    : ''
            }}
          </div>
          <div class="contentBox">
            <img v-show="!xunShow" :src="item.imgUrl == '' ? '' : item.imgUrl" alt="" />
            <div style="display: flex; width: 80%">
              <div v-if="xunShow" class="imgBox">
                <img :src="user" alt="" class="user" />
              </div>

              <div class="word" style="margin-left: 12px">
                <div class="title">
                  {{ item.companyName }}
                </div>
                <!-- <div class="title" v-else>{{ item.scenicSpotName }}</div> -->
                <div class="biaoti" v-if="xunShow">
                  {{ item.type == 'gaojin' ? '预警设备：' : '任务标题：'
                  }}{{ item.title }}
                </div>
                <div class="biaoti" v-if="xunShow == true ? false : true">
                  {{ item.type == 'gaojin' ? '预警项：' : '任务标题：'
                  }}{{ item.title }}
                </div>

                <!-- 这个待处理时出现 -->
                <div class="biaoti" v-if="xunShow">
                  任务内容：{{ item.comment }}
                </div>
                <div class="biaoti van-ellipsis" v-if="xunShow == true ? false : true">
                  {{ item.type == 'gaojin' ? '预警项：' : '任务反馈：'
                  }}{{ item.feedbackComment }}
                </div>
                <div class="biaoti" v-if="xunShow == true ? false : true">
                  {{ item.type == 'gaojin' ? '解除时间：' : '上传时间：'
                  }}{{ item.executeTime }}
                </div>
              </div>
            </div>
            <div v-if="xunShow" class="chakan">
              <div class="time">{{ item.modifiedTime.substr(11, 16) }}</div>
              <van-button type="info" @click.stop="chakan(item.id, item.examineState)">处理</van-button>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <no-data v-if="Data.length == 0"></no-data>
  </div>
</template>
<script>
import Nav from '../../../src/components/nav/nav.vue';
import noData from '@/components/no-data'
import api from '@/api/task/index';
import Search from '@/components/Search'
export default {
  components: {
    Nav,
    noData,
    Search
  },
  data () {
    return {
      form: {
        keyWord: '',
        examineState: 3,
        pageNum: 1,
        pageSize: 10
      },
      Data: [],
      Data1: [],
      searchIcon: require('../../assets/img/patrol/search.png'),
      yishenShow: true,
      yishenNumber: 0,
      yishendian: true,
      daiShow: false,
      daishenNumber: 0,
      daishendian: true,
      xunShow: false,
      xunNumber: 0,
      xundian: true,
      user: require('../../assets/img/task/user.png'),
      gaojin: require('../../assets/img/task/gaojin.png'),
      zhiding: require('../../assets/img/task/zhiding.png'),
      loading: false,
      finished: false
    };
  },
  created () {
    this.yiShow = true;
    if (this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.daiShow == true) {
      this.daishendian = false;
    }
  },
  mounted () {
    this.getList();
    this.gettaskListNum();
  },
  methods: {
    onLoad () {
      this.form.pageNum++;
      api.taskList(this.form).then((res) => {
        if (res.code == '200') {
          if (res.rows.length !== 0) {
            for (var i in res.rows) {
              this.Data.push(res.rows[i]);
            }
          } else {
            this.finished = true;
            return;
          }
        }
        this.loading = false;
      });
    },
    //获取未读数量
    gettaskListNum () {
      api.taskListNum().then((res) => {
        if (res.code == '200') {
          this.yishenNumber = res.data.passNum; //已审核未读
          this.daishenNumber = res.data.unAuditNum; //待审核未读
          this.xunNumber = res.data.unImplementNum; //待处理未读
        } else {
          this.$toast(res.message);
        }
      });
    },
    //获取列表数据
    getList () {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      api.taskList(this.form).then((res) => {
        console.log(res);
        if (res.code == '200') {
          this.Data = res.rows;
        }
      });
    },
    chakan (id, state) {
      console.log(state);
      if (state == 2 || state == 4) {
        this.$router.push({
          name: 'dealWithNow',
          query: {
            id: id
          }
        });
      } else {
        this.$router.push({
          name: 'taskxiangqing',
          query: {
            name: '任务详情的页面',
            id: id,
            state: state
          }
        });
      }
    },
    xiangqing (i, state) {
      console.log(state);
      //已处理跳转
      if (this.form.examineState == 3) {
        this.$router.push({
          name: 'renwuyishenhe',
          query: {
            name: '',
            id: i
          }
        });
      } else if (this.form.examineState == 1) {
        //待处理跳转页面
        this.$router.push({
          name: 'renwuyishenhe',
          query: {
            name: '',
            id: i
          }
        });
      } else if (state == 5) {
        this.$router.push({
          name: 'taskxiangqing',
          query: {
            id: i,
            state: state
          }
        });
      }
    },
    clickSearch () {
      this.form.pageNum = 1
      this.getList();
    },
    onConfirm () {
      this.getList();
    },
    yishenClick () {
      this.form.examineState = 3;
      this.getList();
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    daishenClick () {
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
      this.form.examineState = 2;

      this.getList();
    },
    xunClick () {
      this.form.examineState = 1;
      this.getList();
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import url("../../assets/less/conment.less");

.myTask {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    height: 40px;
    width: 98%;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: #fff;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-icon__image {
          width: 12px;
          height: 12px;
        }
      }

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e8e9ec;

    .box {
      width: 73px;
      height: 100%;
      color: #0c90ff;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      border-bottom: 3px solid #0c90ff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dianstyle {
      width: 14px;
      height: 14px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: -12px;
      top: -4px;
      line-height: 14px;
      font-size: 12px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
      }
    }

    .yishenheNoClick {
      width: 73px;
      height: 36px;
      color: #333333;
      font-weight: 400;
      font-size: 15px;
      font-family: PingFang SC;
      display: flex;
      justify-content: center;
      align-items: center;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
      }
    }

    .xunClick {
      .box();

      .icon {
      }
    }
  }

  .list {
    margin: 15px 0 50px 0;
    overflow-y: auto;
    padding: 0 10px;

    .box {
      &:first-child {
        margin-top: 0px;
      }

      margin-top: 10px;
      height: 115px;
      border-radius: 10px;
      position: relative;
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .biaoshiStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        color: #fff;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .lvbiaoshi {
        .biaoshiStyle();
        background: #50cb79;
        box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
      }

      .huangbiaoshi {
        background: #ffac20;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
      }

      .hongbiaoshi {
        background: #ff6661;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
      }

      .contentBox {
        margin: 10px 16px 0px 16px;
        display: flex;
        justify-content: space-between;

        .imgBox {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          align-self: center;

          .gaojin {
            width: 100%;
            height: 100%;
          }

          .user {
            width: 20px;
            height: 18px;
          }
        }

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 13px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 15px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            // margin-top: 4px;
            width: 190px;
            white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fankui {
            width: 180px;
          }
        }

        > img {
          height: 79px;
          width: 106px;
          border-radius: 8px;
          align-self: end;
        }

        .chakan {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > .time {
            color: #686b73;
            font-size: 10px;
            align-self: center;
          }

          .van-button {
            width: 60px;
            height: 25px;
            font-size: 12px;
            background: #1c91ff;
            box-shadow: 0px 3px 5px 0px rgba(28, 128, 222, 0.2);
            border-radius: 21px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
